import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import StopIcon from '@material-ui/icons/Stop'
import { CONFIG_SETTINGS } from '../../../../config'
import { isValidDocument, onGetCurrencyValue, onGetDateFormat } from '../../../../shared/utility'
import { catalogs } from '../../../../texts/eng/catalogs'
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    noMagazines: {
        marginTop: 100
    },
    noMagazinesTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noMagazinesSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const Magazines = ({ content, magazines, onClick }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {magazines.length > 0 ? magazines.map(magazine => {
                            let disabled = magazine.magazine_status_id !== 2

                            return (
                                <Grid item xs={12} sm={6} lg={4}>
                                    <MagazineCard
                                        magazine={magazine}
                                        content={content}
                                        onClick={disabled ? null : () => onClick(magazine)} />
                                </Grid>
                            )
                        })
                            :
                            <Grid item xs={12}>
                                <div className={classes.noMagazines}>
                                    <Grid container justifyContent='center' spacing={3}>
                                        <Grid item>
                                            <SVGIcon src={content.noMagazines.icon} size={64} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align='center' className={classes.noMagazinesTitle}>
                                                {content.noMagazines.title}
                                            </Typography>
                                            <Typography align='center' className={classes.noMagazinesSubtitle}>
                                                {content.noMagazines.subtitle}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        //maxWidth: 400,
        borderRadius: 10,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    cardActionArea: {
        position: 'relative',
        paddingBottom: 30,
        width: '100%',
        height: '100%',
        '& span': {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                cursor: 'pointer'
            }
        }
    },
    media: {
        width: '100%',
        background: 'grey',
        paddingTop: "56.25%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    cardContent: {
        padding: 24,
        paddingTop: 16,
        paddingBottom: 0,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    nftButton: {
        position: 'absolute',
        bottom: -16,
        right: 10
    },
    soldButton: {
        fontSize: 12,
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    nft_button: {
        background: theme.palette.secondary.main,
        width: 32,
        height: 32,
        margin: 'auto',
        position: 'relative',
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: '100%',
        padding: 0,
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    nft_button_grey: {
        background: grey[500],
        cursor: 'default',
        '&:hover': {
            background: grey[500],
        }
    },
    featured: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 15,
        paddingTop: 0,
    },
    applicationContent: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        padding: 15,
        paddingTop: 20,
        boxSizing: 'border-box'
    },
    applicationTitle: {
        fontSize: 16,
        color: 'white',
        letterSpacing: '0.2em',
        [theme.breakpoints.up('xl')]: {
            fontSize: 18
        }
    },
    applicationSubtitle: {
        fontSize: 24,
        color: 'white',
        [theme.breakpoints.up('xl')]: {
            fontSize: 32
        }
    }
}))

const MagazineCard = ({ magazine, content, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { sold, magazine_status_id, nft, price, likes, url, web_image, mobile_image } = magazine

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage
    return (
        <Card className={classes.root} onClick={onClick}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={classes.media}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            {Boolean(Number(sold)) &&
                                <RoundedButton color='black' size='small'>
                                    <Typography className={classes.soldButton}>
                                        {content.sold}
                                    </Typography>
                                </RoundedButton>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span>{content.data.title.label}:</span> {magazine.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span>{content.data.start_date.label}:</span> {magazine.start_date ? onGetDateFormat(magazine.start_date, 'DD MMM YYYY') : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span>{content.data.end_date.label}:</span> {magazine.application_documents_limit_date ? onGetDateFormat(magazine.application_documents_limit_date, 'DD MMM YYYY') : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography align='right' className={classes.text}>
                                <span>{content.max.label[0]} {magazine.max_artwork_number}</span> {content.max.label[1]}
                            </Typography>
                        </Grid>
                    </Grid>
                    {Boolean(Number(nft)) &&
                        <div className={classes.nftButton}>
                            <IconButton
                                className={cx({
                                    [classes.nft_button]: true,
                                    //[classes.nft_button_grey]: sold > 0
                                })}
                            //onClick={onOpenNFT}
                            >
                                <SVGIcon src={content.nft} size={24} />
                            </IconButton>
                        </div>
                    }
                </CardContent>
                <div className={classes.applicationContent}>
                    <Grid container justifyContent='center' spacing={2}>
                        <Grid item xs>
                            <Typography className={classes.applicationTitle}>
                                {content.application.label}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.applicationSubtitle} align='center'>
                                {magazine.application_limit_date ? onGetDateFormat(magazine.application_limit_date, 'DD MMM YYYY') : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                {magazine.featured &&
                    <div className={classes.featured}>
                        <SVGIcon src={content.icon} color='white' />
                    </div>
                }
            </CardActionArea>
        </Card>
    )
}

export default Magazines