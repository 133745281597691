import React from 'react'
import { connect } from 'react-redux';
import { CardMedia, Drawer, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ProductImage from '../../components/Ecommerce/ProductImage'
import usePurchaseEnquiryDrawer from './usePurchaseEnquiryDrawer';
import LineInputFormLight from '../../components/Forms/LineInputFormLight';
import RoundedButton from '../../components/Actions/RoundedButton'
import { nodata } from '../../config';
import CompletedView from './CompletedView';
import LineSelectFormLight from '../../components/Forms/LineSelectFormLight';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { actionTypes } from '../../store/actions';
import general_translations from "../../texts/eng/website/general-translations"


const PurchaseEnquiryDrawer = ({ open, artwork, onUpdatePurchaseDrawerStatus, onUpdateArtworkData }) => {

    const classes = useStyles()
    const content = general_translations.purchase_enquiry_drawer

    const { sending, success, error, actions, form } = usePurchaseEnquiryDrawer({ content, open, artwork, onUpdatePurchaseDrawerStatus, onUpdateArtworkData })

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const _image = artwork && mobile ? artwork.mobile_image : artwork ? artwork.web_image : null

    return (
        <div >
            <Drawer open={open}
                //variant={ismobile ? 'temporary' : 'persistent'}
                classes={{ paper: classes.drawer }}
                onClose={actions.onClose}
            //className={classes.drawer}
            >
                <div className={classes.container} >

                    <div>
                        <Scrollbars
                            width={mobile ? '100%' : 500}
                            autoHeight
                            autoHeightMin={'100vh'}
                        >
                            <div className={classes.general_section}>
                                <Grid container justifyContent='space-between' alignItems='center'>
                                    <Grid item>
                                        <div className={classes.logo_container}><img className={classes.logo} src={content.logo} /></div>
                                    </Grid>
                                    <Grid item><IconButton onClick={actions.onClose} ><Icon style={{ color: 'white' }}>close</Icon></IconButton></Grid>
                                </Grid>
                            </div>
                            {!success ? <div>
                                <div className={classes.general_section}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Typography variant='h5' style={{ marginBottom: 40 }}>{content.title}</Typography>
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <ProductImage src={_image} />
                                        </Grid>
                                        <Grid item md={7} xs={12}>
                                            <div className={classes.info_container}>
                                                <Typography gutterBottom className={classes.title}>
                                                    {artwork ? artwork.title : nodata}
                                                </Typography>
                                                <Typography className={classes.technique}>
                                                    {artwork ? artwork.technique : nodata}
                                                </Typography>
                                                <Typography className={classes.dimensions}>
                                                    {artwork ? artwork.dimensions : nodata}
                                                </Typography>
                                                <Typography className={classes.artist}>
                                                    {artwork ? artwork.artist_professional_name : nodata}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.form_section}>
                                    <div className={classes.title_container}>
                                        <Typography variant='subtitle1' >{content.form_title}</Typography>
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <LineInputFormLight data={form.name} onChange={actions.onChangeForm} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LineInputFormLight data={form.email} onChange={actions.onChangeForm} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <LineSelectFormLight data={form.country_code} onChange={actions.onChangeForm} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <LineInputFormLight data={form.mobile} onChange={actions.onChangeForm} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LineInputFormLight data={form.message} onChange={actions.onChangeForm} />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.bottom_section}>
                                        <RoundedButton loading={sending} onClick={actions.onSubmit}>{content.button}</RoundedButton>
                                    </div>
                                </div>
                            </div> : <CompletedView content={content.completed} onClose={actions.onClose} />}
                        </Scrollbars>
                    </div>

                </div>
            </Drawer>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        open: state.purchase_enquiry_drawer,
        artwork: state.artwork
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdatePurchaseDrawerStatus: (purchase_enquiry_drawer) => dispatch({ type: actionTypes.DRAWER_PURCHASE_ENQUIRY, purchase_enquiry_drawer }),
        onUpdateArtworkData: (artwork) => dispatch({ type: actionTypes.DATA_UPDATE_ARTWORK, artwork }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseEnquiryDrawer)

const useStyles = makeStyles(theme => ({
    drawer: {
        //border:0,
        background: 'rgba(0, 0, 0, 0.9)',
        //background:'rgba(0, 0, 0, 0.9)'
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        //boxShadow:'4px 4px 9px rgba(0, 0, 0, 0.07)',
        //background:theme.palette.secondary.main,

    },
    container: {
        width: 500,
        //height:'100vh',
        boxSizing: 'border-box',
        //height:'100%',
        background: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    logo_container: {
        width: 40
    },
    title_container: {
        padding: '20px 0px',
        color: '#FEFEFE'
    },
    general_section: {
        padding: '30px 50px',
        borderBottom: '1px solid #5A5A5A'
    },
    form_section: {
        padding: '30px 50px',
        //borderBottom:'1px solid #5A5A5A'
    },
    bottom_section: {
        textAlign: 'center',
        padding: '50px 0px 10px'
    },
    info_container: {
        paddingLeft: 20,
        position: 'relative',
        height: '100%'
    },
    media: {
        background: 'grey',
        paddingTop: '100%',
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 6,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 0,
        color: '#E3E3E3',
        marginBottom: 5
    },
    technique: {
        fontSize: 14,
        fontWeight: 300,
        color: '#B2B2B2'
    },
    dimensions: {
        fontSize: 14,
        fontWeight: 600,
        color: '#B2B2B2'
    },
    artist: {
        fontSize: 14,
        fontWeight: 300,
        color: '#B2B2B2',
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.down('sm')]:{
            position: 'initial',
        }
    }
}))



