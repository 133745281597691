import React, { useState } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onClearMemory, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";

const useConfirmationDrawer = ({ history, language, onUpdateDrawerStatus,
    onUpdateAuthStatus, onUpdateUserData, onUpdateArtistRegisterDrawer,
    onUpdateProMode }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [artist, setArtist] = useState(null)

    const actions = {
        onInitModule: async () => {
            try {
                setLoading(true)
                const search = history.location.search
                const token = search.split('=')

                const data = {
                    token: token[1],
                    device: "web"
                }
                //'clearing...'
                await onClearMemory()
                await onUpdateUserData(null)
                await onUpdateAuthStatus(false)
                //'CLEARED'
                const response = await public_server.post('user/confirm', data)
                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                onUpdateUserData(user_request.data.data.user)
                //GET THE USER TYPE
                if (user_request.data.data.user.user_type_id === 3) {
                    const artist_request = await private_server.get('/artist/me')
                    setArtist(artist_request.data.data.artist)
                    await onUpdateProMode(true)
                }

                await onUpdateAuthStatus(true)
                history.push('/')
                setLoading(false)
                setError(null)
            } catch (error) {
                onUpdateDrawerStatus(false)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onClose: async () => {
            await onUpdateDrawerStatus(false)
        },
        onOpenArtistRegister: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateArtistRegisterDrawer(true)
        }
    }

    return {
        loading, error, actions, artist
    }
}

export default useConfirmationDrawer
