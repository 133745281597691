import React, { useEffect, useState } from 'react'
import SimpleModal from '../../components/Modals/SimpleModal'
import { private_server } from '../../config'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import { generic_errors } from '../../texts/eng/genericErrors'
import translations from '../../texts/eng/pro/general-translations'

const useChangePasswordModal = ({ language, user, content, onUpdateUserData, onUpdateModalStatus }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    //const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: () => {
            const _form = onInitForm(form, null, content.form)
            if (user)
                _form.previous_password.value2 = user.password
            setForm(_form)
        },
        onClose: () => onUpdateModalStatus(false),
        onCloseError: () => setError(null),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSubmit: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)
                console.log(errors)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }

                const data2send = onGetFormData(form)

                await private_server.post(`user/me/passwordchange`, data2send)
                setError(null)
                await onUpdateModalStatus(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, error }
    const view_data = { form }

    return { system, view_data, actions }
}

const formData = {
    previous_password: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 64
        }
    },
    new_password: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 64
        }
    },
}

export default useChangePasswordModal