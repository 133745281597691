import nftIcon from '../../../assets/icons/NFT.svg'

import shareIcon from '../../../assets/icons/share.svg'
import scanIcon from '../../../assets/icons/scan.svg'
import likeIcon from '../../../assets/icons/full_heart.svg'

import arrowIcon from '../../../assets/icons/arrow_down.svg'
import noImage from '../../../assets/artist/noImage.png'

import logo from '../../../assets/logo_gold.png'
import waves from '../../../assets/radio_waves_white.png'

export const translations = {
    main_info_section: {
        title: 'Market Place',
        caption: 'THE ART YOU WANT, WHEREVER YOU ARE'
    },
    newest_section: {
        title: 'Newest',
        noImage: noImage,
        noData: "This artist don't have any artwork yet.",
        sold: 'Sold',
        buy: 'buy',
        nft: nftIcon,
        buttons: {
            share: {
                label: 'Share',
                icon: shareIcon,
            },
            like: {
                icon: likeIcon
            },
            scan: {
                label: 'AR',
                icon: scanIcon
            }
        },
    },
    mostviewed_section: {
        title: 'Most viewed',
        noImage: noImage,
        sold: 'Sold',
        buy: 'buy',
    },
    catalog_section: {
        title: 'Catalogue',
        noImage: noImage,
        noContent:'There is not artwork with the filters selected.',
        sold: 'Sold',
        buy: 'buy',
        colors:'Colors',
        nft: nftIcon,
        moreIcon: arrowIcon,
        buttons: {
            share: {
                label: 'Share',
                icon: shareIcon,
            },
            like: {
                icon: likeIcon
            },
            scan: {
                label: 'AR',
                icon: scanIcon
            }
        },
        see: {
            more: 'See more',
            less: 'See less'
        },
    }
}