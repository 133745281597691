import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardActionArea, CardContent, Divider, Grid, Hidden, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import CustomCarousel from "./CustomCarousel"
import Scrollbars from "react-custom-scrollbars-2"
import CustomCarouselMobile from "./CustomCarouselMobile"

const styles = makeStyles(theme => ({
    root: {
        margin: '40px 0px',
        //paddingLeft: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
}))


const NewArtists = ({ history, artists, content, onArtistSelected }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const carouselRef = useRef(null)
    

    return (
        <div className={classes.root}>
            <MainContainer>
                <div style={{ marginBottom: 16 }}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} align='right'>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </div>
                <div ref={carouselRef}>
                    <Hidden smDown>
                        <CustomCarousel data={artists} onItemSelected={onArtistSelected} reference={carouselRef}/>
                    </Hidden>
                    <Hidden mdUp>
                        <Scrollbars autoHeight autoHeightMax={600}>
                            <div style={{padding:16, paddingBottom:32}}>
                                <CustomCarouselMobile data={artists} onItemSelected={onArtistSelected} reference={carouselRef}/>
                            </div>
                        </Scrollbars>
                    </Hidden>
                </div>
            </MainContainer>
        </div >
    )
}

export default NewArtists