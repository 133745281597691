import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/pro/home-translations'
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../../components/Structure/Table/ActionTopBar';
import useHomeView from './useHomeView'
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument, onGetCurrencyValue } from '../../../shared/utility';
import RoundedButton from '../../../components/Actions/RoundedButton';
import { catalogs } from '../../../texts/eng/catalogs';
import StopIcon from '@material-ui/icons/Stop'
import { grey } from '@material-ui/core/colors';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import cx from 'classnames'
import AddArtworkModal from './modals/DataModal';
import { connect } from 'react-redux';
import OpenCalls from './sections/OpenCalls';
import Magazines from './sections/Magazines';
import DataModal from './modals/DataModal';

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0
    },
    content: {
       // marginTop: 30
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    noArtworks: {
        marginTop: 100
    },
    noArtworksTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noArtworksSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const HomeView = ({ is_auth, history }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useHomeView({
        is_auth, history, content
    })


    return (
        <LoadingContainer
            loading={system.loading}
            sending={system.sending}
            success={system.success}
            error={system.error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title}>
                <DataModal
                    open={view_data.apply_modal}
                    data={view_data.selected_item}
                    onChange={actions.onChange}
                    onDownloadDocument={actions.onDownloadDocument}
                    //onSubmit={actions.onSaveArtwork}
                    onClose={actions.onCloseAddModal}
                    content={content.modals.apply_modal}
                />
                <div className={classes.content}>
                    <Grid container alignItems='stretch' spacing={4}>
                        {/* OPEN CALLS */}
                        <Grid item xs={12}>
                            <OpenCalls
                                content={content.sections.open_calls}
                                exhibitions={view_data.exhibitions}
                                onClick={actions.onSelectItem}
                            />

                        </Grid>
                        {/* MAGAZINES */}
                        <Grid item xs={12}>
                            <Magazines
                                content={content.sections.magazines}
                                magazines={view_data.magazines}
                                onClick={actions.onSelectItem}
                            />
                        </Grid>
                    </Grid>
                </div>
            </TitleContainer>
        </LoadingContainer>
    )
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        maxWidth: 400,
        borderRadius: 5,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    cardActionArea: {
        position: 'relative',
        padding: 10,
        paddingBottom: 30,
        width: '100%',
        height: '100%',
        '& span': {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                cursor: 'pointer'
            }
        }
    },
    media: {
        width: '100%',
        background: 'grey',
        paddingTop: "100%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    cardContent: {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    nftButton: {
        position: 'absolute',
        bottom: -16,
        right: 10
    },
    soldButton: {
        fontSize: 12,
        textTransform: 'uppercase'
    },
    statusContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    status: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[800]
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    nft_button: {
        background: theme.palette.secondary.main,
        width: 32,
        height: 32,
        margin: 'auto',
        position: 'relative',
        boxSizing: 'border-box',
        cursor: 'pointer',
        borderRadius: '100%',
        padding: 0,
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    nft_button_grey: {
        background: grey[500],
        cursor: 'default',
        '&:hover': {
            background: grey[500],
        }
    },
    likesContent: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 15,
        paddingTop: 20,
    },
    likes: {
        fontSize: 14,
        color: 'white'
    }
}))

const ArtworkCard = ({ artwork, content, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { sold, artwork_status_id, nft, price, likes, url, web_image, mobile_image } = artwork

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    const artworkStatus = catalogs.artwork_statuses.find(artwork => artwork.id === artwork_status_id)

    return (
        <Card className={classes.root} onClick={() => onClick(url)}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={classes.media}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            {Boolean(Number(sold)) &&
                                <RoundedButton color='black' size='small'>
                                    <Typography className={classes.soldButton}>
                                        {content.sold}
                                    </Typography>
                                </RoundedButton>
                            }
                        </Grid>
                        <Grid item>
                            <div className={classes.statusContainer}>
                                <Typography className={classes.status}>
                                    {artworkStatus.label}
                                </Typography>
                                <StopIcon style={{ width: 15, height: 15, color: artworkStatus.color }} />
                            </div>
                        </Grid>
                        {content.data.map(item => (
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    <span>{item.label}:</span> {artwork[item.id]}
                                </Typography>
                            </Grid>
                        ))}
                        <Grid item xs>
                            <Typography className={classes.text}>
                                <span>{content.price}:</span> {onGetCurrencyValue(price ? price : '', 0, '€ ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {Boolean(Number(nft)) &&
                        <div className={classes.nftButton}>
                            <IconButton
                                className={cx({
                                    [classes.nft_button]: true,
                                    //[classes.nft_button_grey]: sold > 0
                                })}
                            //onClick={onOpenNFT}
                            >
                                <SVGIcon src={content.nft} size={24} />
                            </IconButton>
                        </div>
                    }
                </CardContent>
                <div className={classes.likesContent}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <SVGIcon src={content.like} color='white' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.likes} align='center'>
                                {likes}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardActionArea>
        </Card>
    )
}


const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth
    }
}

export default connect(mapStateToProps, null)(HomeView)