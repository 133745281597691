import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import { catalogs } from '../../../../texts/eng/catalogs'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import RoundedButton from '../../../../components/Actions/RoundedButton'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    iconButton: {
        background: 'white',
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)'
    },
    labelContent: {
        background: 'white',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        borderRadius: 27,
        padding: '10px 20px',
    },
    label: {
        overflowX: 'auto',
        overflowY: 'hidden',
        color: '#A0A0A0',
        fontSize: 16,
        fontWeight: 700,
        padding: 10,
        borderRadius: 4,
        height: 25,
        background: 'rgba(189, 139, 64, 0.04)',
    }
}))

const SocialMedias = ({ data, content, submitButton, onEdit }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={onEdit}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={4}>
                        {catalogs.social_medias.map(media => (
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container alignItems='center' spacing={2}>
                                    <Grid item xs={2}>
                                        <IconButton className={classes.iconButton}>
                                            <SVGIcon src={media.icon} color={theme.palette.primary.main} size={24} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className={classes.labelContent}>
                                            <Typography className={classes.label}>
                                                {data[media.id]}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SocialMedias