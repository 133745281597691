import React, { useState, useEffect } from 'react';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../shared/utility';
import queryString from 'query-string'
import { private_server, public_server } from '../../config';
import countryCodes from 'country-codes-list'

const usePurchaseEnquiry = ({ content, open, artwork, onUpdateArtworkData, onUpdatePurchaseDrawerStatus }) => {

    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const id = artwork ? artwork.id_artwork : null

    useEffect(() => {
        if (open) {
            actions.onInitModule()
        }
        if (!open) {
            setError(null)
            setForm(JSON.parse(JSON.stringify(form_data)))
        }
    }, [open])

    const actions = {
        onInitModule: async () => {
            let _form = { ...form }
            _form = onInitForm(form, null, content.form)
            const country_codes = countryCodes.customList('countryCallingCode', '+{countryCallingCode}')
            _form.country_code.options = Object.values(country_codes)
            _form.country_code.value = "+49";
            _form.country_code.isValid = true;
            setForm(_form)
        },
        onClose: async () => {
            await onUpdatePurchaseDrawerStatus(false)
            await onUpdateArtworkData(null)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                console.log(errors)
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError('Invalid form')
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            data2send.artwork_id = id
            console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(public_server)
                await public_server.post(`/artworkpurchaserequest/ns`, data2send)
                setError(null)
                setSending(false)
                //onClose()
                //onCompleted()     
            } catch (error) {
                setSending(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error)
            }

        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },

    }

    return {
        sending, success, error, actions, form
    }
}

export default usePurchaseEnquiry

const form_data = {
    name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'distance', min: 1, max: 255 }
    },
    email: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'email' }
    },
    country_code: {
        value: '+52',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [],
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    mobile: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'phone_number', }
    },
    message: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'message',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: { type: 'distance', min: 1, max: 255 }
    },

}