import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/home-translations'
import { connect } from 'react-redux'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import MainBanner from './sections/MainBanner'
import Abstract from './sections/Abstract'
import Plataform from './sections/Plataform'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { onGetErrorMessage } from '../../../shared/utility'
import VirtualExhibitions from './sections/VirtualExhibitions'
import AboutUs from './sections/AboutUs'
import DonwloadApp from './sections/DonwloadApp'
import { actionTypes } from '../../../store/actions'
import { public_server } from '../../../config'
import useHomeView from './useHomeView'


const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
}))

const HomeView = ({ is_auth, history, onUpdateDrawerStatus, onUpdateContactDrawerStatus, onUpdateArtworkData, onUpdateModalStatus }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useHomeView({ is_auth, history, onUpdateDrawerStatus, onUpdateContactDrawerStatus, onUpdateArtworkData, onUpdateModalStatus })

    return (
        <React.Fragment>
            <MainBanner banners={view_data.banners} content={content.main_banner_section} onClickBanner={actions.onClickBanner} />
            <LoadingContainer loading={system.loading} error={system.error} onCloseError={actions.onCloseError}>
                <div className={classes.content}>
                    <Abstract history={history} content={content.abstract_section} openSignUpDrawer={actions.openSignUpDrawer} />
                    <Plataform history={history} platform={view_data.platform} content={content.platform_section} />
                    <VirtualExhibitions history={history} exhibitions={view_data.exhibitions} content={content.exhibitions_section} openSignUpDrawer={actions.openSignUpDrawer} />
                    <AboutUs history={history} content={content.about_us_section} onOpenContactDrawer={actions.onOpenContactDrawer} />
                    {/*<DonwloadApp history={history} content={content.download_app_section} />*/}
                </div>
            </LoadingContainer>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer }),
        onUpdateContactDrawerStatus: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
        onUpdateArtworkData: (artwork) => dispatch({ type: actionTypes.DATA_UPDATE_ARTWORK, artwork }),
        onUpdateModalStatus: (artwork_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_ARTWORK, artwork_modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)