import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import HomeView from '../views/Website/HomeView/HomeView'
import AboutUsView from '../views/Website/AboutUsView/AboutUsView'
import CommunityView from '../views/Website/CommunityView/CommunityView'
import ExhibitionsDetailsView from '../views/Website/ExhibitionsDetailsView/ExhibitionsDetailsView'
import ComingSoon from '../views/Website/ComingSoon'
import ArtistView from '../views/Website/ArtistView/ArtistView'
import ArtistProfileView from '../views/Website/ArtistProfileView/ArtistProfileView'
import MasterclassesView from '../views/Website/MasterclassesView/MasterclassesView'
import MarketPlaceView from '../views/Website/MarketPlaceView/MarketPlaceView'
import MagazinesView from '../views/Website/MagazinesView/MagazinesView'
import ExhibitionsView from '../views/Website/ExhibitionsView/ExhibitionsView'
import MagazinesDetailsView from '../views/Website/MagazinesDetailsView/MagazinesDetailsView'
import MasterclassesDetailsView from '../views/Website/MasterclassesDetailsView/MasterclassesDetailsView'

const DashboardRouter = () => (
    <Switch>
        <Route exact path='/' component={HomeView} />
        <Route path='/confirmation' component={HomeView} />
        <Route path='/passwordchange' component={HomeView} />
        {/*<Route exact path='/' component={ComingSoon} />
            <Route exact path='/' component={ExhibitionsDetailsView} />*/}
        <Route exact path='/exhibitions/:url' component={ExhibitionsDetailsView} />
        <Route exact path='/exhibitions' component={ExhibitionsView} />

        {/*<Route path='/about' component={ComingSoon}/>
            <Route path='/community' component={ComingSoon}/>*/}
        <Route path='/about' component={AboutUsView} />
        <Route path='/community' component={CommunityView} />

        {/*<Route path='/artists' component={ComingSoon}/>*/}
        {/*<Route path='/contact' component={ComingSoon} />*/}
        <Route exact path='/magazines' component={MagazinesView} />
        <Route exact path='/magazines/:url' component={MagazinesDetailsView} />
        <Route exact path='/masterclass' component={MasterclassesView} />
        <Route exact path='/masterclass/:url' component={MasterclassesDetailsView} />
        <Route exact path='/artists' component={ArtistView} />
        <Route exact path='/artists/:url' component={ArtistProfileView} />
        <Route path='/marketplace' component={MarketPlaceView} />
        <Redirect to='/' />
    </Switch>
)

export default DashboardRouter

