import { Grid } from '@material-ui/core'
import React from 'react'
import SwitchForm from '../../../../components/Forms/SwitchForm'
import LineInputForm from '../../../../components/Forms/LineInputForm'
import SimpleModal from '../../../../components/Structure/Modals/SimpleModal'
import RoundedButton from '../../../../components/Actions/RoundedButton'

const AddExhibitionModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <Grid container alignItems='center' spacing={4}>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.title} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.dimensions} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.technique} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.price} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.description} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.date} onChange={onChange} disableFuture />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SwitchForm data={form.sold} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SwitchForm data={form.nft} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.external_url} onChange={onChange}/>
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.add_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default AddExhibitionModal