import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'
import moment from 'moment'

const CssTextField = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    '& label': {
      color: '#A0A0A0',
      paddingLeft: 10,
      fontWeight: 500,
    },
    '& input, textarea': {
      background: 'rgba(189, 139, 64, 0.04)',
      padding: 10,
      borderRadius: 4,
      marginTop: 8
    },
    '& .MuiInput-formControl': {
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiIcon-root': {
      color: '#9D9D9D',
    }
  },
}), { withTheme: true })(TextField);

const CSSKeyboardDatePicker = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    marginTop: '6px !important',
    '& label': {
      color: '#A0A0A0',
      fontWeight: 700
    },
    '& input': {
      background: 'rgba(189, 139, 64, 0.04)',
      padding: 10,
      borderRadius: 4,
      marginTop: 8
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiIcon-root': {
      color: '#9D9D9D',
    }
  }
}))(DatePicker);

const FilledInputForm = props => {

  const { data, color, disabled, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, disableFuture, format } = props

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const handleDateChange = (date) => {
    const response = date.format('YYYY-MM-DD');
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const { config, value, isValid, isVisited } = data
  const { id, label, placeholder, helperText, fullWidth } = config

  const isError = isVisited && !isValid
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''

  const textInput = (
    <CssTextField value={value}
      error={isError} {...interConfig} onChange={onInnerChange}
      InputProps={
        {
          startAdornment: startAdornment ? startAdornment : undefined,
          endAdornment: endAdornment ? endAdornment : undefined,
          onKeyUp: onKeyUp ? onKeyUp : undefined,
          onKeyDown: onKeyDown ? onKeyDown : undefined,
        }}
    />
  )

  const dateInput = (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CSSKeyboardDatePicker
        disableToolbar
        disableFuture={disableFuture}
        disabled={disabled}
        value={value ? moment(value) : null}
        placeholder={placeholder}
        error={isError}
        fullWidth={fullWidth}
        variant="inline"
        format={format ? format : 'DD/MM/YYYY'}
        label={label}
        helperText={isError ? helperText : null}
        onChange={handleDateChange}
        clearable
        autoOk
      />
    </MuiPickersUtilsProvider>
  )

  let inputContent = null

  switch (config.type) {
    case 'date':
      inputContent = dateInput
      break;
    default:
      inputContent = textInput
      break;
  }

  return (
    <div>
      {inputContent}
    </div>
  )

}

export default FilledInputForm