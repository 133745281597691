import React, { useEffect, useState } from "react"
import { alpha, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import { catalogs } from "../../../../texts/eng/catalogs"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"

const styles = makeStyles(theme => ({
    root: {
        background: 'black',
        color: 'white',
        paddingTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    content: {
        marginTop: 80,
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 300,
        textTransform: 'uppercase',
        letterSpacing: '0.025em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    galleryTitle: {
        fontSize: 36,
        fontWeight: 600,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    imageContainer: {
        position: 'relative'
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    button: {
        display: 'block',
        margin: 'auto'
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: '0.525em'
    }
}))

const Artists = ({ history, artists, content, onArtistSelected }) => {

    const classes = styles()
    const [seeMore, setSeeMore] = useState(false)

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='center'>
                            {content.title}
                        </Typography>
                        <Typography className={classes.subtitle} align='center'>
                            {content.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>


                    {artists.length > 0 ?
                        <Grid item xs={12}>
                            <div className={classes.content}>
                                {artists.length === 1 ?
                                    <Grid container justifyContent='center'>
                                        <Grid item xs={12} sm={3}>
                                            <ArtistCard {...artists[0]} noImage={content.noImage} onItemSelected={onArtistSelected} />
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container spacing={4}>
                                        {artists.map(artist => (
                                            <Grid item xs={12} sm={3}>
                                                <ArtistCard {...artist} noImage={content.noImage} onItemSelected={onArtistSelected} />
                                            </Grid>
                                        ))}
                                    </Grid>}
                            </div>
                        </Grid>
                        :
                        <Typography className={classes.noArtist} align='center'>
                            {content.noContent}
                        </Typography>
                    }
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Artists

const ArtistCard = ({ artist_id, url, enabled, artist_professional_name, country_abbreviation, artist_discipline_subject,
    artist_professional_image, onItemSelected }) => {

    const classes = useArtistCardStyles()
    const theme = useTheme()

    return (
        <div className={classes.root} onClick={() => onItemSelected(enabled, url)}>
            <div className={classes.artist_card} key={artist_id.toString()} >
                <GenericImage src={artist_professional_image} ratio='3/4' />
            </div>
            <div style={{ marginTop: 16, padding: 4 }}>
                <Grid container alignItems='flex-start'>
                    <Grid item xs>
                        <Typography gutterBottom className={classes.artist}>
                            {artist_professional_name ? artist_professional_name : '-'}
                        </Typography>
                        <Typography className={classes.carrer}>
                            {artist_discipline_subject ? artist_discipline_subject : '-'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom className={classes.nationality}>
                            {country_abbreviation}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

const useArtistCardStyles = makeStyles(theme => ({
    artist_card: {
        background: 'black',
        width: "100%",
        borderRadius: 10,
        position: 'relative',
    },
    root: {
        color: 'white',
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        borderRadius: 10,
        '&:hover': {
            background: alpha("#000", 0.03)
        }
    },
    artist: {
        color: 'white',
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 0
    },
    carrer: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.265em'
    },
    nationality: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[300],
        padding: '2px 24px 2px 24px',
        border: `1px solid ${grey[700]}`,
        borderRadius: 3
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 20
    }
}))