import axios from "axios"
import { useRef, useState } from "react"
import { CONFIG_SETTINGS, private_server } from "../../../../../config"
import { isValidDocument, onGetCurrentUserProfile, onGetErrorMessage, onGetFileExtension } from "../../../../../shared/utility"

const useArtworkImageModal = ({ id, artwork, onUpdateArtworkImages }) => {

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    const [mobile, setMobile] = useState(false)
    //const [crop, setCrop] = useState({ aspect: 1, width: 128, unit: 'px' })
    const [croppedImage, setCroppedImage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef()


    const actions = {
        onResetComponent: () => {
            setFile(null)
            setSrc(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        },
        onDropFile: (data) => {
            setFile(data)
            if (data) {
                const reader = new FileReader();
                reader.addEventListener("load", () => setSrc(reader.result))
                reader.readAsDataURL(data)
            }
            setValid(true)
        },
        onImageLoaded: image => imageRef.current = image,
        handlerURL2Blob: async (url) => {
            let blob = await fetch(url).then(r => r.blob());
            let temp = blob.type.split('/');
            let ext = "." + temp[temp.length - 1];
            let newfile = new File([blob], "profile" + ext, { type: blob.type });
            return newfile;
        },
        onUpdateMobile: (event) => setMobile(event.target.checked),
        onSubmit: async () => {
            if (!valid) {
                setError('Please select a file')
                return
            }
            setLoading(true)
            try {
                const file2send = {
                    name: mobile ? 'mobile_image' : "web_image",
                    ext: onGetFileExtension(file.name)
                }

                let artworkImageID = artwork ? artwork.id : null
                console.log(!artwork, artwork)
                // Image upload handler
                if (file) {

                    let response = null

                    if (!artwork) {
                        response = await private_server.post(`artworkimage/${id}/me`, { name: " " })
                        artworkImageID = response.data.data.artwork_image.id_artwork_image
                    }

                    const options = { headers: { 'Content-Type': file.type } }
                    response = await private_server.post(`artworkimage/${artworkImageID}/image/me`, file2send)
                    await axios.put(response.data.data.url, file, options)

                    //Obtenemos la imagen del registro en la base

                    response = await private_server.get(`artworkimage/${artworkImageID}/me`)

                    const _artwork = response.data.data.artwork_image
                    const webImage = _artwork ? _artwork.web_image : null
                    const mobileImage = _artwork ? _artwork.mobile_image : null

                    let isValid = isValidDocument(webImage)
                    const selectedImageWeb = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${webImage}` : null
                    isValid = isValidDocument(mobileImage)
                    const selectedImageMob = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${mobileImage}` : null

                    await onUpdateArtworkImages({ id: artworkImageID, web_image: selectedImageWeb, mobile_image: selectedImageMob })
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        }
    }

    return { loading, mobile, file, src, actions }
}

export default useArtworkImageModal