import React, { useState, useEffect } from 'react'
import { translations as translations_esp } from '../../../texts/eng/website/home-translations'
import { connect } from 'react-redux'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import MainBanner from './sections/MainBanner'
import Abstract from './sections/Abstract'
import Plataform from './sections/Plataform'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility'
import VirtualExhibitions from './sections/VirtualExhibitions'
import AboutUs from './sections/AboutUs'
import DonwloadApp from './sections/DonwloadApp'
import { actionTypes } from '../../../store/actions'
import { private_server, public_server } from '../../../config'
import moment from 'moment'
import {useLocation} from 'react-router-dom'

const useQuery = () =>
 {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useHomeView = ({ is_auth, history, onUpdateDrawerStatus, onUpdateContactDrawerStatus, onUpdateArtworkData, onUpdateModalStatus }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [banners, setBanners] = useState(null)
    const [platform, setPlatform] = useState(null)
    const [exhibitions, setExhibitions] = useState(null)

    const query = useQuery()

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)

            try {
				setLoading(true);
				const today = moment().format('YYYY-MM-DD HH:mm:ss');

				let filter = {
					AND: [
						{ field: 'enabled', operator: '=', value: 1 },
						{ field: 'date', operator: '>=', value: today },
					],
				};
				filter = encodeURIComponent(JSON.stringify(filter));
				//const _banners = await public_server.get(`/banner/all/ns?filter=${filter}`)
				const _banners = await public_server.get(`/banner/all/ns`);
				setBanners(_banners.data.data.banners);

				const _platform = await public_server.get('homesection/all/ns');
				setPlatform([..._platform.data.data.home_sections]);

				filter = {
					OR: [
						{ field: 'exhibition_status_id', operator: '=', value: 2 },
						{ field: 'exhibition_status_id', operator: '=', value: 4 },
					],
				};
				filter = encodeURIComponent(JSON.stringify(filter));
				const _exhibitions = is_auth ? await private_server.get(`/exhibition/all?filter=${filter}`) : await public_server.get(`/exhibition/all/ns?filter=${filter}`);
				setExhibitions(_exhibitions.data.data.exhibitions);

				//Open artwork modal from QR code
				if (query.get('artwork')) { 
                    const artwork_url = query.get('artwork')
                    console.log(`Artwork URL: ${artwork_url}`)
                    const _artwork = await public_server.get(`/artwork/url/ns?url=${artwork_url}`);

                    await onUpdateArtworkData(_artwork.data.data.artwork)
                    await onUpdateModalStatus(true);
				}

			} catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        openSignUpDrawer: async () => await onUpdateDrawerStatus(true),
        onOpenContactDrawer: async () => await onUpdateContactDrawerStatus(true),
        onClickBanner: (link) => {
            console.log(link)
            const isValidLink = onValidateURL(link)
            if (isValidLink)
                window.open(link)
        }
    }

    const system = { loading, error }
    const view_data = { banners, platform, exhibitions }

    return { system, view_data, actions }

}

export default useHomeView