import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleModal from '../../components/Structure/Modals/SimpleModal';
import classNames from "classnames"
import { isValidDocument, onGetCurrencyValue, onGetDateFormat } from '../../shared/utility';
import { CONFIG_SETTINGS, nodata, public_server } from '../../config';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import nft_icon from '../../assets/icons/NFT.svg';
import ArworkCarousel from './components/ArtworkCarousel';
import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames'

const ArtworkModal = ({ open, language, data, onUpdateModalStatus, onUpdatePurchaseDrawerStatus }) => {

    const classes = useStyles()
    const [images, setImages] = useState(null)

    const [openDetails, setOpenDetails] = useState(true)
    const [openDescription, setOpenDescription] = useState(true)

    useEffect(() => {
        const initModule = async () => {
            try {
                setImages(null)
                setOpenDetails(true)
                setOpenDescription(true)

                const image_response = await public_server.get(`artworkimage/all/${data.id_artwork}/ns`)
                const _images = [
                    { id_artwork_image: 999, mobile_image: data.mobile_image, web_image: data.web_image },
                    ...image_response.data.data.artwork_images
                ]

                setImages(_images)
            } catch (error) {
                console.log(error.response)
            }
        }
        if (data && !open) initModule()

    }, [data, open])

    const openPurchaseDrawer = async () => {
        await onUpdateModalStatus(false)
        await onUpdatePurchaseDrawerStatus(true)
    }

    const expandIcon = <ExpandMoreIcon color={'secondary'} />


    return (
        <SimpleModal open={open} sharp maxWidth={'md'} onClose={() => onUpdateModalStatus(false)} >
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        {images && <ArworkCarousel start={open} images={images} />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.content}>
                            <div style={{ minHeight: 478 }}>
                                <Typography variant='h6' className={classes.title}>{data ? data.title : nodata}</Typography>
                                <div className={classes.firt_row}>
                                    <Grid container alignItems='baseline' spacing={1}>
                                        <Grid item >
                                            <Typography variant='body2'>{data ? data.artist_professional_name : nodata}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.country}>{data ? data.country_abbreviation : nodata}</Typography>
                                        </Grid>
                                        <Grid item xs></Grid>
                                        {data && !data.sold && <Grid item>
                                            <Typography className={classes.currency}>{`${onGetCurrencyValue(data ? data.price : nodata, 0, '€ ')}`}</Typography>
                                        </Grid>}
                                    </Grid>
                                </div>
                                <div className={classes.second_row}>
                                    <Accordion expanded={openDetails} classes={{ root: classes.paper }} onChange={() => setOpenDetails(prev => !prev)}>
                                        <AccordionSummary expandIcon={expandIcon} style={{ padding: 0 }}>
                                            <Typography className={classes.heading}>Details</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ padding: 0 }}>
                                            <div style={{ paddingBottom: 16 }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography className={classes.label}>Year</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.textcontent}>{data && data.date ? onGetDateFormat(data.date, 'YYYY') : 'n.d.'}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography className={classes.label}>Technique</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.textcontent}>{data ? data.technique : nodata}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Typography className={classes.label}>Dimensions</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography className={classes.textcontent}>{data ? data.dimensions : nodata}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </div>

                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className={classes.second_row} style={{ marginBottom: 20 }}>
                                    <Accordion expanded={openDescription} classes={{ root: classes.paper }} onChange={() => setOpenDescription(prev => !prev)}>
                                        <AccordionSummary expandIcon={expandIcon} style={{ padding: 0 }}>
                                            <Typography className={classes.heading}>Description</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ padding: 0, maxHeight: 200, overflowY: 'auto' }}>
                                            <div style={{ padding: '0px 0px 16px 0px' }}>
                                                <Typography style={{ fontSize: 14, color: '#7F7F7F', lineHeight: 1.75 }}>
                                                    {data && data.description ?
                                                        data.description.replace(/\r/gm, '').split("\n").map(text => (
                                                            <p>{text}</p>
                                                        ))
                                                    :
                                                        nodata
                                                    }
                                                </Typography>

                                            </div>

                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className={classes.buttonContainer}>
                                <Grid container alignItems='center' spacing={2}>
                                    {data && data.nft > 0 &&
                                        <Grid item>
                                            <div
                                                className={cx({
                                                    [classes.nft_button]: true,
                                                    [classes.nft_button_grey]: data.nft_sold > 0
                                                })}
                                                onClick={() => window.open(data.external_url, '_target')}
                                            >
                                                <img src={nft_icon} className={classes.nft} />
                                            </div>
                                        </Grid>}
                                    <Grid item xs>
                                        {data && data.sold > 0 ?
                                            <div className={classes.sold_button}>
                                                Sold
                                            </div>
                                            :
                                            <Button fullWidth color='primary' className={classes.buy_button} onClick={openPurchaseDrawer}>
                                                Buy
                                            </Button>}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    );
}

const useStyles = makeStyles(theme => ({
    content: {
        padding: '0px 0px',
        position: 'relative',
        height: '100%'
    },
    title: {
        fontWeight: 700
    },
    currency: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    country: {
        border: '1px solid #696969',
        borderRadius: 8,
        padding: '4px 8px',
        fontSize: 12
    },
    firt_row: {
        marginTop: 16,
        paddingBottom: 8,
        borderBottom: '1px solid #9E9E9E'
    },
    second_row: {
        marginTop: 4,
        paddingBottom: 8,
        borderBottom: '1px solid #9E9E9E'
    },
    paper: {
        boxShadow: 'none',
        padding: 0,
        margin: 0
    },
    heading: {
        color: theme.palette.secondary.main
    },
    label: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 700,
        color: '#7F7F7F'
    },
    textcontent: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 500,
        color: '#7F7F7F'
    },
    nft_button: {
        background: theme.palette.secondary.main,
        width: 48, height: 48,
        position: 'relative',
        boxSizing: 'border-box',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: 6
    },
    nft_button_grey: {
        background: grey[500]
    },
    nft: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    buy_button: {
        //marginTop: -10,
        borderRadius: 8,
        fontSize: 16,
        fontWeight: 600,
        color: 'white',
        background: theme.palette.primary.main,
        padding: '12px 16px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.33)',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    sold_button: {
        //marginTop: -10,
        borderRadius: 8,
        fontSize: 16,
        fontWeight: 600,
        color: 'white',
        background: 'black',
        padding: '12px 16px',
        borderRadius: 4,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    buttonContainer: {
        bottom: 0,
        position: 'absolute',
        width: '100%',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            marginBottom: 0
        }
    }
}))

const mapStateToProps = state => {
    return {
        data: state.artwork,
        language: state.language,
        open: state.artwork_modal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModalStatus: (artwork_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_ARTWORK, artwork_modal }),
        onUpdatePurchaseDrawerStatus: (purchase_enquiry_drawer) => dispatch({ type: actionTypes.DRAWER_PURCHASE_ENQUIRY, purchase_enquiry_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkModal)