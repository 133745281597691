import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
//import ArtistRegisterDrawer from '../layouts/ArtistRegisterDrawer/ArtistRegisterDrawer';
import KuvikProLayout from '../layouts/KuvikProLayout/KuvikProLayout';
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';

const MainRouter = ({ history }) => {

    return (
        <Router history={history}>
            <Switch>
                <Route path={'/pro'} component={KuvikProLayout} />
                <Route path={'/confirmation'} component={WebsiteLayout} />
                <Route path={'/passwordchange'} component={WebsiteLayout} />
                <Route path={'/'} component={WebsiteLayout} />
                <Redirect to='/' />
            </Switch>
        </Router>
    )
}

export default MainRouter