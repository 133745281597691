import React from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        //boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
    },
    contentTitle: {
        fontSize: 32,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main,
            textTransform: 'uppercase'
        }
    },
    divider: {
        backgroundColor: grey[600]
    },
    description: {
        lineHeight: 2,
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 500,
    },
    message: {
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.secondary.main
    },
    data: {
        // minHeight: '60vh',
        //[theme.breakpoints.down('sm')]: {
        //  minHeight: 'inherit'
        //}
    },
    imageContainer: {
        position: 'relative',
        top: 0,
        paddingTop: '120%',
        maxHeight: 600,
        boxSizing: 'border-box',
        paddingTop: '100%',
        //paddingLeft: 32,
        right: 0,
        [theme.breakpoints.between(0, 960)]: {
            paddingTop: 450,
        },
        [theme.breakpoints.between(960, 985)]: {
            paddingTop: 700,
        },
        [theme.breakpoints.between(985, 1100)]: {
            paddingTop: 650,
        },
        [theme.breakpoints.between(1100, 1330)]: {
            paddingTop: 550,
        },
        /*[theme.breakpoints.only('sm')]: {
            paddingTop: '150%',
        }*/
    },
    image: {
        position: 'absolute',
        top: 0, left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 12
    },
}))

const icon_styles = { margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }

const GameChangers = ({ history, content }) => {

    const classes = styles()

    return (
        <MainContainer className={classes.root}>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.data}>
                        <Grid container justifyContent='center' spacing={4}>
                            <Grid item xs={12}>
                                <Typography className={classes.contentTitle}>
                                    <span>{content.art}</span> {content.titleS}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    {content.description.map(text => (
                                        <Grid item xs={12}>
                                            <Typography align='justify' className={classes.description}>
                                                {text}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.message} align='center'>
                                    {content.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.imageContainer}>
                        <img src={content.image} className={classes.image} />
                    </div>
                </Grid>
            </Grid>
        </MainContainer>
    )
}

export default GameChangers