import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import SVGIcon from '../Structure/DisplayData/SVGIcon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        '& div': {
            color: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiOutlinedInput-root': {
            padding: '0px 20px 0px 20px',
            height: 45,
            '&:hover': {
                '& fieldset': {
                    borderColor: theme.palette.secondary.main
                }
            },
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: theme.palette.secondary.light
            }
        },
        '& .MuiSelect-outlined':{
            paddingRight: 10,
        },
        '& fieldset': {
            borderColor: 'white',
        },
    },
}))
const LanguageButton = ({ icon, options, value, onChange }) => {

    const classes = styles()

    return (
        <TextField
            select
            value={value}
            variant='outlined'
            classes={{ root: classes.root }}
            onChange={(event) => onChange(event.target.value)}
            InputProps={{
                startAdornment: <SVGIcon
                    src={icon}
                    size={20} style={{ width: 45, margin: 10 }}
                    color='white'
                />,
            }}
            SelectProps={{
                IconComponent: (props) => <ExpandMoreIcon {...props} />
            }}
        >
            {options.map(option => (
                <option key={option.id} value={option.value}>
                    {option.label}
                </option>
            ))}
        </TextField>
    )
}

export default LanguageButton