import noImage from '../../../assets/noimage.png'
import nftIcon from '../../../assets/icons/NFT.svg'
import likeIcon from '../../../assets/icons/full_heart.svg'
import featuredIcon from '../../../assets/icons/featured.svg'

import exhibitions from '../../../assets/icons/exhibitions.svg'
import magazines from '../../../assets/icons/magazines.svg'

export const translations = {
    title: 'Home',
    sections: {
        open_calls: {
            title: 'Open calls',
            icon: featuredIcon,
            noExhibitions: {
                title: 'No exhibitions to show',
                subtitle: 'Exhibitions will show here.',
                icon: exhibitions
            },
            data: {
                title: {
                    label: 'Title'
                },
                start_date: {
                    label: 'Date'
                },
                end_date: {
                    label: 'Final decision'
                }
            },
            application: {
                id: 'application_limit_date',
                label: 'Application deadline:'
            },
            max: {
                id: 'max_artwork_number',
                label: ['max.', 'Artworks']
            }
        },
        magazines: {
            title: 'Magazines',
            icon: featuredIcon,
            noMagazines: {
                title: 'No magazines to show',
                subtitle: 'Magazines will show here.',
                icon: magazines
            },
            data: {
                title: {
                    label: 'Title'
                },
                start_date: {
                    label: 'Date'
                },
                end_date: {
                    label: 'Final decision'
                }
            },
            application: {
                id: 'application_limit_date',
                label: 'Application deadline:'
            },
            max: {
                id: 'max_artwork_number',
                label: ['max.', 'Artworks']
            }
        },
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
        },
        dimensions: {
            label: 'Dimensions',
            placeholder: 'Dimensions',
        },
        technique: {
            label: 'Technique',
            placeholder: 'Technique',
        },
        price: {
            label: 'Price',
            placeholder: 'Price',
        },
        description: {
            label: 'Description',
            placeholder: 'Description',
        },
        date: {
            label: 'Date',
            //placeholder: 'Date',
        },
        sold: {
            label: 'Sold'
        },
        nft: {
            label: 'NFT'
        },
        external_url: {
            label: 'NFT Link',
            placeholder: 'NFT Link',
        },
    },
    noImage: noImage,
    modals: {
        apply_modal: {
            icon: featuredIcon,
            document_button: 'Brochure',
            apply_button: 'Apply',
            form: {
                start_date: {
                    label: 'Date'
                },
                application_limit_date: {
                    label: 'Application date'
                },
                application_documents_limit_date: {
                    label: 'Final decision'
                },
                max_artwork_number: {
                    label: ['Artworks', 'Maximum']
                },
                description: {
                    label: 'Description',
                    multiline: true,
                    rows: 4
                }
            }
        }
    }
}