import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/pro/artworks-details-translations'
import { Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../../components/Structure/Table/ActionTopBar';
import useArtworksDetailsView from './useArtworksDetailsView'
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument, onGetCurrencyValue } from '../../../shared/utility';
import RoundedButton from '../../../components/Actions/RoundedButton';
import { catalogs } from '../../../texts/eng/catalogs';
import StopIcon from '@material-ui/icons/Stop'
import { grey } from '@material-ui/core/colors';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import cx from 'classnames'
import FilterSelect from '../../../components/Forms/FilterSelect';
import GeneralInfo from './sections/GeneralInfo';
import EditArtworkModal from './modals/EditArtworkModal';
import PictureModal from './modals/PictureModal/PictureModal';
import AdditionalImages from './sections/AdditionalImages';
import ArtworkImageModal from './modals/ArtworkImageModal/ArtworkImageModal';
import Statistics from './sections/Statistics';
import { connect } from 'react-redux';

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
    topBar: {
        [theme.breakpoints.up('md')]: {
            marginTop: -50
        }
    },
    content: {
        marginTop: 30
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    }
}))

const submitButton = {
    padding: '3.5px 20px',
    textTransform: 'uppercase'
}

const ArtworksDetailsView = ({ is_auth, language, history, match }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useArtworksDetailsView({
        language, is_auth, history, match, content
    })

    return (
        <LoadingContainer
            loading={system.loading}
            sending={system.sending}
            success={system.success}
            error={system.error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title} detail={content.detail}>
                <PictureModal open={view_data.picture_modal} id={view_data.artworkID} name={view_data.imageType} content={content.modals.picture} onClose={actions.onClosePictureModal} onUpdateArtwork={actions.onUpdateArtwork} />
                <ArtworkImageModal
                    open={view_data.artwork_image_modal}
                    id={view_data.artworkID}
                    artwork={view_data.selectedArtworkImage}
                    content={content.modals.artwork_image_modal}
                    onClose={actions.onCloseArtworkImageModal}
                    onUpdateArtworkImages={actions.onUpdateArtworkImages}
                />
                <EditArtworkModal
                    open={view_data.edit_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveArtwork}
                    onClose={actions.onCloseEditModal}
                    content={content}
                />
                <div className={classes.topBar}>
                    <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                        <Grid item>
                            <FilterSelect
                                value={view_data.status}
                                menuFilter={catalogs.artwork_statuses}
                                onFilter={actions.onSetArtworkStatus}
                                disabled
                            />
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                color='black'
                                size='small'
                                style={submitButton}
                                onClick={actions.onOpenEditModal}
                            >
                                {content.edit_button}
                            </RoundedButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.deleteButton}
                                onClick={actions.onDeleteArtwork}
                            >
                                <SVGIcon src={content.delete} size={22} color='white' />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {view_data.artwork &&
                                <GeneralInfo
                                    imageType={view_data.imageType}
                                    data={view_data.artwork}
                                    form={view_data.form}
                                    content={content.sections.general_info}
                                    onEditPicture={actions.onOpenPictureModal}
                                    onUpdateImageType={actions.onUpdateImageType}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <AdditionalImages
                                images={view_data.artworkImages}
                                content={content.sections.additional_images}
                                onUpdateImage={actions.onOpenArtworkImageModal}
                                onDeleteImage={actions.onDeleteImage}
                            />
                        </Grid>
                        {/*<Grid item xs={12}>
                            {view_data.artwork &&
                                <Statistics
                                    content={content.sections.statistics}
                                    data={view_data.artwork}
                                />
                            }
                        </Grid>*/}
                    </Grid>
                </div>
            </TitleContainer>
        </LoadingContainer>
    )
}

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        language: state.language
    }
}

export default connect(mapStateToProps, null)(ArtworksDetailsView)