import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import { catalogs } from '../../../../texts/eng/catalogs'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import RoundedButton from '../../../../components/Actions/RoundedButton'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    iconButton: {
        background: 'white',
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)'
    },
    sectionTitle: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    },
    labelContent: {
        marginTop: 20,
        background: 'white',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        borderRadius: 5,
        padding: 20
    },
    label: {
        whiteSpace: "pre",
        color: '#5E5E5E',
        fontSize: 16,
        fontWeight: 400,
        padding: 20,
        borderRadius: 4,
        background: 'rgba(189, 139, 64, 0.04)',
        maxHeight: 200,
        overflow: 'auto'
    }
}))

const About = ({ data, content, submitButton, onEdit }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={onEdit}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        {Object.keys(content.form).map(key => (
                            <Grid item xs={12}>
                                <Typography className={classes.sectionTitle}>
                                    {content.form[key].label}
                                </Typography>
                                <div className={classes.labelContent}>
                                    <Typography className={classes.label}>
                                        {data[key]}
                                    </Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default About