import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { connect } from "react-redux"
import RoundedButton from "../../../components/Actions/RoundedButton"
import InputFormG from "../../../components/Forms/InputFormG"
import InputFormW from "../../../components/Forms/InputFormW"

const Form = ({ area, form, content, actions }) => (

    <Grid container justifyContent='center' spacing={5}>
        <Grid item xs={12} sm={area === 4 ? 12 : 6}>
            <InputFormW data={form.first_name} onChange={actions.onChange} />
        </Grid>
        {area !== 4 &&
            <Grid item xs={12} sm={6}>
                <InputFormW data={form.last_name} onChange={actions.onChange} />
            </Grid>
        }
        {Object.keys(content.form.fixed).map((item, key) => {
            let input = (
                <Grid item xs={12} sm={6} key={`form.0_${key}}`}>
                    <InputFormW data={form[item]} onChange={actions.onChange} />
                </Grid>
            )

            // if (item === 'country_code')
            //     input = (
            //         <Grid item xs={3} sm={2} key={`form.0_${key}}`}>
            //             <InputFormW data={form.country_code} onChange={actions.onChange} />
            //         </Grid>
            //     )
            if (item === 'mobile')
                input = (
                    <Grid item xs={9} sm={6} key={`form.0_${key}}`}>
                        <InputFormW data={form.mobile} onChange={actions.onChange} />
                    </Grid>
                )


            return input

        })}

        <Grid item xs></Grid>
        <Grid item xs={12}>
            <Grid container justifyContent='center' alignItems='center' spacing={8}>
                <Grid item>
                    <Typography variant='body2'>{content.reference_code.title}</Typography>
                </Grid>
                <Grid item>
                    <InputFormG data={form.reference_code} variant='standard' onChange={actions.onChange} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <RoundedButton onClick={actions.onSubmit}>
                {content.button}
            </RoundedButton>
        </Grid>
    </Grid>
)

const mapStateToProps = (state) => {
    return {
        profile_picture: state.profile_picture
    }
}

export default connect(mapStateToProps)(Form)