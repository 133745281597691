import React, { useState, useEffect } from "react"
import { Fade, Divider, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../../config"
import { isValidDocument } from "../../../../../shared/utility"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"
import cx from 'classnames'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./VirtualCarousel.css"
import GenericImage from "../../../../../components/Structure/DisplayData/GenericImage"
import RoundedButton from "../../../../../components/Actions/RoundedButton"

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
}

const styles = makeStyles(theme => ({
    root: {
        background: 'black',
        color: 'white',
        paddingTop: 80,
        paddingBottom: 100,
    },
    content: {
        margin: 'auto',
        boxSizing: 'border-box',
        paddingLeft: 32,
        paddingRight: 32
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noExhibitions: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 550,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
}))

const Virtual = ({ history, exhibitions, content, onVisitExhibition, onSaveExhibition, onDownloadDocument }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const deviceType = mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    const [currentSlide, setCurrentSlide] = useState(2)
    const [nextSlide, setNextSlide] = useState(false)
    const [interval, setIntervalState] = useState(null)

    useEffect(() => {
        setIntervalState(setInterval(() => setNextSlide(true), 8000))
        return () => clearInterval(interval)
    }, [])

    useEffect(() => console.log(exhibitions), [exhibitions])

    const CustomArrow = ({ onClick, side }) => {

        const handleClick = () => {
            clearInterval(interval)
            setIntervalState(null)
            setNextSlide(false)
            onClick()
            setIntervalState(setInterval(() => setNextSlide(true), 8000))
        }
        if (nextSlide && side === 'right') handleClick()

        return (
            <button
                onClick={handleClick}
                aria-label="Go to next slide"
                className={
                    `react-multiple-carousel__arrow
                    react-multiple-carousel__arrow--${side}
                    carousel_arrow 
                    carousel_arrow-${side}`
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </MainContainer>
            <div className={classes.carouselContainer}>
                {exhibitions.length > 2 ?
                    <Carousel
                        responsive={responsive}
                        ssr
                        infinite
                        keyBoardControl
                        centerMode={!mobile}
                        customTransition="transform 300ms ease-in-out"
                        itemClass="carouselArtistItem"
                        deviceType={deviceType}
                        removeArrowOnDeviceType={["mobileXS"]}
                        afterChange={(previousSlide, { currentSlide, onMove }) => {
                            //console.log("Cambio")
                            //console.log("previous", previousSlide)
                            //console.log("current", currentSlide)
                            setCurrentSlide(currentSlide)
                        }}
                        customLeftArrow={<CustomArrow side='left' />}
                        customRightArrow={<CustomArrow side='right' />}
                    >
                        {exhibitions.map((item, key) => {
                            let idx = currentSlide - 2 === key
                            return (
                                <CarouselItem
                                    key={key.toString()}
                                    current={idx}
                                    {...item}
                                    content={content}
                                    noImage={content.noImage}
                                    onClick={onVisitExhibition}
                                />
                            )
                        })}
                    </Carousel>
                    :
                    <div className={classes.content}>
                        <Grid container justifyContent='center'>
                            {exhibitions.map((item, key) => (
                                <Grid item xs={6}>
                                    <CarouselItem
                                        key={key.toString()}
                                        {...item}
                                        content={content}
                                        noImage={content.noImage}
                                        onClick={onVisitExhibition}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                }
            </div>
        </div >
    )
}

export default Virtual

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        //minHeight: '100vh',
        color: 'white',
        paddingTop: 64,
        paddingBottom: 64,
        transition: 'padding 0s linear 100ms',
        [theme.breakpoints.down('md')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 32,
            boxSizing: 'border-box',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    current: {
        //padding: 0,
        transition: 'padding 0s linear'
    },
    currentFadeIn: {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0s linear, opacity 500ms linear',
        minHeight: 150,
    },
    currentFadeOut: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 500ms, opacity 500ms linear',
        minHeight: 150,
    },
    content: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        }
    },
    image: {
        width: '100%',
        height: 600,
        objectFit: 'contain',
        //filter: 'brightness(50%)',
        //borderRadius: 5
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        [theme.breakpoints.only('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
        }
    },
    date: {
        fontSize: 24,
        fontWeight: 400,
        [theme.breakpoints.only('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
        }
    },
    short_description: {
        fontWeight: 400,
        fontSize: 18,
        // lineHeight: 30,
        //letterSpacing: '0.265em',
        [theme.breakpoints.only('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 14,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 12,
        }
    },
    description: {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: '272.24%',
        [theme.breakpoints.only('md')]: {
            fontSize: 24,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
        }
    },
    current_card: {
        width: '100%'
    },
    content: {
        top: 0,
        position: 'absolute',
        background: 'black',
        width: '100%',
        height: '100%',
        opacity: 0,
        padding: 20,
        boxSizing: 'border-box',
        overflow: 'hidden',
        transition: 'opacity 0.5s ease-in-out',
    },
    content_show: {
        opacity: 0.5
    },
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    bottom: {
        position: 'absolute',
        top: '90%',
        left: '50%',
        transform: 'translate(-50%, -90%)'
    },
    bottomTitle: {
        position: 'absolute',
        top: '100%',
        width: '100%',
        marginTop: 0,
        transition: 'margin-top 0.2s ease-in-out',
    },
    bottomTitleCurrent:{
        marginTop: 50
    }
}))

const CarouselItem = ({ content, current, short_description, medium_description,
    exhibition_title, date_text, exhibition_url, exhibition_web_image,
    exhibition_mobile_image, noImage, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [hover, setHover] = useState(false)

    const _image = mobile ? exhibition_mobile_image : exhibition_web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : noImage
    const width = current ? '100%' : '85%'

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}

            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div style={{ width: '95%', paddingTop: '63.75%', margin: 'auto', position: 'relative' }}>
                <div style={{
                    boxSizing: 'border-box',
                    position: 'absolute',
                    width: '100%',
                    background: 'transparent',
                    top: '50%', left: 0,
                    transform: 'translateY(-50%)',
                    borderRadius: 5,
                }}
                //onClick={onClick}
                >
                    <GenericImage src={_image} style={{ transition: 'all 0.2s linear', width:width}} ratio={current ? '4/3' : '16/9'} />
                    <div className={cx({
                        [classes.content]: true,
                        [classes.content_show]: hover
                    })}>
                        <Typography gutterBottom className={classes.short_description} align='center'>
                            {date_text}
                        </Typography>
                        <div className={classes.center}>
                            <Typography className={classes.description} align='center'>
                                {short_description}
                            </Typography>
                        </div>
                        <div className={classes.bottom}>
                            <RoundedButton size='small' color='transparent_white_square' onClick={() => onClick(exhibition_url)}>
                                {content.visit}
                            </RoundedButton>
                        </div>
                    </div>
                </div>
                <div className={cx({
                    [classes.bottomTitle]: true,
                    [classes.bottomTitleCurrent]: !mobile && current
                })}>
                    <Typography align='center' className={classes.title}>
                        {exhibition_title}
                    </Typography>
                </div>
            </div>
        </div>
    )
}