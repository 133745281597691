import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import cx from 'classnames'
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    galleryTitle: {
        fontSize: 36,
        fontWeight: 600,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    imageContainer: {
        position: 'relative'
    },
    noArtist: {
        fontSize: 16,
        fontWeight: 300
    },
    carouselItem: {
        height: 650,
        marginTop: -100,
        '& li': {
            margin: 10,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: 10,
                boxSizing: 'border-box'
            }
        },
        '& ul.react-multi-carousel-dot-list': {
            top: 0,
            marginTop: 600,
            height: 'fit-content',
        }
    },
    lineButton: {
        width: 120,
        height: 1.5,
        borderRadius: 0,
        //marginLeft: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    button: {
        display: 'block',
        margin: 'auto'
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: '0.525em'
    }
}))

const Individuals = ({ history, magazines, content, onClick }) => {

    const classes = styles()
    const [seeMore, setSeeMore] = useState(false)

    return (
        <MainContainer className={classes.root}>
            <Grid container justifyContent='center' spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title} align='right'>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {magazines.length > 0 ?
                        (magazines.length > 1 ?
                            <Grid container spacing={4}>
                                {magazines.map(magazine => (
                                    <Grid item xs={12} sm={6}>
                                        <MagazineCard {...magazine} content={content} onClick={onClick} />
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <Grid container justifyContent='center'>
                                <Grid item sm={6}>
                                    <MagazineCard {...magazines[0]} content={content} onClick={onClick} />
                                </Grid>
                            </Grid>
                        )
                        :
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}>
                                <Typography className={classes.noArtist} align='center'>
                                    {content.noContent}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </MainContainer>
    )
}

export default Individuals

const stylesCard = makeStyles(theme => ({
    root: {
        maxWidth: 503,
        boxShadow: 'none',
        margin: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 5,
    },
    cardActions: {
        width: '100%',
        background: 'transparent',
        padding: 0,
        paddingTop: 10,
        display: 'block',
    },
    cardContent: {
        display: 'none',
        position: 'absolute',
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        width: '100%',
        height: '100%',
        background: 'grey',
        paddingTop: "calc(100% * (3 / 2))",
        borderRadius: 5,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        //filter: 'drop-shadow(0px 4.80392px 36.0294px rgba(0, 0, 0, 0.23));'
    },
    mediaH: {
        filter: 'drop-shadow(0px 4.80392px 36.0294px rgba(0, 0, 0, 0.23)) brightness(0.6)'
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: 'black'
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        //textTransform: 'uppercase'
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 20
    }
}))

const MagazineCard = ({ title, description, web_image, mobile_image, content, onClick, url, featured }) => {

    const classes = stylesCard()

    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const [hover, setHover] = useState(false)
    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    return (
        <Card className={classes.root} onClick={() => onClick(url)}>
            <CardActionArea
                className={classes.cardActionArea}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaH]: hover
                    })}
                    image={selectedImage}
                    title={"Magazine Individuals"}
                />
                <CardContent className={cx({
                    [classes.cardContent]: true,
                    [classes.showCardContent]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item xs={12} sm={6}>
                            <RoundedButton
                                fullWidth
                                color='white_square'
                                onClick={() => onClick(url)}
                            >
                                {content.open}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </CardContent>
                {featured && featured > 0 ?
                    <div className={classes.featuredTag}>
                        <SVGIcon src={content.featuredIcon} color={hover ? 'white' : theme.palette.secondary.main} size={42} />
                    </div>
                    : null}
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12}>
                        <Typography align='center' gutterBottom className={classes.title}>
                            {title}
                        </Typography>
                        <Typography align='center' className={classes.description}>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}