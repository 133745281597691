import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import InputFormW from "../../../components/Forms/InputFormW"
import RoundedButton from "../../../components/Actions/RoundedButton"

const styles = makeStyles(theme => ({
    title: {
        fontSize: 22,
        fontWeight: 500
    },
}))

const Step3 = ({ form, content, actions }) => {
    const classes = styles()

    return (
        <Grid container justifyContent='center' spacing={8}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' alignItems='center' spacing={5}>
                    {Object.keys(content.form).map((item, key) => {
                        
                        return (
                            <Grid item xs={12} key={`form.2_${key}}`}>
                                <Grid container justifyContent='center' spacing={2}>
                                    <Grid item xs>
                                        <Typography className={classes.title}>{form[item].config.label}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='caption'>(max. {form[item].rules.max} {form[item].config.words ? content.words : content.characters})</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputFormW data={form[item]} onChange={actions.onChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item>
                <RoundedButton onClick={actions.onNextStep}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

export default Step3