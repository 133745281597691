import React, { useState, useEffect } from 'react'
import { isValidDocument, onGetErrorMessage, onGetFormData, onInitForm } from '../../../shared/utility'
import queryString from 'query-string'
import { CONFIG_SETTINGS, private_server } from '../../../config'
import axios from 'axios'
import FileSaver from 'file-saver'

const useHomeView = ({ is_auth, history, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [magazines, setMagazines] = useState([])
    const [exhibitions, setExhibitions] = useState([])
    const [selected_item, setSelectedItem] = useState(null)

    //MODAL
    const [apply_modal, setApplyModal] = useState(false)

    useEffect(() => {
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitForm(_form, null, content.form)
                setForm(_form)
                //exhibition_status_id
                let filterArgs = {
                    OR: [
                        { field: "exhibition_status_id", operator: "=", value: 2 },
                        { field: "exhibition_status_id", operator: "=", value: 3 }
                    ]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params2send = { offset: 0, order: 0, order_by: 'id_exhibition' }
                let params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

                const _exhibitions = await private_server.get(`exhibition/all${params}`)
                setExhibitions([..._exhibitions.data.data.exhibitions])

                filterArgs = {
                    OR: [
                        { field: "magazine_status_id", operator: "=", value: 2 },
                        { field: "magazine_status_id", operator: "=", value: 3 }
                    ]
                }

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params2send = { offset: 0, order: 0, order_by: 'id_magazine' }
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

                const _magazines = await private_server.get(`magazine/all${params}`)
                setMagazines([..._magazines.data.data.magazines])

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onSelectItem: (item) => {
            setApplyModal(true)
            setSelectedItem(item)
        },
        onCloseAddModal: () => setApplyModal(false),
        onDownloadDocument: async (document) => {
            try {
                setSending(true)

                if (isValidDocument(document)) {
                    let file = `${CONFIG_SETTINGS.S3_SERVER_URL}${document}`
                    console.log(file)
                    //let response = await private_server.get()
                    let response = await axios.get(file, { responseType: 'blob' })
                    FileSaver.saveAs(response.data, 'brochure')
                }
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { form, exhibitions, magazines, selected_item, apply_modal }

    return { system, view_data, actions }
}

export default useHomeView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'number',
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    sold: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sold'
        }
    },
    nft: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'nft'
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
}