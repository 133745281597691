import React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import { onGetDateFormat } from '../../../../shared/utility'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    topTitle: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    title: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    },
}))

const Payments = ({ content, data }) => {

    const classes = styles()

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.topTitle}>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>

            </Grid>
        </Grid>
    )
}

const stylesData = makeStyles(theme => ({
    label: {
        fontSize: 14,
        fontWeight: 600,
        color: '#A0A0A0'
    },
    text: {
        fontSize: 16,
        color: '#151515'
    }
}))

const DisplayData = ({ label, data, isDate, format }) => {

    const classes = stylesData()

    let formatData = data ? data : '-'
    if (isDate)
        formatData = format ? onGetDateFormat(formatData, format) : onGetDateFormat(formatData, 'DD MMM YYYY')

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.label}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text}>
                    {formatData}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Payments