import React from 'react'
import { translations as translations_esp } from '../../../texts/eng/pro/artist-profile-translations'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../../components/Structure/Layouts/TitleContainer'
import useArtistProfileView from './useArtistProfileView'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { catalogs } from '../../../texts/eng/catalogs'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import FilterSelect from '../../../components/Forms/FilterSelect'
import GeneralInfo from './sections/GeneralInfo'
import EditArtistModal from './modals/EditArtistModal'
import PictureModal from './modals/PictureModal/PictureModal'
import Images from './sections/Images'
import ArtistImageModal from './modals/ArtistImageModal/ArtistImageModal'
import { actionTypes } from '../../../store/actions'
import { connect } from 'react-redux'
import SocialMedias from './sections/SocialMedias'
import EditSocialMediasModal from './modals/EditSocialMediasModal'
import About from './sections/About'
import EditAboutModal from './modals/EditAboutModal'
import Documents from './sections/Documents'
import DocumentModal from './modals/DocumentModal'
import Membership from './sections/Membership'
import Payments from './sections/Payments'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
}))

const submitButton = {
    fontSize: 14,
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'uppercase'
}

const ArtistProfileView = ({ is_auth, history, match, user, onUpdateModalStatus }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useArtistProfileView({
        is_auth, history, match, content, onUpdateModalStatus
    })


    return (
        <LoadingContainer
            loading={system.loading}
            sending={system.sending}
            success={system.success}
            error={system.error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title} detail={content.detail}>
                <DocumentModal content={content.modals.documents} open={view_data.document_modal} onUpdateDocument={actions.onUpdateDocument} loading={system.sending} error={system.error} onClose={actions.onCloseDocumentModal} />
                <PictureModal open={view_data.picture_modal} content={content.modals.picture} onClose={actions.onClosePictureModal} onUpdateArtist={actions.onUpdateArtist} />
                <ArtistImageModal
                    open={view_data.professional_image_modal}
                    id={view_data.artistID}
                    professionalImage={view_data.selectedProfessionalImage}
                    content={content.modals.professional_image}
                    onClose={actions.onCloseProfessionalImageModal}
                    onUpdateArtistImages={actions.onUpdateProfessionalImages}
                />
                <EditArtistModal open={view_data.edit_modal} form={view_data.form} onChange={actions.onChange} onSubmit={actions.onSaveArtist} onClose={actions.onCloseEditModal} content={content} />
                <EditSocialMediasModal open={view_data.edit_social_modal} form={view_data.form} onChange={actions.onChange} onSubmit={actions.onSaveArtist} onClose={actions.onCloseEditSocialModal} content={content}
                />
                <EditAboutModal
                    open={view_data.edit_about_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveArtist}
                    onClose={actions.onCloseEditAboutModal}
                    content={content}
                />
                <Grid container spacing={4}>
                    {view_data.artist &&
                        <>
                            <Grid item xs={12}>
                                <GeneralInfo
                                    status={view_data.status}
                                    data={view_data.artist}
                                    form={view_data.form}
                                    user={user}
                                    submitButton={submitButton}
                                    content={content.sections.general_info}
                                    onEditPicture={actions.onOpenProfileModal}
                                    onOpenEditModal={actions.onOpenEditModal}
                                    onSetArtistStatus={actions.onSetArtistStatus}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SocialMedias
                                    submitButton={submitButton}
                                    data={view_data.artist}
                                    form={view_data.form}
                                    content={content.sections.social_medias}
                                    onEdit={actions.onOpenEditSocialModal}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <About
                                    submitButton={submitButton}
                                    data={view_data.artist}
                                    form={view_data.form}
                                    content={content.sections.about}
                                    onEdit={actions.onOpenEditAboutModal}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Documents
                                    submitButton={submitButton}
                                    files={view_data.files}
                                    content={content.sections.documents}
                                    onUpload={actions.onOpenDocumentModal}
                                    onDownload={actions.onDownloadDocument}
                                    onRemove={actions.onRemoveDocument}
                                /></Grid>
                            <Grid item xs={12}>
                                <Images
                                    data={view_data.artist}
                                    images={view_data.professionalImages}
                                    content={content.sections.images}
                                    onUpdateImage={actions.onOpenProfessionalImageModal}
                                    onDeleteImage={actions.onDeleteImage}
                                    onEditPicture={actions.onOpenPictureModal}
                                    onSetMain={actions.onSetMain}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Membership
                                    content={content.sections.membership}
                                    data={view_data.artist}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                                <Payments
                                    content={content.sections.payments}
                                    data={view_data.artist}
                                />
                    </Grid>*/}
                        </>
                    }
                </Grid>
            </TitleContainer>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        is_auth: state.is_auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateModalStatus: (profile_picture_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_PROFILE_PICTURE, profile_picture_modal })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistProfileView)