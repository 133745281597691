import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isValidDocument, onGetErrorMessage, onGetFileExtension, onGetSelectedFormData, onInitForm } from '../../../shared/utility'
import { CONFIG_SETTINGS, private_server } from '../../../config'
import { catalogs } from '../../../texts/eng/catalogs'
import axios from 'axios'
import FileSaver from 'file-saver'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useArtistProfileView = ({ is_auth, history, match, content, onUpdateModalStatus }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [status, setStatus] = useState(0)
    const [artist, setArtist] = useState(null)
    const [disciplines, setDisciplines] = useState([])
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [selectedProfessionalImage, setSelectedProfessionalImage] = useState(null)
    const [professionalImages, setProfessionalImages] = useState([])

    //MODALS
    const [document_modal, setDocumentModal] = useState(false)
    const [picture_modal, setPictureModal] = useState(false)
    const [professional_image_modal, setProfessionalImageModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [edit_social_modal, setEditSocialModal] = useState(false)
    const [edit_about_modal, setEditAboutModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log('PROFILE', is_auth)
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    useEffect(() => {
        let _form = JSON.parse(JSON.stringify(formData))
        _form.discipline_id.options = [...disciplines]

        Object.keys(content.sections).map(section => {
            if (content.sections[section].form)
                _form = onInitForm(_form, artist, content.sections[section].form)
        })

        setForm(_form)

        let _files = JSON.parse(JSON.stringify(filesData))
        Object.keys(content.sections.documents.files).map(fileID => {
            _files[fileID] = { ..._files[fileID], ...content.sections.documents.files[fileID] }
            _files[fileID].status = artist && isValidDocument(artist[fileID])
        })

        setFiles(_files)

    }, [artist, edit_modal, edit_social_modal, edit_about_modal])

    const actions = {
        initModule: async () => {
            try {
                setLoading(true)
                let _artist = await private_server.get(`artist/me`)
                _artist = _artist.data.data.artist
                setArtist({ ..._artist })
                setStatus(_artist.artist_status_id)

                let disciplines = await private_server.get('artist/disciplines')
                disciplines = disciplines.data.data.artist_disciplines
                disciplines = disciplines.map(discipline => {
                    return {
                        id: discipline.id_discipline,
                        value: discipline.id_discipline,
                        label: discipline.subject
                    }
                })

                setDisciplines(disciplines)

                let _professionalImages = await private_server.get(`professionalimage/all/me`)
                _professionalImages = _professionalImages.data.data.professional_images

                let _gallery = []

                _professionalImages.map(image => {
                    const _image = image ? image.image : null

                    let isValid = isValidDocument(_image)
                    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : null

                    _gallery.push({ id: image.id_professional_image, image: selectedImage, main: image.main })
                })

                setProfessionalImages(_gallery)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onsetArtistStatus: (value) => setStatus(value),
        //PROFILE
        onOpenProfileModal: async () => await onUpdateModalStatus(true),
        //GENERAL INFO
        onOpenEditModal: () => setEditModal(true),
        onCloseEditModal: () => setEditModal(false),
        //SOCIAL MEDIAS
        onOpenEditSocialModal: () => setEditSocialModal(true),
        onCloseEditSocialModal: () => setEditSocialModal(false),
        //ABOUT
        onOpenEditAboutModal: () => setEditAboutModal(true),
        onCloseEditAboutModal: () => setEditAboutModal(false),
        //BG BANNER
        onOpenPictureModal: () => setPictureModal(true),
        onClosePictureModal: () => setPictureModal(false),
        //MAIN
        onSetMain: async (image) => {
            let _image = { ...image, main: 1 }
            await actions.onUpdateProfessionalImages(_image)
        },
        //MAIN - PROFESSIONAL IMAGES
        onOpenProfessionalImageModal: (image) => {
            setSelectedProfessionalImage(image)
            setProfessionalImageModal(true)
        },
        onCloseProfessionalImageModal: () => {
            setProfessionalImageModal(false)
            setSelectedProfessionalImage(null)
        },
        /**** */
        onDeleteArtist: async () => {
            try {
                setSending(true)
                await private_server.delete(`artist/me`)
                //LOG OUT?
                history.push('/')
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSaveArtist: async () => {
            try {
                setSending(true)

                const data2send = onGetSelectedFormData(form, artist)
                //data2send['artist_status_id'] = status
                console.log(data2send)

                if (Object.keys(data2send).length > 0) {
                    let _artist = await private_server.patch(`artist/me`, data2send)
                    _artist = _artist.data.data.artist
                    setArtist({ ..._artist })
                }

                setError(null)
                setEditModal(false)
                setEditSocialModal(false)
                setEditAboutModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateArtist: async () => {
            try {
                setSending(true)

                let _artist = await private_server.get(`artist/me`)
                _artist = _artist.data.data.artist
                setArtist({ ..._artist })

                let _form = JSON.parse(JSON.stringify(formData))

                Object.keys(content.sections).map(section => {
                    _form = onInitForm(_form, _artist, content.sections[section].form)
                })

                setForm(_form)

                setError(null)
                setPictureModal(false)
                setDocumentModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        //MAIN & MULTI PROFESSIONAL IMAGES
        onUpdateProfessionalImages: async (image) => {
            try {
                let _prevGallery = [...professionalImages]
                let _imageIndex = _prevGallery.findIndex((a => a.id === image.id))
                let _image = { ...image }

                if (image.main > 0) {
                    let _imagePrevMain = _prevGallery.findIndex((a => a.main === 1))
                    if (_imagePrevMain.id !== image.id)
                        _prevGallery[_imagePrevMain] = { ..._prevGallery[_imagePrevMain], main: 0 }

                    await private_server.patch(`professionalimage/${image.id}/me`, { main: 1 })
                }

                if (_imageIndex !== -1)
                    _prevGallery[_imageIndex] = { ..._image }
                else// if (_prevGallery.length < 12)
                    _prevGallery.push(_image)

                //onSubmitArtwork
                setProfessionalImages(_prevGallery)
                setProfessionalImageModal(false)
                await actions.onUpdateArtist()
                //setSelectedProfessionalImage(null)
                setSending(false)
            } catch (error) {
                console.log(error)
            }
        },
        onDeleteImage: async (image) => {
            try {
                setSending(true)
                let _gallery = [...professionalImages]
                const index = _gallery.indexOf(image)

                if (index > -1) {
                    await private_server.delete(`professionalimage/${image.id}/me`)
                    _gallery.splice(index, 1)
                }

                setProfessionalImages(_gallery)
                await actions.onUpdateArtist()
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
        },
        //DOCUMENTS
        onOpenDocumentModal: document => {
            setSelectedDocument(document)
            setDocumentModal(true)
        },
        onCloseDocumentModal: () => {
            setSelectedDocument(null)
            setDocumentModal(false)
        },
        onUpdateDocument: async (file) => {
            try {
                if (!file) {
                    setError('A document must be attached')
                    return
                }

                setSending(true)
                const file2send = { name: selectedDocument.key, ext: onGetFileExtension(file.name) }
                const options = { headers: { 'Content-Type': file.type } }
                const response = await private_server.post(`artist/me/file`, file2send)
                await axios.put(response.data.data.url, file, options)

                await actions.onUpdateArtist()
                setSuccess(true)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onDownloadDocument: async (document) => {
            try {
                setSending(true)

                let file = artist[document.key]

                if (isValidDocument(file)) {
                    file = `${CONFIG_SETTINGS.S3_SERVER_URL}${file}`
                    console.log(file)
                    //let response = await private_server.get()
                    let response = await axios.get(file, { responseType: 'blob' })
                    FileSaver.saveAs(response.data, document.label)
                }
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onRemoveDocument: async (document) => {
            try {
                setSending(true)

                const data = { name: document.path }

                await private_server.delete(`artist/me/file`, { params: data })
                await actions.onUpdateArtist()

                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, success, error }
    const view_data = {
        files, form, status, artist, selectedProfessionalImage, selectedDocument,
        professionalImages, picture_modal, professional_image_modal,
        edit_modal, edit_social_modal, edit_about_modal, document_modal
    }

    return { system, view_data, actions }
}

export default useArtistProfileView

const filesData = {
    cv: { key: 'cv', path: 'cv', status: false },
    portfolio: { key: 'portfolio', path: 'portfolio', status: false },
    other_documents: { key: 'other_documents', path: 'other_documents', status: false },
}

const formData = {
    referenced_by: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: null,
        config: {
            id: 'referenced_by',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    approved_by: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: null,
        config: {
            id: 'approved_by',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    professional_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'professional_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.user_genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    country_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.countries],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    discipline_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'discipline_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    country_code: {
        value: '+49',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [...catalogs.country_codes],
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    mobile: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone_number'
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    facebook: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'facebook',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    twitter: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'twitter',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    instagram: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'instagram',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    tiktok: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'tiktok',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    pinterest: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pinterest',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    youtube: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'youtube',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    vimeo: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'vimeo',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    opensea: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'opensea',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    web: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'web',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    tweet: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tweet',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 140
        }
    },
    biography: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'biography',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 250
        }
    },
    statement: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'statement',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 200
        }
    },
    why_you: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'why_you',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'words',
            min: 1,
            max: 200
        }
    },
    suscription_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'suscription_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    renewal_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'renewal_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    cancelation_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'cancelation_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
}