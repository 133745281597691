import { Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import SwitchForm from '../../../../components/Forms/SwitchForm'
import SimpleModal from '../../../../components/Structure/Modals/SimpleModal'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import FilledDisplayDataValue from '../../../../components/Structure/DisplayData/FilledDisplayDataValue'
import GenericImage from '../../../../components/Structure/DisplayData/GenericImage'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 32
    },
    title: {
        fontSize: 18,
        fontWeight: 600
    },
    imageContainer: {
        position: 'relative'
    },
    featured: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 15,
        paddingTop: 0,
    },
    artworks: {
        fontSize: 12,
        fontWeight: 700,
        color: 'black',
        '& span:first-child': {
            fontWeight: 500
        },
        '& span:last-child': {
            fontSize: 16,
        }
    }
}))

const DataModal = ({ open, data, onSubmit, onDownloadDocument, onClose, content }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const image = data ? mobile ? data.mobile_image : data.web_image : null

    const document = data ? data.brochure : null

    return data ? (
        <SimpleModal
            sharp
            padding='small'
            maxWidth='xs'
            open={open}
            onClose={onClose}
        >
            <div className={classes.root}>
                <Grid container justifyContent='center' alignItems='center' spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>
                                    {data.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.imageContainer}>
                                    <GenericImage src={image} ratio={'16/9'} borderRadius={5} />
                                    <div className={classes.featured}>
                                        <SVGIcon src={content.icon} color='white' size={42} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FilledDisplayDataValue data={content.form.start_date} value={data.start_date} format={'DD MMM YYYY'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FilledDisplayDataValue data={content.form.application_limit_date} value={data.application_limit_date} format={'DD MMM YYYY'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FilledDisplayDataValue data={content.form.application_documents_limit_date} value={data.application_documents_limit_date} format={'DD MMM YYYY'} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.artworks}>
                            {content.form.max_artwork_number.label[0]}: <span>{content.form.max_artwork_number.label[1]}</span> <span>{data.max_artwork_number}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FilledDisplayDataValue data={content.form.description} value={data.description} />
                    </Grid>
                    {document &&
                        <Grid item>
                            <RoundedButton
                                color='transparent_secondary'
                                onClick={() => onDownloadDocument(document)}
                            >
                                {content.document_button}
                            </RoundedButton>
                        </Grid>
                    }
                    <Grid item>
                        <RoundedButton
                            disabled
                            onClick={onSubmit}
                        >
                            {content.apply_button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    ) : null
}

export default DataModal