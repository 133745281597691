import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"
import { CONFIG_SETTINGS } from "../../../../../config"
import { isValidDocument } from "../../../../../shared/utility"
import { catalogs } from "../../../../../texts/eng/catalogs"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"
import './TopClassesCarousel.css'
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import StopIcon from '@material-ui/icons/Stop'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1500 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 1500, min: 900 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 900, min: 700 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
    }
}

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        },
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    noClasses: {
        fontSize: 16,
        fontWeight: 300
    },
    carousel: {
        paddingTop: 80,
        paddingLeft: 32,
        paddingRight: 32,
        maxWidth:1440,
        margin:'auto',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 64,
            paddingRight: 64
        }
    }
}))

const TopClasses = ({ history, masterclasses, content, onGoToUrl }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))
    const tablet = useMediaQuery(theme.breakpoints.down('md'))
    const deviceType = mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </MainContainer>
            <div className={classes.carousel}>
                {masterclasses.length > 0 ?
                    <Carousel
                        responsive={responsive}
                        ssr
                        infinite
                        autoPlay
                        //autoPlaySpeed={1000}
                        keyBoardControl
                        centerMode={false}
                        customTransition="transform 300ms ease-in-out"
                        transitionDuration={500}
                        deviceType={deviceType}
                        removeArrowOnDeviceType={'mobile'}
                        itemClass={'top-classes-carouselItem'}
                        additionalTransfrom={!mobile ? (0 * masterclasses.length) : 0}
                    >
                        {masterclasses && masterclasses.map(masterclass =>
                            <MasterclassCard {...masterclass} history={history} content={content} onClick={onGoToUrl} />
                        )}
                    </Carousel>
                    :
                    <Typography className={classes.noClasses} align='center'>
                        {content.noContent}
                    </Typography>
                }
            </div>
        </div >
    )
}

export default TopClasses

const styles_class_card = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'block',
        boxShadow: 'none',
        margin: 'auto',
    },
    cardActionArea: {
        //pointerEvents: 'none',
        position: 'relative',
    },
    cardContent: {
        color: 'white',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        background: 'grey',
        paddingTop: "calc(100% * (4 / 3))",
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 5,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        filter: 'brightness(0.5)'
    },
    artist: {
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'capitalize',
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        }
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        letterSpacing: '0.265em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10
        }
    },
    freeButton: {
        width: 80,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: 'white',
        background: '#151515',
        padding: '12px 28px',
        borderRadius: 4,
        fontSize: 14,
        [theme.breakpoints.only('xs')]: {
            width: 'auto',
            fontSize: 12,
            padding: '5px 20px',
        }
    },
    level: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.165em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 10,
        }
    },
    bottom: {
        width: '100%',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: 32,
        textAlign: 'center'
    },
    levelInd: {
        fontSize: 12
    }
}))

const MasterclassCard = ({ history, url, artist, title, masterclass_level, masterclass_level_id,
    content, web_image, mobile_image, paid, onClick }) => {

    const classes = styles_class_card()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'))

    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    let level = []
    for (let i = 0; i < 5; i++) {
        level.push(i < masterclass_level_id ?
            <StopIcon className={classes.levelInd} /> :
            <StopOutlinedIcon className={classes.levelInd} />)
    }

    return (
        <Card className={classes.root} onClick={() => onClick(url)}>
            <CardActionArea className={classes.action_area}>
                <CardMedia
                    className={classes.media}
                    image={selectedImage}
                    title={"New master classes"}
                />
                <CardContent className={classes.cardContent}>
                    <div>
                        <Typography className={classes.artist} align='center'>
                            {artist}
                        </Typography>
                        <Typography className={classes.title} align='center'>
                            {title}
                        </Typography>
                    </div>
                    <div style={{ height: '100%' }}>
                        <div className={classes.bottom}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                {paid === 0 &&
                                    <Grid item>
                                        <div className={classes.freeButton}>
                                            {content.free}
                                        </div>
                                    </Grid>
                                }
                                <Grid item>
                                    <Typography className={classes.level}>
                                        {masterclass_level}
                                    </Typography>
                                    <div className={classes.levelBar}>
                                        {level}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea >
        </Card >
    )
}