import { Divider, Grid, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { makeStyles, useTheme } from "@material-ui/styles"
import React from "react"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    content: {
        padding: '20px 0px',
        boxSizing: 'border-box'
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    text: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: '0.05em',
        color: theme.palette.secondary.main,
    },
    textUnit:{
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: '0.05em',
        color: theme.palette.secondary.main,
    },
    label: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: '0.26em'
    }
}))

const Statistics = ({ content, data }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent='space-between' alignItems='stretch'>
                        {content.data.map(card => (
                            <Grid item xs={12} sm={2}>
                                <SimpleCard className={classes.card}>
                                    <div className={classes.content}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <SVGIcon
                                                            size={42}
                                                            src={card.icon}
                                                            color={theme.palette.secondary.main}
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.text} align='center'>
                                                            {data[card.id] ? data[card.id] : '120'}
                                                        </Typography>
                                                        {card.unit &&
                                                            <Typography className={classes.textUnit} align='center'>
                                                                {card.unit}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.label} align='center'>
                                                    {card.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </SimpleCard>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Statistics