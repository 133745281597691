import React from 'react'
import { FormControlLabel, Switch, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    label: {
        color: '#A0A0A0',
        fontWeight: 700,
        fontSize: 12
    },
    root: {
        width: 45,
        height: 26,
        padding: 0,
        margin: '18px 10px 10px 0px',
        overflow: 'initial'
    },
    switchBase: {
        padding: 1,
        color: '#999999',
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.secondary.main,
                opacity: 1,
                border: 'none',
            },
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: '#E7E7E7',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
        color: theme.palette.secondary.main
    }
}))

const SwitchForm = props => {

    const classes = styles()
    const { data, onChange, disabled, size } = props

    const onInnerChange = event => {
        const response = event.target.checked
        let temp = { ...data }
        temp.value = Number(response)
        temp.isVisited = true
        temp.isValid = true
        onChange(temp)
    }

    const { config, value, isVisited } = data
    const { id, label } = config

    return (
        <div className={classes.container}>
            <Typography className={classes.label}>
                {label}
            </Typography>
            <Switch
                name={id}
                checked={value}
                onChange={onInnerChange}
                color="primary"
                disabled={disabled}
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
            />
        </div>
    )
}

export default SwitchForm