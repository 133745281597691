import axios from "axios"
import { useRef, useState } from "react"
import { private_server } from "../../../../../config"
import { onGetCurrentUserProfile, onGetErrorMessage, onGetFileExtension } from "../../../../../shared/utility"

const usePictureModal = ({ onUpdateArtist }) => {

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    //const [mobile, setMobile] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef()


    const actions = {
        onResetComponent: () => {
            setFile(null)
            setSrc(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        },
        onDropFile: (data) => {
            setFile(data)
            if (data) {
                const reader = new FileReader();
                reader.addEventListener("load", () => setSrc(reader.result))
                reader.readAsDataURL(data)
            }
            setValid(true)
        },
        onImageLoaded: image => imageRef.current = image,
        handlerURL2Blob: async (url) => {
            let blob = await fetch(url).then(r => r.blob());
            let temp = blob.type.split('/');
            let ext = "." + temp[temp.length - 1];
            let newfile = new File([blob], "profile" + ext, { type: blob.type });
            return newfile;
        },
      //  onUpdateMobile: (event) => setMobile(event.target.checked),
        onSubmit: async () => {
            if (!valid) {
                setError('Please select a file')
                return
            }
            setLoading(true)
            try {
                const ext = {
                    name: 'banner_image',
                    ext: onGetFileExtension(file.name)
                }

                // Image upload handler
                if (file) {
                    let response = await private_server.post(`/artist/me/image`, ext)
                    const options = { headers: { 'Content-Type': file.type } }
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(response.data.data.url, file, options)
                    await onUpdateArtist()
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        }
    }

    return { loading, file, src, actions} //,mobile }
}

export default usePictureModal