import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import { onGetDateFormat } from '../../shared/utility';

const CssTextField = withStyles(theme => ({
  root: {
    whiteSpace: "pre",
    '& label': {
      width: '100%'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:before': {

    },
    '& .MuiInput-underline:after': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
    },
    '& .MuiInputBase-input': {
      //color: 'white',
    }
  },
}), { withTheme: true })(TextField);

const LineInputForm = props => {

  const { data, variant, color, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown } = props

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const { config, value, isValid, isRequired, isVisited } = data
  let _value = value
  const isError = (isVisited && !isValid) || (isVisited && isRequired && !isValid)
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''
  if (isRequired) interConfig.placeholder = `${interConfig.placeholder} *`
  if (interConfig.type === 'date') _value = value ? onGetDateFormat(value, 'YYYY-MM-DD') : null
  //if (interConfig.type === 'number') _value = value && value.length === 0 ? 0 : value

  return (
    <div>
      <CssTextField value={_value} variant={variant ? variant : 'standard'}
        error={isError} {...interConfig} onChange={onInnerChange}
        InputProps={
          {
            startAdornment: startAdornment ? startAdornment : undefined,
            endAdornment: endAdornment ? endAdornment : undefined,
            onKeyUp: onKeyUp ? onKeyUp : undefined,
            onKeyDown: onKeyDown ? onKeyDown : undefined,
          }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  )
}

export default LineInputForm