import React from 'react'
import { IconButton, makeStyles, MenuItem, Select } from '@material-ui/core'
import SVGIcon from '../Structure/DisplayData/SVGIcon'
import arrowIcon from '../../assets/icons/arrow_down.svg'
import StopIcon from '@material-ui/icons/Stop'

const styles = makeStyles(theme => ({
    menuItem: {
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    select_root: {
        padding: 5,
        paddingLeft: 10,
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        background: 'white',
        display:'inline-flex',
        alignItems:'center',
    },
    select_filled: {
        borderRadius: 2,
        boxShadow: '0px 4.21459px 11.5901px rgba(0, 0, 0, 0.11)',
    },
    select_icon: {
        top: 0,
        right: 0,
        padding: 10,
    }
}))

const FilterSelect = ({ value, menuFilter, onFilter, disabled }) => {

    const classes = styles()

    const handleChange = (event) => {
        onFilter(event.target.value)
    }

    return (
        <Select
            autoWidth
            disableUnderline
            variant="filled"
            value={value}
            disabled={disabled}
            onChange={handleChange}
            IconComponent={(props) => (
                <IconButton {...props}>
                    <SVGIcon src={arrowIcon} size={12} colorStroke='black' />
                </IconButton>
            )}
            classes={{
                root: classes.select_root,
                filled: classes.select_filled,
                icon: classes.select_icon
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }}
        >
            {menuFilter.map((item, key) => (
                <MenuItem
                    key={`menu-action-top-bar-${key.toString()}`}
                    value={item.value}
                    className={classes.menuItem}
                >
                    {item.label}
                    {item.color && <StopIcon style={{ width: 15, height: 15, color: item.color, padding: 5 }} />}
                </MenuItem>
            ))
            }
        </Select >
    )
}

export default FilterSelect