import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isFormValid, isValidDocument, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../shared/utility'
import queryString from 'query-string'
import { CONFIG_SETTINGS, private_server, public_server } from '../../../config'
import { generic_errors } from '../../../texts/eng/genericErrors'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useArtworksDetailsView = ({ is_auth, language, history, match, content }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [artworkID, setArtworkID] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [status, setStatus] = useState(0)
    const [artwork, setArtwork] = useState(null)
    const [artworkImages, setArtworkImages] = useState([])
    const [selectedArtworkImage, setSelectedArtworkImage] = useState(null)
    const [imageType, setImageType] = useState({ id: 'web_image', value: 1, label: 'web' })

    //MODALS
    const [artwork_image_modal, setArtworkImageModal] = useState(false)
    const [picture_modal, setPictureModal] = useState(false)
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    useEffect(() => {
        let _form = JSON.parse(JSON.stringify(formData))
        _form = onInitForm(_form, artwork, content.sections.general_info.form)
        setForm(_form)
    }, [artwork, edit_modal])

    const actions = {
        initModule: async () => {
            const artworkURL = match.params.url

            try {
                setLoading(true)
                let _artwork = await private_server.get(`artwork/url?url=${artworkURL}`)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })
                setStatus(_artwork.artwork_status_id)
                setArtworkID(_artwork.id_artwork)

                let _artworkImages = await private_server.get(`artworkimage/all/${_artwork.id_artwork}/me`)
                _artworkImages = _artworkImages.data.data.artwork_images

                let _gallery = []

                _artworkImages.map(artwork => {
                    const webImage = artwork ? artwork.web_image : null
                    const mobileImage = artwork ? artwork.mobile_image : null

                    let isValid = isValidDocument(webImage)
                    const selectedImageWeb = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${webImage}` : null
                    isValid = isValidDocument(mobileImage)
                    const selectedImageMob = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${mobileImage}` : null

                    _gallery.push({ id: artwork.id_artwork_image, web_image: selectedImageWeb, mobile_image: selectedImageMob })
                })

                setArtworkImages(_gallery)


            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onUpdateImageType: (step) => setImageType(step),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSetArtworkStatus: (value) => setStatus(value),
        onAddArtwork: () => {
            console.log('ADD C:')
        },
        onOpenPictureModal: () => setPictureModal(true),
        onClosePictureModal: () => setPictureModal(false),
        onOpenArtworkImageModal: (artwork) => {
            if (artwork && artwork.id)
                setSelectedArtworkImage(artwork)

            setArtworkImageModal(true)
        },
        onCloseArtworkImageModal: () => {
            setArtworkImageModal(false)
            setSelectedArtworkImage(null)
        },
        onOpenEditModal: () => setEditModal(true),
        onCloseEditModal: () => setEditModal(false),
        onDeleteArtwork: async () => {
            try {
                setSending(true)
                await private_server.delete(`artwork/${artworkID}/me`)
                history.push('/artworks')
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSaveArtwork: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)
                console.log(errors)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }

                const data2send = onGetSelectedFormData(form, artwork)

                data2send['artwork_status_id'] = status
                console.log(form, data2send)

                let _artwork = await private_server.patch(`artwork/${artworkID}/me`, data2send)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })

                setError(null)
                setEditModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onUpdateArtwork: async () => {
            try {
                setSending(true)


                let _artwork = await private_server.get(`artwork/${artworkID}/me`)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })

                let _form = JSON.parse(JSON.stringify(formData))

                Object.keys(content.sections).map(section => {
                    _form = onInitForm(_form, _artwork, content.sections[section].form)
                })

                setForm(_form)

                setError(null)
                setPictureModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onUpdateArtworkImages: async (artwork) => {
            try {
                let _prevGallery = [...artworkImages]
                let _artworkIndex = _prevGallery.findIndex((a => a.id === artwork.id))

                if (_artworkIndex !== -1)
                    _prevGallery[_artworkIndex] = { ...artwork }
                else if (_prevGallery.length < 12)
                    _prevGallery.push(artwork)

                //onSubmitArtwork
                setArtworkImages(_prevGallery)
                setArtworkImageModal(false)
                setSelectedArtworkImage(null)
                setSending(false)
            } catch (error) {
                console.log(error)
            }
        },
        onDeleteImage: async (artwork) => {
            try {
                setSending(true)
                let _gallery = [...artworkImages]
                const index = _gallery.indexOf(artwork)

                if (index > -1) {
                    await private_server.delete(`artworkimage/${artwork.id}/me`)
                    _gallery.splice(index, 1)
                }

                setArtworkImages(_gallery)
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
        },
    }

    const system = { loading, sending, success, error }
    const view_data = {
        form, status, artwork, artworkID, artworkImages, selectedArtworkImage,
        artwork_image_modal, picture_modal, add_modal, edit_modal, imageType
    }

    return { system, view_data, actions }
}

export default useArtworksDetailsView

const formData = {
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    title: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
        }
    },
    description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'none',
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    quantity: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    sold: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sold'
        }
    },
    new: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new'
        }
    },
    most_viewed: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'most_viewed'
        }
    },
    nft: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'nft'
        }
    },
    nft_sold: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'nft_sold'
        }
    },
    nft_price: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'nft_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 200
        }
    },
}