import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import GenericImage from '../../../components/Structure/DisplayData/GenericImage'
import SVGIcon from './SVGIcon'
import editIcon from '../../../assets/icons/edit.svg'

const styles = makeStyles(theme => ({
    imageContainer: {
        position: 'relative',
        background: grey[700],
        borderRadius: 5
    },
    editButton: {
        top: 0,
        right: 0,
        position: 'absolute',
        background: 'white',
        padding: 10,
        margin: 10,
        '&:hover': {
            background: grey[200]
        }
    },
}))

const ImageEdition = ({ src, onClick, ratio, fullpath }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.imageContainer}>
            <GenericImage src={src} borderRadius={5} ratio={ratio} fullpath={fullpath}/>
            <IconButton className={classes.editButton} onClick={onClick}>
                <SVGIcon
                    size={24}
                    src={editIcon}
                    colorStroke={theme.palette.primary.main}
                />
            </IconButton>
        </div>
    )
}

export default ImageEdition