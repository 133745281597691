import React, { useState } from 'react'
import { Divider, Grid, Icon, makeStyles, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import LanguageButton from '../../../../components/Actions/LanguageButton'
import moment from 'moment'
import { onGetDateFormat } from '../../../../shared/utility'
import PageContainer from '../../../../components/Structure/Layouts/PageContainer'
import MainContainer from '../../../../components/Structure/Layouts/MainContainer'
import { CONFIG_SETTINGS, nodata } from '../../../../config'

const styles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        filter: 'brightness(50%)',
        [theme.breakpoints.down('md')]: {
            objectPosition: 'left'
        },
        [theme.breakpoints.only('lg')]: {
            objectPosition: 'right'
        }
    },
    content: {
        width: '100%',
        height: '100vh',
        color: 'white',
        position: 'relative',
        transform: 'translateY(-100vh)'
    },
    center: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-45%)',
        /*[theme.breakpoints.between(0, 400)]: {
            transform: 'translateY(30%)',
        },
        [theme.breakpoints.between(400, 500)]: {
            transform: 'translateY(50%)',
        },
        [theme.breakpoints.between(500, 600)]: {
            transform: 'translateY(70%)',
        },
        [theme.breakpoints.between(600, 700)]: {
            transform: 'translateY(0%)',
        },
        [theme.breakpoints.between(700, 1140)]: {
            transform: 'translateY(10%)',
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            left: 0,
            boxSizing: 'border-box'
        }*/
    },
    topTitle: {
        fontSize: 24,
        fontWeight: 500,
        paddingBottom: 12,
        letterSpacing: '0.265em',
        [theme.breakpoints.only('xs')]: {
            fontSize: 24
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 20
        }
    },
    title: {
        fontSize: 36,
        fontWeight: 600,
        paddingBottom: 12,
        [theme.breakpoints.only('sm')]: {
            fontSize: 32
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 28
        }
    },
    date: {
        fontSize: 20,
        marginBottom: 20
    },
    description: {
        width: '80%',
        fontSize: 18,
        fontWeight: 500,
        [theme.breakpoints.only('sm')]: {
            fontSize: 20
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
            width: '100%',
        }
    },
    bottom: {
        transform: 'translateY(85vh)',
        margin: 32
    },
    logoContainer: {
        width: 120,
        height: 45,
        background: 'white',
        borderRadius: 9,
        paddingLeft: 10,
        paddingRight: 10,
        margin: 20
    },
    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))

const MainBanner = ({ exhibition, content }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const notFullDesktop = useMediaQuery(theme.breakpoints.down('md'))
    const isLG = useMediaQuery(theme.breakpoints.only('lg'))

    //console.log(content.date)

    const startDate = content.date.startDate
    const endDate = content.date.endDate



    //const banner = notFullDesktop ? content.bannerMobile : (isLG ? content.bannerMobile2 : content.image)

    let banner = null
    let description = nodata
    if (exhibition) {
        const path = ismobile ? exhibition.mobile_banner : exhibition.web_banner
        //console.log(path)
        banner = path ? `${CONFIG_SETTINGS.S3_SERVER_URL}${path}` : content.image
        description = ismobile ? exhibition.medium_description : exhibition.description
    }

    return (
        <div className={classes.root}>
            <img src={banner} className={classes.image} />
            <MainContainer>
                <div className={classes.content}>
                    <div className={classes.center}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography className={classes.topTitle}>{content.top_title}</Typography>
                                <Typography className={classes.title}>{exhibition ? exhibition.title : nodata}</Typography>
                                <Typography className={classes.date}>
                                    {exhibition ? exhibition.date_text : nodata}
                                    {/*startDate.format('DD') <span>{startDate.format('MMM')}</span> - {endDate.format('DD')} <span>{endDate.format('MMM')}</span>*/}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description} variant='subtitle1' align='justify'>
                                    {description && description.replace(/\r/gm, '').split("\n").map(text => (
                                        <p>{text}</p>
                                    ))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {/*<div className={classes.bottom}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <div className={classes.logoContainer}>
                                <img src={content.logos.cultura} className={classes.logo} />
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.logoContainer}>
                                <img src={content.logos.inba} className={classes.logo} />
                            </div>
                        </Grid>
                    </Grid>
    </div>*/}
                </div>
            </MainContainer>
        </div >
    )
}

export default MainBanner