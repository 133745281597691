import React from "react"
import { Checkbox, Divider, FormControlLabel, Grid, IconButton, Paper, Typography, useMediaQuery } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { makeStyles, useTheme } from "@material-ui/styles"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon";
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage";
import ImageEdition from "../../../../components/Structure/DisplayData/ImageEdition";

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        marginBottom: 80
    },
    topTitle: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    title: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    },
}))

const Images = ({ content, data, images, onEditPicture, onUpdateImage, onDeleteImage, onSetMain }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.topTitle}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {content.bannerImage}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ImageEdition src={data.banner_image} onClick={onEditPicture} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {content.mainImage}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ImageEdition src={data.professional_image} onClick={() => onUpdateImage({ main: 1 })} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {content.professionalImages}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems='stretch' spacing={4}>
                                {images.map(professionalImage => (
                                    <Grid item xs={12} sm={3}>
                                        <ImageCard professionalImage={professionalImage} onDelete={onDeleteImage} content={content} updateGallery={onUpdateImage} onSetMain={onSetMain} />
                                    </Grid>
                                ))}
                                {images.length < 12 &&
                                    <Grid item xs sm={3}>
                                        <ImageCard add updateGallery={onUpdateImage} content={content} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const imageCardStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 10
    },
    add: {
        width: '100%',
        paddingTop: '100%',
        background: '#E3E3E3',
        borderRadius: 5,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        position: 'relative',
        '&:hover': {
            background: 'rgb(0,0,0,0.2)'
        }
    },
    addButton: {
        top: 0,
        position: 'absolute',
        color: 'black',
        margin: 'auto',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        '&:hover': {
            background: 'transparent'
        }
    },
    addIcon: {
        width: 50,
        height: 50
    },
    topSpace: {
        height: '100%',
        paddingTop: 40,
        boxSizing: 'border-box'
    },
    deleteIcon: {
        background: 'white',
        boxShadow: '0px 4.44444px 5.55556px rgba(0, 0, 0, 0.17)',
        padding: 5
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: 400
    },
    imageContainer: {
        //paddingTop: '100%',
        position: 'relative'
    },
    image: {
        //position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    checkbox: {
        //position: 'absolute',
        //bottom: 0,
        //left: 10
    },
    checkboxLabel: {
        fontSize: 12,
        color: grey[500]
        //filter: 'contrast(0)'
    }
}))

const ImageCard = ({ professionalImage, content, add, updateGallery, onSetMain, onDelete }) => {

    const classes = imageCardStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    let selectedImage = professionalImage && professionalImage.image ? professionalImage.image : null
    selectedImage = selectedImage ? selectedImage : content.noImage

    const checkboxVal = professionalImage ? professionalImage.main : false

    const addButton = (
        <div className={classes.topSpace}>
            <Paper className={classes.add}>
                <IconButton className={classes.addButton} onClick={updateGallery} component="span">
                    <AddCircleIcon className={classes.addIcon} />
                </IconButton>
            </Paper>
        </div>
    )

    const contentView = (
        <Grid container justifyContent='flex-end' spacing={1}>
            <Grid item xs>
                <FormControlLabel
                    classes={{
                        root: classes.checkbox,
                        label: classes.checkboxLabel
                    }}
                    control={
                        <Checkbox checked={checkboxVal} onChange={(event) => onSetMain(professionalImage, event.target.checked)} />
                    }
                    label={content.main}
                />
            </Grid>
            <Grid item>
                <IconButton className={classes.deleteIcon} component="span" onClick={() => onDelete(professionalImage)}>
                    <SVGIcon src={content.deleteIcon} size={20} colorStroke={theme.palette.primary.main} />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <div className={classes.imageContainer}>
                        <GenericImage src={selectedImage} fullpath onClick={() => updateGallery(professionalImage)} />
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )

    return add ? addButton : contentView
}

export default Images