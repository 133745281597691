import React from 'react';
import { translations as translations_esp } from '../../../texts/eng/website/market-place-translations'
import { makeStyles } from '@material-ui/styles';
import Header from './sections/Header'
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import Newest from './sections/Newest';
import MostViewed from './sections/MostViewed/MostViewed'
import Catalog from './sections/Catalog'
import useMarketPlaceView from './useMarketPlaceView'
import PurchaseEnquiry from '../../../layouts/PurchaseEnquiryDrawer/PurchaseEnquiryDrawer';
import ArtworkModal from '../../../modals/ArtworkModal/ArtworkModal';
import { actionTypes } from '../../../store/actions';
import { connect } from 'react-redux';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        //minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const MarketPlaceView = ({ history, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus }) => {

    const classes = styles()
    const { loading, sending, success, error, actions, page,
        artwork, catalog, newest, most_viewed } =
        useMarketPlaceView({ history, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus })

    const content = translations_esp

    return (
        <LoadingContainer loading={loading} error={error} onCloseError={actions.onCloseError}>
            <div className={classes.content}>
                <Header history={history} content={content.main_info_section} />
                <Newest
                    artwork={newest}
                    history={history}
                    content={content.newest_section}
                    onOpenArtWork={actions.onOpenArtWork}
                    onBuy={actions.onBuy}
                    onChangePage={actions.onChangePage}
                    page={page}
                />
                {artwork.length > 0 &&
                    <React.Fragment>
                        <MostViewed
                            artwork={most_viewed}
                            history={history}
                            content={content.mostviewed_section}
                            onBuy={actions.onBuy}
                            onOpenArtWork={actions.onOpenArtWork}
                        />
                        <LoadingContainer sending={sending} success={success} onCloseSuccess={actions.onCloseSuccess} onCloseError={actions.onCloseError}>
                            <Catalog
                                artwork={catalog}
                                history={history}
                                onSelectFilter={actions.onSelectFilter}
                                content={content.catalog_section}
                                actions={actions}
                                onOpenArtWork={actions.onOpenArtWork}
                                onBuy={actions.onBuy}
                            />
                        </LoadingContainer>
                    </React.Fragment>
                }
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateArtworkData: (artwork) => dispatch({ type: actionTypes.DATA_UPDATE_ARTWORK, artwork }),
        onUpdateModalStatus: (artwork_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_ARTWORK, artwork_modal }),
        onUpdatePurchaseDrawerStatus: (purchase_enquiry_drawer) => dispatch({ type: actionTypes.DRAWER_PURCHASE_ENQUIRY, purchase_enquiry_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceView)