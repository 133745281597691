import { useState, useEffect, useRef } from "react"
import { TOKEN_STATUSES } from "../../config"
import { onClearMemory, onClearTokenSession, onGetCurrentUserProfile, onGetErrorMessage, onGetTokenFromMemory, onReAuthToken, onSetProMode, onValidateToken } from "../../shared/utility"

const useWebsiteLayout = ({ history, onUpdateUserData, onUpdateAuthStatus, onUpdateArrowPosition,
    onUpdateProMode, onUpdateLogInDrawer }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)

    const actions = {
        onInitModule: async () => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                
                let _user
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        await onUpdateUserData(_user)
                        await onUpdateAuthStatus(true)
                        break;
                    case TOKEN_STATUSES.VALID:
                        console.log('VALID')
                        _user = await onGetCurrentUserProfile()
                        await onUpdateUserData(_user)
                        await onUpdateAuthStatus(true)
                        break;
                    default:
                        console.log('NOT_VALID')
                        await onClearTokenSession()
                        await onUpdateUserData(null)
                        await onUpdateAuthStatus(false)
                        break;
                }
            }  catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onCheckTokenExpirationDate: async () => {
            if (busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        break;
                    case TOKEN_STATUSES.NOT_VALID:
                        await onClearTokenSession()
                        await onUpdateUserData(null)
                        await onUpdateAuthStatus(false)
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }
            } catch (error) {
                console.log(error)
            }
        },
        onUpdateNavArrow: (inView, entry) => {
            onUpdateArrowPosition(inView)
        },
        onItemSelected: async (url) => {
            try {
                setLoading(true)
                switch (url) {
                    case '/login':
                        await onUpdateLogInDrawer(true)
                        break;
                    case '/logout':
                        await onUpdateAuthStatus(false)
                        await onUpdateUserData(null)
                        await onUpdateProMode(false)
                        await onSetProMode(false)
                        await onClearMemory()
                        history.push('/')
                        break;
                    case '/kuvik-pro':
                        await onUpdateProMode(true)
                        await onSetProMode(true)
                        history.push('/pro')
                        break;
                    default:
                        history.push(url)
                        break;
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
    }

    return { loading, sending, success, error, actions }
}

export default useWebsiteLayout;

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
