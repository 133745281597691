import { useState, useEffect, useRef } from "react"
import { TOKEN_STATUSES } from "../../config"
import { onClearMemory, onClearTokenSession, onGetCurrentArtistProfile, onGetCurrentUserProfile, onGetErrorMessage, onGetProModeFromMemory, onGetTokenFromMemory, onReAuthToken, onSetProMode, onValidateToken } from "../../shared/utility"

const useKuvikProLayout = ({ is_auth, pro_mode, history, onUpdateUserData, onUpdateAuthStatus,
    onUpdateArtistRegisterDrawer, onUpdateChangePasswordModal, onUpdateProMode, onUpdateWelcomeDrawer }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)

    const [change_password_modal, setChangePasswordModal] = useState(false)

    useEffect(() => {
        //console.log('AUTH', is_auth, pro_mode)
        //if (is_auth && pro_mode)
            actions.onInitModule()
    }, [is_auth, pro_mode])

    useInterval(() => {
        actions.onCheckTokenExpirationDate()
    }, 3000)

    const actions = {
        onInitModule: async () => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)

                let _user
                let _artist
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        history.push('/')
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        await onUpdateUserData(_user)
                        await onUpdateAuthStatus(true)
                        break;
                    case TOKEN_STATUSES.VALID:
                        console.log('VALID')
                        _user = await onGetCurrentUserProfile()
                        if(_user.user_type_id === 3){
                            _artist = await onGetCurrentArtistProfile()
                            onUpdateWelcomeDrawer(_artist.artist_status_id === 1 || _artist.artist_status_id === 5)
                        }
                        await onUpdateUserData(_user)
                        await onUpdateAuthStatus(true)
                        console.log(_user)
                        break;
                    default:
                        console.log('NOT_VALID')
                        await onClearTokenSession()
                        await onUpdateUserData(null)
                        await onUpdateAuthStatus(false)
                        history.push('/')
                        break;
                }
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onCheckTokenExpirationDate: async () => {
            if (busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        break;
                    case TOKEN_STATUSES.NOT_VALID:
                        console.log('NOT VALID')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        history.push('/')
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        setBusy(true)
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        await onUpdateAuthStatus(true)
                        await onReAuthToken()
                        setBusy(false)
                        break;
                    default:
                        //Nothing to do
                        break;
                }
            } catch (error) {
                console.log(error)
            }
        },
        onItemSelected: async (url) => {
            try {
                setSending(true)
                switch (url) {
                    case '/logout':
                        await onUpdateAuthStatus(false)
                        await onUpdateUserData(null)
                        await onUpdateProMode(false)
                        await onSetProMode(false)
                        await onClearMemory()
                        history.push('/')
                        break;
                    case '/change-password':
                        await onUpdateChangePasswordModal(true)
                        break;
                    case '/kuvik-website':
                        await onSetProMode(false)
                        await onUpdateProMode(false)
                        history.push('/')
                        break;
                    default:
                        history.push(url)
                        break;
                }
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onShowApplicationForm: async () => onUpdateArtistRegisterDrawer(true)
    }

    return { loading, sending, success, error, change_password_modal, actions }
}

export default useKuvikProLayout;

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
