import React, { useState } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onClearMemory, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";

const useWelcomeArtistDrawer = ({ history, language, onUpdateDrawerStatus, 
    onUpdateArtistRegisterDrawer }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const actions = {
        onCloseError: () => setError(null),
        onClose: async () => {
            await onUpdateDrawerStatus(false)
        },
        onOpenArtistRegister: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateArtistRegisterDrawer(true)
        }
    }

    return {
        loading, error, actions
    }
}

export default useWelcomeArtistDrawer
