import React from "react"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import InputFormW from "../../components/Forms/InputFormW";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RoundedButton from "../../components/Actions/RoundedButton";
import SVGIcon from "../../components/Structure/DisplayData/SVGIcon";
import general_translations from '../../texts/eng/website/general-translations'
import { actionTypes } from "../../store/actions";
import { connect } from "react-redux";
import useForgotPasswordDrawer from "./useForgotPasswordDrawer";
import LoadingContainer from "../../components/Structure/Layouts/LoadingContainer";

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
    },
    paper: {
        background: 'rgba(0,0,0,0.8)'
    },
    content: {
        color: 'white',
        width: '50vh',
        height: 'auto',
        margin: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            padding: 32,
            boxSizing: 'border-box'
        }
    },
    appBar: {
        position: 'relative',
        background: 'transparent',
        padding: 32,
        boxSizing: 'border-box',
        boxShadow: 'none'
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 300
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    iconPassword: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    footer: {
        color: 'white',
        marginBottom: 'auto',
        borderTop: '1px solid #5A5A5A',
        padding: 24
    },
    footerTitle: {
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: '0.25em'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ForgotPasswordDrawer = ({ open, language, onUpdateDrawerStatus,
    onUpdateDrawerSignUp, onUpdateDrawerLogIn }) => {

    const classes = styles()
    const content = general_translations.forgot_password_drawer

    const { loading, sending, success, error, form, actions } = useForgotPasswordDrawer({
        open, language, content, onUpdateDrawerStatus, onUpdateDrawerSignUp, 
        onUpdateDrawerLogIn
    })

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={actions.onCloseSuccess} onCloseError={actions.onCloseError}>
            <div className={classes.root}>
                <Dialog fullScreen classes={{ paperFullScreen: classes.paper }} open={open} onClose={actions.onClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    <img src={content.logo} style={{ marginLeft: 10, width: 45 }} />
                                </Grid>
                                <Grid item>
                                    <IconButton edge="end" color="inherit" onClick={actions.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center' spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' align='center' className={classes.title}>
                                            {content.title[0]} <span>{content.title[1]}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' align='center' className={classes.subtitle}>
                                            {content.subtitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputFormW data={form.email} onChange={actions.onChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RoundedButton fullWidth onClick={actions.onSubmit} color='white'>
                                            {content.button}
                                        </RoundedButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div onClick={actions.onOpenLogIn}>
                                            <Typography variant='body2' align='center' className={classes.link}>
                                                {content.go_login}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' align='center'>
                                    {content.no_account} <span className={classes.link} onClick={actions.onOpenSignUp}>{content.sign_up}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.footer}>
                        <Grid container justifyContent='center' spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='body2' align='center' className={classes.footerTitle}>
                                    {content.social_media.title}
                                </Typography>
                            </Grid>
                            {content.social_media.media.map(media => (
                                <Grid item key={`login-media.${media.id}`}>
                                    <IconButton onClick={() => window.open(media.url, media.target)}>
                                        <SVGIcon src={media.icon} color='white' />
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Dialog>
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        open: state.forgot_password_drawer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (forgot_password_drawer) => dispatch({ type: actionTypes.DRAWER_FORGOT_PASSWORD, forgot_password_drawer }),
        onUpdateDrawerLogIn: (login_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_LOGIN, login_drawer }),
        onUpdateDrawerSignUp: (signup_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_SIGNUP, signup_drawer })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordDrawer)