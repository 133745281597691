import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import GenericImage from '../../../../components/Structure/DisplayData/GenericImage';
import cx from 'classnames'
import { grey } from '@material-ui/core/colors';
import { catalogs } from '../../../../texts/eng/catalogs';

const MAX_ITEMS = 1;
const MAX_WIDTH = 448

const CustomCarouselMobile = ({ data, onItemSelected, reference }) => {

    const classes = useStyles()
    const sliderRef = useRef()
    const [width, setWidth] = useState(MAX_WIDTH)
    const [slider_config, setSliderConfig] = useState({
        posx: 0, width: 100
    })
    const [dots, setDots] = useState([])

    useEffect(() => {
        if (data && data.length) {
            let _dots = []
            const ndots = Math.ceil(data.length / MAX_ITEMS);
            setSliderConfig({ ...slider_config, width: `${data.length * MAX_WIDTH}` })
            for (let i = 0; i < ndots; i++) _dots.push(i);
            setDots(_dots)
            if (reference && reference.current) {
                const _width = Math.ceil(reference.current.offsetWidth / MAX_ITEMS)
                if (_width < MAX_WIDTH) {
                    setSliderConfig({ ...slider_config, width: `${data.length * _width}` })
                    setWidth(_width)
                }
            }
        }
    }, [data, reference, window.screen.width])

    useInterval(() => {
        // Your custom logic here
        let temp = slider_config.posx + 1
        if (temp > dots.length - 1) temp = 0
        setSliderConfig({ ...slider_config, posx: temp });
    }, 8000);



    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.style.transform = `translate(-${width * MAX_ITEMS * slider_config.posx}px)`
        }
    }, [sliderRef, slider_config, data])

    console.log(slider_config)

    return (
        <div>
            <Grid container wrap='nowrap' spacing={2}>
                {data.map(item => (
                    <Grid item>
                        <div className={classes.artist_card} key={item.id_user.toString()} onClick={() => onItemSelected(item.url)}>
                            <GenericImage src={item.professional_image} ratio='3/4' />
                            <div className={classes.text_container}>
                                <Typography style={{ fontSize: 20, letterSpacing: 4 }}
                                >{item.discipline_subject ? item.discipline_subject : 'No subject'}</Typography>
                                <Typography style={{ fontSize: 32 }}>{item.professional_name}</Typography>

                                {/*<Typography style={{textTransform:'uppercase'}}>{item.id_user}</Typography>*/}
                            </div>
                            <div className={classes.country}>
                                <Typography style={{ textTransform: 'uppercase' }}>{item.country_abbreviation}</Typography>
                            </div>
                        </div>
                    </Grid>
                    
                ))}

            </Grid>
                    
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {

    },
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        position: 'relative',
        padding: '32px 8px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            //border:'1px solid black',
            padding: '16px 8px',
            overflowX: 'auto'
        }
    },
    slider: {
        display: 'flex',
        height: '100%',
        flexShrink: 0,
        transition: 'all 0.5s'
    },
    artist_card: {
        background: 'black',
        width: 250,
        height: 333,
        //margin: '0px 24px',
        borderRadius: 10,
        position: 'relative',
        cursor: 'pointer'
    },
    text_container: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: 'white',
        width: '100%',
        boxSizing: 'border-box',
        padding: 20
    },
    country: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        color: 'white',
        border: '1px solid white',
        padding: '4px 8px',
        borderRadius: 8
    },
    dot: {
        width: 50,
        height: 4,
        background: grey[300],
        cursor: 'pointer'
    },
    dot_active: {
        background: theme.palette.secondary.main
    }
}))

export default CustomCarouselMobile;

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}