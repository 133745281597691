import React, { useState, useRef, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import SimpleModal from '../../components/Modals/SimpleModal'
import system_translations from '../../texts/system_translations'
import RoundedButton from '../../components/Actions/RoundedButton'
import useProfilePictureModal from './useProfilePictureModal'
import { actionTypes } from '../../store/actions'
import { connect } from 'react-redux'

const ProfilePictureModal = props => {

    const classes = useStyles()
    const { open, language, onUpdateModalStatus, onUpdateUserData, onUpdateProfilePicture, user } = props

    const { loading, src, crop, actions } = useProfilePictureModal({ user, onUpdateModalStatus, onUpdateUserData, onUpdateProfilePicture })

    const content = system_translations[language].modals.profile_picture

    useEffect(() => {
        if (open) actions.onResetComponent()
    }, [open])

    return (
        <SimpleModal open={open} onClose={() => onUpdateModalStatus(false)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title} A</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={actions.onDropFile} placeholder={content.placeholder} />
                </Grid>
                {src ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' >{content.image_selected}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.react_drop}>
                                    <ReactCrop
                                        style={{
                                            height: 'auto',
                                            width: 'fit-content',
                                            margin: 'auto',
                                            display: 'block'
                                        }}
                                        imageStyle={{
                                            width: 'auto',
                                            height: 300,
                                            objectFit: 'contain',
                                            margin: 'auto'
                                        }}
                                        src={src} crop={crop}
                                        onImageLoaded={actions.onImageLoaded}
                                        onChange={actions.handleOnCropChange}
                                        onComplete={actions.handleOnCropComplete}
                                    />
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <RoundedButton onClick={actions.onSubmit} loading={loading}>{content.save_button}</RoundedButton>
                        </Grid>
                    </Grid>
                    {/*<ActionModalBar error={error} loading={loading} btnLabel='Guardar' onDelete={onDelete} onSubmit={onSubmit}/>*/}
                </Grid>

            </Grid>
        </SimpleModal>
    )
}

const useStyles = makeStyles(theme => ({
    react_drop: {
        width: '100%',
        height: 300,
        //overflowY: 'hidden'
    },
    react_drop_image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    react_drop_container: {

    }
}))

const mapStateToProps = state => {
    return {
        user: state.user,
        language: state.language,
        open: state.profile_picture_modal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateProfilePicture: (profile_picture) => dispatch({ type: actionTypes.SYS_UPDATE_PROFILE_PICTURE, profile_picture }),
        onUpdateModalStatus: (profile_picture_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_PROFILE_PICTURE, profile_picture_modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureModal)