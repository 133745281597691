import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../../components/Structure/DisplayData/FilledDisplayData'
import FilterSelect from '../../../../components/Forms/FilterSelect'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import { catalogs } from '../../../../texts/eng/catalogs'
import ImageEdition from '../../../../components/Structure/DisplayData/ImageEdition'

const styles = makeStyles(theme => ({
    root: {

    },
    topBar: {
        [theme.breakpoints.up('md')]: {
            marginTop: -50
        }
    },
    content: {
        marginTop: 30
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    country_code: {
        fontSize: '0.875rem',
		color: '#151515',
		marginRight: 5,
		fontWeight: 700,
		fontFamily: 'Montserrat',
    },
    topData: {
        top: 0,
        right: 0,
        position: 'absolute',
        display: 'inline-flex'
    },
    referenceCode: {
        marginTop: 10,
        marginRight: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#A6A6A6',
        '& span': {
            fontSize: 14,
            color: theme.palette.secondary.main
        }
    },
    imageContent: {
        marginBottom: 20
    },
    sideImage: {
        height: '100%',
        display: 'grid',
        alignItems: 'stretch'
    }
}))

const GeneralInfo = ({ user, status, data, form, content, onEditPicture, onOpenEditModal, onSetArtistStatus, submitButton }) => {

    const classes = styles()
    const theme = useTheme()
    const isMD = useMediaQuery(theme.breakpoints.only('xs'))

    let image = user && user.image ? user.image : null

    const country_code = (
        <div className={classes.country_code}>
            ({data.country_code})
        </div>
    )
    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                    <Grid item>
                        <FilterSelect
                            value={status}
                            menuFilter={catalogs.artist_statuses}
                            onFilter={onSetArtistStatus}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            color='black'
                            size='small'
                            style={submitButton}
                            onClick={onOpenEditModal}
                        >
                            {content.edit_button}
                        </RoundedButton>
                    </Grid>
                    {/* <Grid item>
                            <IconButton
                                className={classes.deleteButton}
                                onClick={actions.onDeleteArtist}
                            >
                                <SVGIcon src={content.delete} size={22} color='white' />
                            </IconButton>
                       </Grid>*/}
                </Grid>
            </div>
            <div className={classes.content}>
                <Grid container alignItems='stretch' spacing={4}>
                    <Grid item xs={12} md={4} lg={3}>
                        <div className={classes.sideImage}>
                            <div className={classes.imageContent}>
                                <ImageEdition src={image} onClick={() => onEditPicture('image')} />
                            </div>
                            <SimpleCard fullHeight>
                                <FilledDisplayData data={form.referenced_by} value={data.referer} />
                            </SimpleCard>
                        </div>
                        {/*<Grid item xs={12}>
                                <SimpleCard padding='small'>
                                    <FilledDisplayData data={form.approved_by} value={data.approver} />
                                </SimpleCard>
                    </Grid>*/}
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <SimpleCard fullHeight>
                            <div style={{ position: 'relative' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.first_name} value={data.first_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.last_name} value={data.last_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.professional_name} value={data.professional_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.gender_id} value={data.gender} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.country_id} value={data.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.discipline_id} value={data.discipline} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.birth_date} value={data.birth_date} format={'DD/MMMM/YYYY'} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.mobile} value={data.mobile} startAdornment={country_code} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.email} value={data.email} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.url} value={data.url} />
                                    </Grid>
                                </Grid>
                                <div className={classes.topData}>
                                    {/*data.reference_code &&
                                        <Typography className={classes.referenceCode}>
                                            {content.referenceCode} - <span>{data.reference_code}</span>
                                        </Typography>
                    */}
                                    {data.featured > 0 &&
                                        <SVGIcon src={content.featuredIcon} color={theme.palette.secondary.main} size={42} />
                                    }
                                </div>
                            </div>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {content.smallCards.map(card => (
                                <Grid item xs={4}>
                                    <SmallCard value={data[card.id]} {...card} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid >
            </div>
        </div >
    )
}

const SmallCard = ({ icon, label, value, secondaryColor, primaryColor }) => {

    const _primaryColor = primaryColor ? primaryColor : green[500]
    const _secondaryColor = secondaryColor ? secondaryColor : red[500]
    const styleElement = { fontSize: 15, color: value ? _primaryColor : _secondaryColor }

    return (
        <SimpleCard padding='small'>
            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    {React.cloneElement(icon, { style: styleElement })}
                </Grid>
                <Grid item>
                    <Typography style={{ color: '#636363', fontSize: 16, fontWeight: 600 }}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}
export default GeneralInfo