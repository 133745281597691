import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user: null,
    profile_picture: null,
    is_auth: false,
    token: null,
    language: 'en',
    cart: null,
    ip_address: null,
    drawer_status: true,
    blur: false,
    arrow_translate: false,
    pro_mode: false,
    artwork: null,
    /////////// Modals //////////////
    profile_picture_modal: false,
    artwork_modal: false,
    change_password_modal: false,
    /////////// Drawers /////////////
    login_drawer: false,
    signup_drawer: false,
    register: false,
    confirmation_drawer: false,
    welcome_artist_drawer: false,
    artist_register_drawer: false,
    forgot_password_drawer: false,
    new_password_drawer: false,
    contact_drawer: false,
    sidebar_drawer: true,
    purchase_enquiry_drawer: false,
    ////////// REFERENCES //////////
    magazine_reference: false,
    // Error Handler
    error: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        ///////////////////// PAGE ///////////////////////////////
        case actionTypes.ARROW_TRANSLATE:
            return updateObject(state, { arrow_translate: action.arrow_translate })
        ///////////////////// SYS  ///////////////////////////////
        case actionTypes.SYS_PRO_MODE:
            return updateObject(state, { pro_mode: action.pro_mode })
        case actionTypes.SYS_UPDATE_LANGUAGE:
            return updateObject(state, { language: action.language })
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, { user: action.user })
        case actionTypes.AUTH_UPDATE_STATUS:
            return updateObject(state, { is_auth: action.is_auth })
        case actionTypes.SYS_UPDATE_PROFILE_PICTURE:
            return updateObject(state, { profile_picture: action.profile_picture })
        case actionTypes.SYS_UPDATE_IP_ADDRESS:
            return updateObject(state, { ip_address: action.ip_address })
        case actionTypes.SYS_UPDATE_BLUR:
            return updateObject(state, { blur: action.blur })
        case actionTypes.SYS_UPDATE_TOKEN:
            return updateObject(state, { token: action.token })
        case actionTypes.SYS_UPDATE_SESSION_STATUS:
            return updateObject(state, { session_status: action.session_status })
        //////////////////////   DATA     //////////////////////
        case actionTypes.DATA_UPDATE_ARTWORK:
            return updateObject(state, { artwork: action.artwork })
        //////////////////////   DRAWERS  //////////////////////
        case actionTypes.DRAWER_UPDATE_LOGIN:
            return updateObject(state, { login_drawer: action.login_drawer })
        case actionTypes.DRAWER_UPDATE_SIGNUP:
            return updateObject(state, { signup_drawer: action.signup_drawer })
        case actionTypes.DRAWER_CONFIRMATION:
            return updateObject(state, { confirmation_drawer: action.confirmation_drawer })
        case actionTypes.DRAWER_WELCOME_ARTIST:
            return updateObject(state, { welcome_artist_drawer: action.welcome_artist_drawer })
        case actionTypes.DRAWER_ARTIST_REGISTER:
            return updateObject(state, { artist_register_drawer: action.artist_register_drawer })
        case actionTypes.DRAWER_FORGOT_PASSWORD:
            return updateObject(state, { forgot_password_drawer: action.forgot_password_drawer })
        case actionTypes.DRAWER_NEW_PASSWORD:
            return updateObject(state, { new_password_drawer: action.new_password_drawer })
        case actionTypes.DRAWER_CONTACT:
            return updateObject(state, { contact_drawer: action.contact_drawer })
        case actionTypes.DRAWER_SIDEBAR:
            return updateObject(state, { sidebar_drawer: action.sidebar_drawer })
        case actionTypes.DRAWER_PURCHASE_ENQUIRY:
            return updateObject(state, { purchase_enquiry_drawer: action.purchase_enquiry_drawer })
        //////////////////////   REFERENCES  ///////////////////
        case actionTypes.REFERENCE_MAGAZINE:
            return updateObject(state, { magazine_reference: action.magazine_reference })
        //////////////////////   MODALS   //////////////////////
        case actionTypes.MODAL_UPDATE_PROFILE_PICTURE:
            return updateObject(state, { profile_picture_modal: action.profile_picture_modal })
        case actionTypes.MODAL_UPDATE_ARTWORK:
            return updateObject(state, { artwork_modal: action.artwork_modal })
        case actionTypes.MODAL_CHANGE_PASSWORD:
            return updateObject(state, { change_password_modal: action.change_password_modal })
        case actionTypes.SYS_UPDATE_MODAL:
            return updateObject(state, { modals: { ...state.modals, ...action.modal } })
        //////////////////////   ERROR HANDLER   //////////////////////
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, { error: action.error })
        default:
            return state;

    }
}

export const store = createStore(reducer)

