import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { onGetDateFormat } from "../../../shared/utility"
import AvatarPicture from './AvatarPicture'
import nouser from '../../../assets/nouser.png'

const styles = makeStyles(theme => ({
  label: {
    color: '#A0A0A0',
    fontSize: '0.875rem',
    fontWeight: 700
  },
  content: {
    background: 'rgba(189, 139, 64, 0.04)',
    padding: 10,
    borderRadius: 4,
    '& span': {
      fontSize: 16,
      color: '#9D9D9D'
    }
  },
  text: {
    whiteSpace: "pre-line",
    fontSize: '1rem',
    color: grey[800],
  },
  textArea: {
    overflowY: 'auto',
  }
}))

const FilledDisplayData = props => {

  const classes = styles()
  const { data, value, startAdornment, endAdornment, format } = props

  const { config } = data
  const { id, label, image, multiline, rows } = config

  let _value = value ? value : '-'

  if (format)
    _value = value ? onGetDateFormat(value, format) : '-'

  let textHeight = {}
  if (multiline)
    textHeight = { height: 20 * rows }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.label}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.content}>
            <Grid container>
              {startAdornment &&
                <Grid item>
                  {startAdornment}
                </Grid>
              }
              {image &&
                <AvatarPicture size={125} src={image} nopicture={nouser} />
              }
              <Grid item xs>
                <div className={classes.textArea} style={textHeight}>
                  <Typography className={classes.text}>
                    {_value}
                  </Typography>
                </div>
              </Grid>
              {endAdornment &&
                <Grid item>
                  {endAdornment}
                </Grid>
              }
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )

}

export default FilledDisplayData