import React, { useState, useEffect } from 'react';
import { onGetErrorMessage, onValidateURL } from '../../../shared/utility';
import queryString from 'query-string'
import { public_server } from '../../../config';

const useMarketPlaceView = ({ history, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [artwork, setArtwork] = useState([])
    const [catalog, setCatalog] = useState([])
    const [newest, setNewest] = useState([])
    const [most_viewed, setMostViewed] = useState([])

    const [page, setCurrentPage] = useState(1)

    useEffect(() => {
        actions.initModule()
    }, [])


    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            try {
                setLoading(true)
                let filter = { offset: 0, order_by: 'id_artwork', order: 0 }
                let filterArgs = {
                    AND: [
                        { field: "new", operator: "=", value: 1 },
                        { field: "artwork_status_id", operator: "=", value: 4 },
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                let params = queryString.stringify(filter)
                let _newest = await public_server.get(`/artwork/all/ns?${params}&filter=${filterArgs}`)
                _newest = _newest.data.data.artworks
                const result = new Array(Math.ceil(_newest.length / 6))
                    .fill()
                    .map(_ => _newest.splice(0, 6))

                setNewest([...result])

                //MOST VIEWED
                filterArgs = {
                    "AND": [
                        { "field": "most_viewed", "operator": "=", "value": 1 },
                        { "field": "artwork_status_id", "operator": "=", "value": 4 }
                    ]
                }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const _mostviewed = await public_server.get(`/artwork/all/ns?${params}&filter=${filterArgs}`)
                setMostViewed([..._mostviewed.data.data.artworks])

                //ARTWORK
                let params2send = { offset: 0, order: 1, order_by: 'order' }
                params = queryString.stringify(params2send)
                const _artwork = await public_server.get(`/artwork/all/ns?${params}`)
                setArtwork([..._artwork.data.data.artworks])

                //CATALOG
                params2send = { offset: 0, order: 0, order_by: 'id_artwork' }
                params = queryString.stringify(params2send)
                filterArgs = { "AND": [{ "field": "artwork_status_id", "operator": "=", "value": 4 }] }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const _catalog = await public_server.get(`/artwork/all/ns?${params}&filter=${filterArgs}`)
                setCatalog([..._catalog.data.data.artworks])

                
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onSelectFilter: async (filter, nft, color) => {
            try {
                setSending(true)
                let params, params2send = null

                switch (filter) {
                    case 1: //ALL
                        params2send = { offset: 0, order: 0, order_by: 'id_artwork' }
                        break;
                }

                let filterArgs = [
                    { "field": "nft", "operator": nft ? ">" : "=", "value": 0 }
                    //{ "field": "color", "operator": "=", "value": color }
                ]
                filterArgs = { "AND": filterArgs }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

                const _artwork = await public_server.get(`/artwork/all/ns${params}`)
                setCatalog([..._artwork.data.data.artworks])
                setSending(false)
            } catch (error) {
                console.log(error)
                onGetErrorMessage(error)
            }
            setSending(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onChangePage: (event, page) => setCurrentPage(page),
        onOpenArtWork: async (data) => {
            console.log(data)
            await onUpdateArtworkData(data)
            await onUpdateModalStatus(true)
        },
        onBuy: async (data) => {
            await onUpdateArtworkData(data)
            await onUpdateModalStatus(false)
            await onUpdatePurchaseDrawerStatus(true)
        }
    }

    return {
        loading, sending, success, error, actions, page,
        artwork, catalog, newest, most_viewed,
    }
}

export default useMarketPlaceView