import noImage from '../../../assets/artist/noImage.png'
import nftIcon from '../../../assets/icons/NFT.svg'
import likeIcon from '../../../assets/icons/full_heart.svg'
import trashIcon from '../../../assets/icons/trash.svg'
import editIcon from '../../../assets/icons/edit.svg'

import viewsIcon from '../../../assets/icons/views.svg'
import exhibitionsIcon from '../../../assets/icons/exhibitions_filled.svg'
import timeIcon from '../../../assets/icons/wall-clock-white-clockhands.svg'
import enquiresIcon from '../../../assets/icons/file_filled.svg'

export const translations = {
    title: 'Artwork',
    detail: 'Detail',
    add_button: 'Add',
    edit_button: 'Edit',
    save_button: 'Save',
    nft: nftIcon,
    like: likeIcon,
    delete: trashIcon,
    sections: {
        general_info: {
            editIcon: editIcon,
            image_options: [
                { id: 'web_image', value: 1, label: 'web' },
                { id: 'mobile_image', value: 2, label: 'mobile' },
            ],
            form: {
                order: {
                    label: 'Order',
                    placeholder: 'Order'
                },
                quantity: {
                    label: 'Quantity',
                    placeholder: 'Quantity'
                },
                title: {
                    label: 'Title',
                    placeholder: 'Title',
                },
                dimensions: {
                    label: 'Dimensions',
                    placeholder: 'Dimensions',
                },
                technique: {
                    label: 'Technique',
                    placeholder: 'Technique',
                },
                price: {
                    label: 'Price',
                    placeholder: 'Price',
                },
                description: {
                    label: 'Description',
                    placeholder: 'Description',
                },
                date: {
                    label: 'Date',
                    //placeholder: 'Date',
                },
                quantity: {
                    label: 'Quantity',
                    placeholder: 'Quantity',
                },
                sold: {
                    label: 'Sold'
                },
                new: {
                    label: 'New'
                },
                most_viewed: {
                    label: 'Most viewed'
                },
                nft: {
                    label: 'NFT'
                },
                nft_sold: {
                    label: 'NFT Sold'
                },
                nft_price: {
                    label: 'NFT Price',
                    placeholder: 'NFT Price'
                },
                external_url: {
                    label: 'NFT Link',
                    placeholder: 'NFT Link',
                },
            }
        },
        additional_images: {
            title: 'ADDITIONAL IMAGES',
            deleteIcon: trashIcon
        },
        statistics: {
            title: 'STATISTICS',
            data: [
                {
                    id: 'likes',
                    label: 'Likes',
                    icon: likeIcon
                },
                {
                    id: 'views',
                    label: 'Views',
                    icon: viewsIcon
                },
                {
                    id: 'purchase_requested',
                    label: 'Exhibitions',
                    icon: exhibitionsIcon
                },
                {
                    id: 'time',
                    label: 'Time',
                    icon: timeIcon,
                    unit: 'mins'
                },
                {
                    id: 'enquires',
                    label: 'Enquires',
                    icon: enquiresIcon
                }
            ]
        }
    },
    modals: {
        picture: {
            title: 'Update Picture',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view'
        },
        artwork_image_modal: {
            title: 'Update Additional Image',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view',
        },
    }
}