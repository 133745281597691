import noImage from '../../../assets/noimage.png'
import nftIcon from '../../../assets/icons/NFT.svg'
import likeIcon from '../../../assets/icons/full_heart.svg'

import noArtworks from '../../../assets/noimage.png'

export const translations = {
    title: 'Artworks',
    noArtworks: {
        title: 'No artworks to show',
        subtitle: 'Artworks will show here.',
        image: noArtworks
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
        },
        dimensions: {
            label: 'Dimensions',
            placeholder: 'Dimensions',
        },
        technique: {
            label: 'Technique',
            placeholder: 'Technique',
        },
        price: {
            label: 'Price',
            placeholder: 'Price',
        },
        description: {
            label: 'Description',
            placeholder: 'Description',
        },
        date: {
            label: 'Date',
            //placeholder: 'Date',
        },
        sold: {
            label: 'Sold'
        },
        nft: {
            label: 'NFT'
        },
        external_url: {
            label: 'NFT Link',
            placeholder: 'NFT Link',
        },
    },
    add_button: 'Add',
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
    sold: 'Sold',
    data: [
        /* {
             id: 'id_artwork',
             label: 'ID'
         },
         {
             id: 'artist',
             label: 'Artist'
         },*/
        {
            id: 'title',
            label: 'Title'
        },
    ],
    price: 'Price',
    nft: nftIcon,
    like: likeIcon
}