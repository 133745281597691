import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { grey } from '@material-ui/core/colors'
import StopIcon from '@material-ui/icons/Stop'
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {
        //paddingTop: 100,
        //paddingBottom: 100,
        padding: 80,
        margin: 'auto',
        boxSizing: 'border-box',
        marginBottom: 100,
        [theme.breakpoints.up('xl')]: {
            width: 1600
        },
        // [theme.breakpoints.down('lg')]: {
        //   width: 1200,
        //margin:'auto'
        //},
        //[theme.breakpoints.down('md')]: {
        //    padding: 100,
        //    width: '100%'
        //},
        [theme.breakpoints.only('xs')]: {
            padding: 32
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        textTransform: 'capitalize',
        color: grey[800],
        letterSpacing: '0.065em',
    },
    titleD: {
        color: grey[600],
    },
    dot: {
        width: 10,
        height: 10,
        color: theme.palette.secondary.main
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 700,
        color: grey[800],
        textTransform: 'uppercase',
        lineHeight: 0
    }
}))

const TitleContainer = props => {

    const classes = styles()
    const { title, detail, style } = props

    return (
        <div className={classNames(classes.root, props.className)} style={{ ...style }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                            <Typography className={cx({
                                [classes.title]: true,
                                [classes.titleD]: detail
                            })}>
                                {title}
                            </Typography>
                        </Grid>
                        {detail &&
                            <>
                                <Grid item>
                                    <StopIcon className={classes.dot} />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.subtitle}>
                                        {detail}
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
            </Grid>
        </div>
    )
}

export default TitleContainer