import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import RoundedButton from "../../components/Actions/RoundedButton";
import general_translations from '../../texts/eng/website/general-translations'
import { actionTypes } from "../../store/actions";
import { connect } from "react-redux";
import useConfirmationDrawer from "./useConfirmationDrawer";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'

const styles = makeStyles(theme => ({
    root: {
        color: 'white',
    },
    paper: {
        background: 'rgba(0,0,0,0.8)'
    },
    content: {
        color: 'white',
        width: '100%',
        height: 'auto',
        margin: 'auto',
        padding: 64,
        boxSizing: 'border-box',
        background: 'rgba(255, 255, 255, 0.07)',
        border: 'solid 1px white',
        borderLeft: 'none',
        borderRight: 'none',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            padding: 32,
            boxSizing: 'border-box'
        }
    },
    appBar: {
        position: 'relative',
        background: 'transparent',
        padding: 32,
        boxSizing: 'border-box',
        boxShadow: 'none'
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 300
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    iconPassword: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    footer: {
        color: 'white',
        marginBottom: 'auto',
        borderTop: '1px solid #5A5A5A',
        padding: 24
    },
    footerTitle: {
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: '0.25em'
    },
    button: {
        fontSize: 16,
        fontWeight: 300,
        color: '#D3D3D3',
        letterSpacing: '0.025em'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDrawer = ({ history, open, language, onUpdateToken, onUpdateSessionStatus,
    onUpdateDrawerStatus, onUpdateAuthStatus, onUpdateUserData, onUpdateArtistRegisterDrawer,
    onUpdateProMode }) => {

    const classes = styles()
    const content = general_translations.confirmation_drawer

    const { loading, error, actions, artist } = useConfirmationDrawer({
        history, language, onUpdateToken, onUpdateSessionStatus,
        onUpdateDrawerStatus, onUpdateDrawerStatus, onUpdateAuthStatus,
        onUpdateUserData, onUpdateArtistRegisterDrawer, onUpdateProMode
    })


    useEffect(() => {
        if (open)
            actions.onInitModule()
    }, [open])

    return (
        <LoadingContainer loading={loading} error={error} onCloseError={actions.onCloseError}>
            <div className={classes.root}>
                <Dialog fullScreen classes={{ paperFullScreen: classes.paper }} open={open} onClose={actions.onClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <IconButton edge="end" color="inherit" onClick={actions.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <Grid container justifyContent='center' alignItems='stretch' spacing={8}>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center' spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' align='center' className={classes.title}>
                                            {content.title[0]} <span>{content.title[1]}</span>
                                        </Typography>
                                    </Grid>
                                    {artist && artist.user_type_id === 3 && artist.artist_status_id === 5 &&
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' align='center' className={classes.subtitle}>
                                                    {content.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <RoundedButton
                                                    onClick={actions.onOpenArtistRegister}
                                                    color='white'
                                                    endIcon={<ArrowForwardIosIcon />}
                                                >
                                                    {content.button}
                                                </RoundedButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align='center' className={classes.button}>
                                                    {content.not_now}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Dialog>
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        open: state.confirmation_drawer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateToken: (token) => dispatch({ type: actionTypes.SYS_UPDATE_TOKEN, token }),
        onUpdateSessionStatus: (session_status) => dispatch({ type: actionTypes.SYS_UPDATE_SESSION_STATUS, session_status }),
        onUpdateDrawerStatus: (confirmation_drawer) => dispatch({ type: actionTypes.DRAWER_CONFIRMATION, confirmation_drawer }),
        onUpdateArtistRegisterDrawer: (artist_register_drawer) => dispatch({ type: actionTypes.DRAWER_ARTIST_REGISTER, artist_register_drawer }),
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDrawer)