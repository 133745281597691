import React, { useState, useEffect } from 'react'
import { onGetErrorMessage, onGetFormData, onInitForm } from '../../../shared/utility'
import queryString from 'query-string'
import { private_server, public_server } from '../../../config'

const useExhibitionsView = ({ is_auth, user, history, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [userID, setUserID] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [filterValue, setFilterValue] = useState(0)
    const [exhibitions, setExhibitions] = useState([])

    //MODAL
    const [add_modal, setAddModal] = useState(false)

    useEffect(() => {
        if (is_auth && user)
            setUserID(user.id_user)
        actions.initModule()
    }, [is_auth, user])

    useEffect(() => {
        if (userID)
            actions.onFilterExhibition()
    }, [filterValue, userID])

    const actions = {
        initModule: async () => {
            window.scrollTo(0, 0)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onSearchExhibition: async (data) => {
            try {
                // setSending(true)
                let temp = { ...form }
                temp.search = { ...data }
                if (error) setError(null)
                setForm(temp)

                const searchValue = data.value

                let filter = {
                    AND: [
                        { field: "artist_id", operator: "=", value: userID },
                        { field: "title", operator: "LIKE", value: `%%${searchValue}%%` },
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))

                let params2send = { offset: 0, order: 0, order_by: 'id_exhibition_artist' }
                let params = searchValue.length > 0 ? `?${queryString.stringify(params2send)}&filter=${filter}` : `?${queryString.stringify(params2send)}`
                const _exhibitions = await public_server.get(`exhibitionartist/all/ns${params}`)
                setExhibitions([..._exhibitions.data.data.exhibition_artists])

            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            //setSending(false)
        },
        onUpdateFilterValue: (id) => setFilterValue(id),
        onFilterExhibition: async () => {
            try {
                setLoading(true)

                let filter = {
                    AND: [
                        { field: "artist_id", operator: "=", value: userID },
                    ]
                }
                if (filterValue) filter.AND.push({ field: "exhibition_status_id", operator: "=", value: filterValue })
                filter = encodeURIComponent(JSON.stringify(filter))

                let params2send = { offset: 0, order: 0, order_by: 'id_exhibition_artist' }
                let params = `?${queryString.stringify(params2send)}&filter=${filter}`
                const _exhibitions = await public_server.get(`exhibitionartist/all/ns${params}`)
                setExhibitions([..._exhibitions.data.data.exhibition_artists])

            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onVisitUrl: (url) => {
            if (url && url.length > 0)
                history.push(`exhibitions/${url}`)
        },
        onOpenAddModal: () => setAddModal(true),
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSaveExhibition: async () => {
            try {
                setSending(true)

                const data2send = onGetFormData(form)
                delete data2send.search

                let _exhibition = await private_server.post(`exhibition`, data2send)
                _exhibition = _exhibition.data.data.exhibition
                setExhibitions([...exhibitions, _exhibition])

                setError(null)
                setAddModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { form, filterValue, exhibitions, add_modal }

    return { system, view_data, actions }
}

export default useExhibitionsView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'number',
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    sold: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sold'
        }
    },
    nft: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'nft'
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
}