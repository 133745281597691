import React, { useRef } from 'react';
import { connect } from 'react-redux'
import { translations as translations_esp } from '../../../texts/eng/website/artist-profile-translations'
import { makeStyles } from '@material-ui/styles';
import LoadingContainer from '../../../components/Structure/Layouts/LoadingContainer';
import MainBanner from './sections/MainBanner';
import Biography from './sections/Biography/Biography';
import Artwork from './sections/Artwork'
import Virtual from './sections/Virtual/Virtual'
import Magazines from './sections/Magazines/Magazines'
import Masterclasses from './sections/Masterclasses/Masterclasses';
import Abstract from './sections/Abstract';
import useArtistProfileView from './useArtistProfileView';
import { actionTypes } from '../../../store/actions';

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const ArtistProfileView = ({ match, history, user, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus }) => {

    const classes = styles()
    const content = translations_esp

    const { loading, sending, success, error, actions, page, artist,
        mainImage, profesionalImages, artwork, exhibitions, masterclasses,
        magazines, bioRef } = useArtistProfileView({ match, history, user, onUpdateArtworkData, onUpdateModalStatus, onUpdatePurchaseDrawerStatus })

    return (
        <LoadingContainer loading={loading} sending={sending} error={error} success={success} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={classes.content}>
                <MainBanner history={history} artist={artist} content={content.main_info_section} />
                <Abstract
                    history={history}
                    artist={artist}
                    image={mainImage}
                    content={content.abstract_section}
                    onGoToBio={actions.onGoToBio}
                />
                {artwork.length > 0 &&
                    <Artwork
                        history={history}
                        artwork={artwork}
                        content={content.artwork_section}
                        onOpenArtWork={actions.onOpenArtWork}
                        onBuy={actions.onBuy}
                        onChangePage={actions.onChangePage}
                        page={page}
                    />
                }
                {exhibitions.length  > 0 &&
                        <Virtual
                            history={history}
                            exhibitions={exhibitions}
                            content={content.virtual_section}
                            onSaveExhibition={user ? actions.onSaveExhibition : null}
                            onDownloadDocument={actions.onDownloadDocument}
                            onVisitExhibition={actions.onVisitExhibition}
                        />
                }
                {magazines.length > 0 && <Magazines history={history} magazines={magazines} content={content.magazines_section} onClick={actions.onGoToMagazineDetails} />}
                {masterclasses.length > 0 && <Masterclasses history={history} masterclasses={masterclasses} content={content.masterclasses_section} />}
                <div ref={bioRef}>
                    <Biography
                        history={history}
                        artist={artist}
                        images={profesionalImages}
                        content={content.biography_section}
                    />
                </div>
            </div>
        </LoadingContainer >
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateArtworkData: (artwork) => dispatch({ type: actionTypes.DATA_UPDATE_ARTWORK, artwork }),
        onUpdateModalStatus: (artwork_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_ARTWORK, artwork_modal }),
        onUpdatePurchaseDrawerStatus: (purchase_enquiry_drawer) => dispatch({ type: actionTypes.DRAWER_PURCHASE_ENQUIRY, purchase_enquiry_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistProfileView)