import React, { useState } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetProMode, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";

const useNewPasswordDrawer = ({ history, language, content, onUpdateDrawerStatus,
    onUpdateDrawerSignUp, onUpdateLogInDrawer, onUpdateDrawerForgotPassword }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [token, setToken] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const actions = {
        onInitModule: async () => {
            try {
                setLoading(false)
                setError(null)

                setSending(true)

                const _form = onInitForm(form, null, content.form)
                setForm(_form)

                const search = history.location.search
                const _token = search.split('=')

                await public_server.post('user/passwordchange/validate', { token: _token[1] })

                setToken(_token[1])
            } catch (error) {
                setError(onGetErrorMessage(error))
                //  await onUpdateDrawerStatus(false)
            }
            setSending(false)
        },
        onClose: async () => {
            await onUpdateDrawerStatus(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onUpdateShowPassword: (value) => setShowPassword(value),
        onUpdateShowConfirmPassword: (value) => setShowConfirmPassword(value),
        onOpenSignUp: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerSignUp(true)
        },
        onOpenForgotPassword: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerForgotPassword(true)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            if (id === 'password')
                temp.confirm_password.value2 = temp.password.value
            setForm(temp)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            delete data2send.confirm_password

            data2send['token'] = token

            try {
                setSending(true)
                const response = await public_server.post(`/user/passwordchange/execute`, data2send)
                /*const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                onUpdateUserData(user_request.data.data.user)
                await onUpdateAuthStatus(true)*/
                setError(null)
                //await onUpdateProMode(true)
                //await onSetProMode(true)
                history.push('/')
                onUpdateDrawerStatus(false)
                onUpdateLogInDrawer(true)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    return { loading, sending, success, error, form, showPassword, showConfirmPassword, actions }
}

export default useNewPasswordDrawer

const formData = {
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 64
        }
    },
    confirm_password: {
        value: '',
        value2: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'confirm_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 64
        }
    },
}