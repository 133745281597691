import React, { useEffect, useState } from 'react';
import { Button, TextField, withStyles, makeStyles, InputLabel } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { onGetDateFormat } from '../../shared/utility';
import CountryCodesModal from '../Modals/CountryCodesModal';

const CountryCodeButtonW = (props) => {
	const { data, onChange} = props;

	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false)

	
	const onInnerChange = (code) => {
		let temp = { ...data };
		temp.value = `+${code}`;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
		setOpenModal(false)
	};

	const { config, value, isValid, isRequired, isVisited } = data;
	const isError = (isVisited && !isValid) || (isVisited && isRequired && !isValid);
	let interConfig = { ...config };
	if (!isError) interConfig.helperText = '';

	return (
		<div>
			<CountryCodesModal open={openModal} onClose={()=>setOpenModal(false)} onPick={onInnerChange} />
			<InputLabel shrink htmlFor={interConfig.id}>
          		{interConfig.label}
        	</InputLabel>
			<Button id={interConfig.id} variant='outlined' className={classes.root} onClick={()=>setOpenModal(true)}>
				{value}
			</Button>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: 56,
		borderRadius: 12,
		background: 'transparent',
		color: 'white',
		border: '1px solid white',
		width: '100%',
		'&:hover':{
			border: `1px solid ${theme.palette.secondary.main}`
		}
	},
}));


export default CountryCodeButtonW;



