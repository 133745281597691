import React, { useEffect, useRef } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import ProRouter from '../../routes/pro-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import useKuvikProLayout from './useKuvikProLayout';
import ProfilePictureModal from '../../modals/ProfilePictureModal/ProfilePictureModal'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import Sidebar from './components/Sidebar';
import ArtistRegisterDrawer from '../ArtistRegisterDrawer/ArtistRegisterDrawer';
import ArtworkModal from '../../modals/ArtworkModal/ArtworkModal';
import ConfirmationDrawer from '../ConfirmationDrawer/ConfirmationDrawer';
import ContactDrawer from '../ContactDrawer/ContactDrawer';
import PurchaseEnquiryDrawer from '../PurchaseEnquiryDrawer/PurchaseEnquiryDrawer';
import cx from 'classnames'
import WelcomeArtistDrawer from '../WelcomeArtistDrawer/WelcomeArtistDrawer';
import ChangePasswordModal from '../../modals/ChangePasswordModal/ChangePasswordModal';

const KuvikProLayout = ({ history, language, blur, is_auth, pro_mode,
    showSidebar, onUpdateUserData, onUpdateAuthStatus, onUpdateProMode, 
    onUpdateArtistRegisterDrawer, onUpdateChangePasswordModal, welcome_drawer, onUpdateWelcomeDrawer }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { loading, sending, success, error, change_password_modal, actions } =
        useKuvikProLayout({
            history, onUpdateUserData, onUpdateAuthStatus, onUpdateProMode,
            is_auth, pro_mode, onUpdateArtistRegisterDrawer,
            onUpdateChangePasswordModal, onUpdateWelcomeDrawer
        })

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
            <div className={cx({
                [classes.root]: true,
                [classes.root_blur]: blur
            })}>
                <ProfilePictureModal />
                <ChangePasswordModal language={language} />
                <ArtworkModal />
                <ConfirmationDrawer history={history} />
                <WelcomeArtistDrawer history={history} />
                <ContactDrawer history={history} />
                <PurchaseEnquiryDrawer />
                <ArtistRegisterDrawer history={history} />
                <Sidebar history={history} ismobile={ismobile} onItemSelected={actions.onItemSelected} />
                <Topbar history={history} showSidebar={showSidebar} onItemSelected={actions.onItemSelected} onShowApplicationForm={actions.onShowApplicationForm} />
                <main className={classes.main} >
                    <ProRouter />
                </main>

            </div>
        </LoadingContainer>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        background: '#FCFCFC',
        display: 'flex',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        filter: 'none',
        position: 'relative',
        zIndex: 1,
    },
    root_blur: {
        filter: 'blur(3px)'
    },
    main: {
        height: '100vh',
        marginTop: 80,
        boxSizing: 'border-box',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: 'auto',
        width: '100%',
        // marginLeft: 120
    },
    main_mobile: {
        marginLeft: 0
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        pro_mode: state.pro_mode,
        blur: state.blur,
        user: state.user,
        language: state.language,
        showSidebar: state.sidebar_drawer,
        welcome_drawer: state.welcome_artist_drawer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateLanguage: (language) => dispatch({ type: actionTypes.SYS_UPDATE_LANGUAGE, language }),
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateProMode: (pro_mode) => dispatch({ type: actionTypes.SYS_PRO_MODE, pro_mode }),
        onUpdateChangePasswordModal: (change_password_modal) => dispatch({ type: actionTypes.MODAL_CHANGE_PASSWORD, change_password_modal }),
        onUpdateArtistRegisterDrawer: (artist_register_drawer) => dispatch({ type: actionTypes.DRAWER_ARTIST_REGISTER, artist_register_drawer }),
        onUpdateWelcomeDrawer: (welcome_artist_drawer) => dispatch({ type: actionTypes.DRAWER_WELCOME_ARTIST, welcome_artist_drawer }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KuvikProLayout);

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}