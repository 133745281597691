import axios from "axios"
import { useCallback, useRef, useState } from "react"
import { private_server } from "../../config"
import { onGetCurrentUserProfile, onGetErrorMessage, onGetFileExtension } from "../../shared/utility"

const useProfilePictureModal = ({ user, onUpdateModalStatus, onUpdateUserData, onUpdateProfilePicture }) => {

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    const [crop, setCrop] = useState({ aspect: 1, unit: '%', width: 100 })
    const [croppedImage, setCroppedImage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef()


    const actions = {
        onResetComponent: () => {
            setFile(null)
            setSrc(null)
            setCrop({ aspect: 1, unit: '%', width: 100 })
            setCroppedImage(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        },
        onDropFile: (data) => {
            setFile(data)
            if (data) {
                const reader = new FileReader();
                reader.addEventListener("load", () => setSrc(reader.result))
                reader.readAsDataURL(data)
            }
        },
        onImageLoaded: img => {
            imageRef.current = img

            const aspect = 1
            const width = img.width < img.height ? 100 : (img.height / img.width) * 100
            const height = img.width > img.height ? 100 : (img.width / img.height) * 100
            const y = (100 - height) / 2
            const x = (100 - width) / 2

            setCrop({
                unit: '%',
                width,
                height,
                x,
                y,
                aspect,
            })

            return false // Return false if you set crop state in here.
        },
        handleOnCropChange: (crop) => setCrop(crop),
        handleOnCropComplete: async (crop, pixelCrop) => {
            setValid(crop.width > 0 && crop.height > 0)
            if (imageRef && crop.width && crop.height) {
                const croppedImg = await actions.getCroppedImg(imageRef.current, crop, 'profile')
                setCroppedImage(croppedImg)
            }
        },
        getCroppedImg: (image, crop) => {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
            );
            // As Base64 string
            // const base64Image = canvas.toDataURL('image/jpeg');

            // As a blob
            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    let fileUrl
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl)
                });
            });
        },
        handlerURL2Blob: async (url) => {
            let blob = await fetch(url).then(r => r.blob());
            let temp = blob.type.split('/');
            let ext = "." + temp[temp.length - 1];
            let newfile = new File([blob], "profile" + ext, { type: blob.type });
            return newfile;
        },
        onSubmit: async () => {
            if (!valid) {
                setError('Please select a file')
                return
            }
            setLoading(true)
            try {
                const file = await actions.handlerURL2Blob(croppedImage)
                const ext = { name: "image", ext: onGetFileExtension(file.name) }

                if (croppedImage) {
                    // Image upload handler
                    if (user) {
                        let response = await private_server.post(`/user/me/image`, ext)
                        const options = { headers: { 'Content-Type': file.type } }
                        delete axios.defaults.headers.common["Authorization"]
                        await axios.put(response.data.data.url, file, options);
                        // Reload user
                        const _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        //onUpdateProfilePicture(_user.image)
                    }
                    setLoading(false)
                    onUpdateModalStatus(false)
                }
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        }
    }

    return { loading, file, src, crop, actions }
}

export default useProfilePictureModal