import axios from "axios"
import { useRef, useState } from "react"
import { CONFIG_SETTINGS, private_server } from "../../../../../config"
import { isValidDocument, onGetCurrentUserProfile, onGetErrorMessage, onGetFileExtension } from "../../../../../shared/utility"

const useArtistImageModal = ({ id, professionalImage, onUpdateArtistImages }) => {

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    //const [crop, setCrop] = useState({ aspect: 1, width: 128, unit: 'px' })
    const [croppedImage, setCroppedImage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef()


    const actions = {
        onResetComponent: () => {
            setFile(null)
            setSrc(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        },
        onDropFile: (data) => {
            setFile(data)
            if (data) {
                const reader = new FileReader();
                reader.addEventListener("load", () => setSrc(reader.result))
                reader.readAsDataURL(data)
            }
            setValid(true)
        },
        onImageLoaded: image => imageRef.current = image,
        handlerURL2Blob: async (url) => {
            let blob = await fetch(url).then(r => r.blob());
            let temp = blob.type.split('/');
            let ext = "." + temp[temp.length - 1];
            let newfile = new File([blob], "profile" + ext, { type: blob.type });
            return newfile;
        },
        onSubmit: async () => {
            if (!valid) {
                setError('Please select a file')
                return
            }
            setLoading(true)
            try {
                const file2send = {
                    name: 'image',
                    ext: onGetFileExtension(file.name)
                }

                const initialData = {
                    title: " ",
                    description: " ",
                    enabled: 1
                }
                console.log(professionalImage)
                let professionalImageID = professionalImage && professionalImage.id ? professionalImage.id : null

                // Image upload handler
                if (file) {

                    let response = null

                    if (professionalImage && !professionalImage.id) {
                        response = await private_server.post(`professionalimage/me`, initialData)
                        professionalImageID = response.data.data.professional_image.id_professional_image
                    }

                    const options = { headers: { 'Content-Type': file.type } }
                    response = await private_server.post(`professionalimage/${professionalImageID}/image/me`, file2send)
                    await axios.put(response.data.data.url, file, options)

                    //Obtenemos la imagen del registro en la base

                    response = await private_server.get(`professionalimage/${professionalImageID}/me`)

                    const _professional_image = response.data.data.professional_image.image

                    let isValid = isValidDocument(_professional_image)
                    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_professional_image}` : null

                    await onUpdateArtistImages({ id: professionalImageID, image: selectedImage, main: professionalImage.main })
                }

                setLoading(false)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        }
    }

    return { loading, file, src, actions }
}

export default useArtistImageModal