import React, { useState, useEffect } from "react"
import { CONFIG_SETTINGS, private_server, public_server } from "../../config";
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetProMode, onSetTokenSession, updateAuthorizationHeader } from "../../shared/utility";
import { generic_errors } from "../../texts/eng/genericErrors";

const useForgotPasswordDrawer = ({ open, language, content, onUpdateDrawerStatus,
    onUpdateDrawerSignUp, onUpdateDrawerLogIn }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    useEffect(() => {
        if (!open)
            actions.onInitModule()
    }, [open])

    const actions = {
        onInitModule: () => {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setLoading(false)
            setSending(false)
            setError(null)
            setForm(_form)
        },
        onClose: async () => {
            await onUpdateDrawerStatus(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onOpenSignUp: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerSignUp(true)
        },
        onOpenLogIn: async () => {
            await onUpdateDrawerStatus(false)
            await onUpdateDrawerLogIn(true)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)

            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)

            try {
                setSending(true)
                const response = await public_server.post(`user/passwordchange/request`, data2send)
                setError(null)
                onUpdateDrawerStatus(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        }
    }

    return { loading, sending, success, error, form, actions }
}

export default useForgotPasswordDrawer

const formData = {
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
}