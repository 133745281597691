import React, { useEffect, useState } from "react"
import { Button, Divider, Grid, Icon, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { useTheme } from "@material-ui/styles"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { grey } from "@material-ui/core/colors"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        marginBottom: 80,
        position: 'relative',
        //minHeight: '80vh',
        [theme.breakpoints.only('xs')]: {
            minHeight: 'auto',
            height: '100%'
        }
    },
    content: {
        padding: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    center: {
        minWidth: 220,
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%,-50%)'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    image: {
        width: '100%',
        height: 'auto',
        filter: 'brightness(50%)'
    },
    imageContainer: {
        position: 'relative'
    },
    media: {
        background: 'rgb(0 0 0 / 42%)',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: 'none',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        },
    },
    media_container: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '56%',
    },
}))

const Virtual = ({ history, content, exhibition }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    console.log(exhibition)
    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
            <div className={classes.imageContainer} style={{ marginTop: 70 }}>
                {/*} <img src={content.banner} className={classes.image} />
                <div className={classes.center}>
                    <SVGIcon src={content.icon} size={87} color='white' style={{ margin: 'auto', marginBottom: 32 }} />
                    <RoundedButton fullWidth color='transparent_white_square' onClick={() => window.open(content.button.url)}>
                        {content.button.label}
                    </RoundedButton>
    </div>*/}
                {exhibition && exhibition.video ?
                    <MainContainer>
                        <div className={classes.media_container}>
                            <ReactPlayer
                                url={exhibition.video}
                                //playing={play}
                                className={classes.media}
                                //light
                                controls
                                width='100%' height='100%'
                            //onClickPreview={() => setPlay(true)}
                            />
                        </div>
                    </MainContainer>
                    :
                    ReactHtmlParser(exhibition ? exhibition.iframe : '-')
                }
                {/*  <iframe  target="_parent" frameborder="0" scrolling="no" src="https://art.kunstmatrix.com/apps/artspaces/?language=en&uid=46461&exhibition=8373413&splashscreen=false" width="100%" height="600"></iframe>*/}
            </div>
        </div>
    )
}

export default Virtual