import React, { useEffect, useRef, useState } from "react"
import { Divider, Fade, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import cx from 'classnames'
import ReactHtmlParser from 'react-html-parser'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./Carousel.css"
import PageContainer from "../../../../components/Structure/Layouts/PageContainer"
import { CONFIG_SETTINGS } from "../../../../config"
import { isValidDocument } from "../../../../shared/utility"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"
import GenericImage from "../../../../components/Structure/DisplayData/GenericImage"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        background: 'black',
        color: 'white',
    },
    content: {
        padding: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    galleryTitle: {
        fontSize: 36,
        fontWeight: 600,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    imageContainer: {
        position: 'relative'
    },
    carouselContainer: {
        //maxWidth:1440,
        margin: 'auto',
        //padding:'0px 200px'
    }
}))

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
}

const Artwork = ({ history, artworks, content, onOpenArtWork }) => {

    const classes = styles()
    const theme = useTheme()
    const mobileXS = useMediaQuery(theme.breakpoints.only('xs'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const deviceType = mobileXS ? 'mobileXS' : mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    const [currentSlide, setCurrentSlide] = useState(2)
    const [nextSlide, setNextSlide] = useState(false)
    const [interval, setIntervalState] = useState(null)

    useEffect(() => {
        setIntervalState(setInterval(() => setNextSlide(true), 8000))
        return () => clearInterval(interval)
    }, [])

    const CustomArrow = ({ onClick, side }) => {

        const handleClick = () => {
            console.log('in', nextSlide)
            clearInterval(interval)
            setIntervalState(null)
            setNextSlide(false)
            onClick()
            setIntervalState(setInterval(() => setNextSlide(true), 8000))
        }
        if (nextSlide && side === 'right') handleClick()

        return (
            <button
                onClick={handleClick}
                aria-label="Go to next slide"
                className={
                    `react-multiple-carousel__arrow
                    react-multiple-carousel__arrow--${side}
                    carousel_arrow 
                    carousel_arrow-${side}`
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
            </MainContainer>
            <div className={classes.carouselContainer}>
                <Carousel
                    responsive={responsive}
                    ssr
                    infinite
                    keyBoardControl
                    centerMode={!mobile}
                    customTransition="transform 300ms ease-in-out"
                    itemClass="carouselItem"
                    deviceType={deviceType}
                    removeArrowOnDeviceType={["mobileXS"]}
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                        //console.log("Cambio")
                        //console.log("previous", previousSlide)
                        //console.log("current", currentSlide)
                        setCurrentSlide(currentSlide)
                    }}
                    customLeftArrow={<CustomArrow side='left' />}
                    customRightArrow={<CustomArrow side='right' />}
                >
                    {artworks.map((artwork, key) => {

                        let idx = currentSlide - 2
                        /*if(idx < 0){
                            console.log(idx + ) 
                        }*/

                        return <CarouselItem key={key.toString()} current={currentSlide - 2 === key} {...artwork} content={content}
                            onOpenArtWork={() => onOpenArtWork(artwork)} />
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default Artwork

const styles_carousel = makeStyles(theme => ({
    root: {
        width: '100%',
        //minHeight: '100vh',
        color: 'white',
        paddingTop: 64,
        paddingBottom: 64,
        transition: 'padding 0s linear 100ms',
        [theme.breakpoints.down('md')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 32,
            boxSizing: 'border-box',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    current: {
        //padding: 0,
        transition: 'padding 0s linear'
    },
    currentFadeIn: {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0s linear, opacity 500ms linear',
        minHeight: 150,
    },
    currentFadeOut: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 500ms, opacity 500ms linear',
        minHeight: 150,
    },
    content: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        }
    },
    image: {
        width: '100%',
        height: 500,
        objectFit: 'contain',
        //filter: 'brightness(50%)',
        //borderRadius: 5
    },
    title: {
        fontSize: 32,
        fontWeight: 600,
        [theme.breakpoints.only('sm')]: {
            fontSize: 28,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 24,
        }
    },
    description: {
        fontSize: 20,
        fontWeight: 600,
        paddingBottom: 32,
        [theme.breakpoints.only('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        }
    },
    current_card: {
        width: '100%'
    },
    generic_card: {

    }
}))

const CarouselItem = ({ content, current, artwork, artwork_description, artwork_web_image,
    artwork_mobile_image, onOpenArtWork }) => {

    const classes = styles_carousel()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const image = mobile ? artwork_mobile_image : artwork_web_image

    const [hover, setHover] = useState(false)
    // let _description = null

    // if (current) {
    //     _description = artwork_description.replace(/<[^>]+>/g, ' ')
    //     _description = _description.substring(0, 130) + '...'
    //     if (hover)
    //         _description = ReactHtmlParser(artwork_description)
    // }

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div onClick={onOpenArtWork} style={{ width: '85%', paddingTop: '63.75%', margin: 'auto', position: 'relative' }}>
                        <div style={{
                            boxSizing: 'border-box',
                            position: 'absolute',
                            width: '100%',
                            //background: 'grey',
                            top: '50%', left: 0,
                            transform: 'translateY(-50%)',
                            borderRadius: 12,

                        }}>
                            <GenericImage src={image} style={{ transition: 'all 0.2s linear', width: current ? '100%' : '85%' }} ratio={current ? '4/3' : '16/9'} objectFit='contain' />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ minHeight: 150, }}>
                        <Fade in={current} timeout={800}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title} align='center'>
                                        {artwork}
                                    </Typography>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <div
                                        onMouseEnter={!mobile ? () => setHover(true) : null}
                                        onMouseLeave={!mobile ? () => setHover(false) : null}
                                        onClick={mobile ? () => setHover(prev => !prev) : null}
                                    >
                                        <Typography className={classes.description} align='center' component='p'>
                                            {_description}
                                        </Typography>
                                    </div>
                    </Grid>*/}
                            </Grid>
                        </Fade>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}