import React from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography, withTheme } from '@material-ui/core'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    labelButton: {
        fontSize: 14,
        fontWeight: 600,
        color: '#6D6D6D',
        marginTop: 10
    },
    label: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    }
}))

const Documents = ({ files, content, onUpload, onDownload, onRemove }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={8}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={4}>
                        {Object.keys(content.files).map(file => (
                            <Grid item xs={12} sm={4}>
                                <Grid container alignItems='center' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.label}>
                                            {files[file].label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SimpleCard>
                                            <Grid container justifyContent='center' spacing={2}>
                                                <Grid item>
                                                    <DocumentButton onClick={() => onUpload(files[file])}>
                                                        <SVGIcon src={content.fileData.upload.icon} size={24} />
                                                    </DocumentButton>
                                                    <Typography align='center' className={classes.labelButton}>
                                                        {content.fileData.upload.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <DocumentButton
                                                        onClick={() => onDownload(files[file])}
                                                        disabled={!files[file].status}
                                                    >
                                                        <SVGIcon src={content.fileData.download.icon} size={24} />
                                                    </DocumentButton>
                                                    <Typography align='center' className={classes.labelButton}>
                                                        {content.fileData.download.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <RedDocumentButton
                                                        disabled={!files[file].status}
                                                        onClick={() => onRemove(files[file])}
                                                    >
                                                        <SVGIcon src={content.fileData.remove.icon} size={24} />
                                                    </RedDocumentButton>
                                                    <Typography align='center' className={classes.labelButton}>
                                                        {content.fileData.remove.label}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </SimpleCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const DocumentButton = withStyles(theme => ({
    root: {
        background: 'black',
        padding: 10,
        margin: 'auto',
        display: 'flex',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

const RedDocumentButton = withStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        '&:hover': {
            background: theme.palette.primary.light
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

export default Documents