import { Grid, Typography } from "@material-ui/core"
import { NotificationsPausedRounded } from "@material-ui/icons"
import React from "react"
import { connect } from "react-redux"
import RoundedButton from "../../../components/Actions/RoundedButton"
import ButtonsSelect from "../../../components/Forms/ButtonsSelect"
import CountryCodeButton from "../../../components/Forms/CountryCodeButton"
import CountryCodeButtonW from "../../../components/Forms/CountryCodeButtonW"
import InputFormG from "../../../components/Forms/InputFormG"
import InputFormW from "../../../components/Forms/InputFormW"
import AvatarPicture from "../../../components/Structure/DisplayData/AvatarPicture"
import { CONFIG_SETTINGS } from "../../../config"
import { isValidDocument } from "../../../shared/utility"

const Step1 = ({ user, form, content, actions }) => {

    const _image = user ? user.image : null
    const isValid = isValidDocument(_image)
    const profile_picture = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : null

    return (

        <Grid container justifyContent="center" spacing={8}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={5}>
                    <Grid item>
                        <AvatarPicture size={125} src={profile_picture} nopicture={NotificationsPausedRounded} profile_picture
                            can_edit onChangePicture={actions.onChangeProfilePicture} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <ButtonsSelect data={form.gender_id} color='transparent_white' unactive_color='transparent' onChange={actions.onChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputFormW data={form.first_name} onChange={actions.onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputFormW data={form.last_name} onChange={actions.onChange} />
                    </Grid>
                    {Object.keys(content.form.fixed).map((item, key) => {
                        let input = (
                            <Grid item xs={12} sm={6} key={`form.0_${key}}`}>
                                <InputFormW data={form[item]} onChange={actions.onChange} />
                            </Grid>
                        )

                        if (item === 'country_code')
                            input = (
                                <Grid item xs={3} sm={2} key={`form.0_${key}}`}>
                                    <CountryCodeButtonW data={form.country_code} onChange={actions.onChange} />
                                </Grid>
                            )
                        if (item === 'mobile')
                            input = (
                                <Grid item xs={9} sm={4} key={`form.0_${key}}`}>
                                    <InputFormW data={form.mobile} onChange={actions.onChange} />
                                </Grid>
                            )


                        return input

                    })}
                 {/*   <Grid item xs={12}>
                        <Grid container justifyContent='center' alignItems='center' spacing={8}>
                            <Grid item>
                                <Typography variant='body2'>{content.reference_code.title}</Typography>
                            </Grid>
                            <Grid item>
                                <InputFormG data={form.reference_code} variant='standard' onChange={actions.onChange} />
                            </Grid>
                        </Grid>
                </Grid>*/}
                </Grid>
            </Grid>
            <Grid item>
                <RoundedButton onClick={actions.onNextStep}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Step1)