import logo from '../../../assets/logo.png'
import logo_gold from '../../../assets/logo_gold.svg'
import logo_white from '../../../assets/logo-white.png'
import logo_dark from '../../../assets/logo_dark.png'
import search_icon from '../../../assets/icons/search.svg'

import fileIcon from '../../../assets/icons/file.svg'
import kuvikDoc from '../../../assets/KuvikCatalogue2022.pdf'
import app_store_button from '../../../assets/apple_button.png'
import play_store_button from '../../../assets/google_button.png'

// Social media icons
import FB_icon from '../../../assets/icons/facebook-black.png'
import IG_icon from '../../../assets/icons/instagram-black.png'
import FB from '../../../assets/icons/facebookWhite.svg'
import IG from '../../../assets/icons/instagramWhite.svg'
import TW from '../../../assets/icons/twitterWhite.svg'
import TT from '../../../assets/icons/tiktok.svg'
import PT from '../../../assets/icons/pinterest.svg'
import YT from '../../../assets/icons/youtube.svg'
import WE from '../../../assets/icons/emblemweb.svg'
import OP from '../../../assets/icons/opeansea.svg'
import VM from '../../../assets/icons/vimeo.svg'

import mini_world from '../../../assets/icons/net.svg'
import mini_lock from '../../../assets/icons/mini_lock.svg'

import uploadIcon from '../../../assets/upload.svg'
import emailSent from '../../../assets/email_sent.png'

import vertical_arrow from '../../../assets/icons/vertical_arrow.svg'

import goldLogo from '../../../assets/logo_gold.svg'
import footerBG1 from '../../../assets/bgform.png'
import footerBG2 from '../../../assets/exhibitions/artist1.png'
import footerBG3 from '../../../assets/exhibitions/artist4.png'

import contactImage from '../../../assets/contact.png'
import waves from '../../../assets/radio_waves_white.png'
import noImage from '../../../assets/artist/noImage.png'

const helper_text = 'This field must contain at least one character'

const general_translations = {
    arrow_navigate: {
        label: 'Navigate',
        icon: vertical_arrow
    },
    sidebar: {
        logo: logo,
        title: 'Monitoring System',
        list: [
            {
                name: 'Dashboard',
                path: '/',
                icon: 'dashboard',
            },
            {
                name: 'Devices',
                path: '/devices',
                icon: 'device_hub',
            },
            {
                name: 'Locations',
                path: '/locations',
                icon: 'room',
            }
        ]
    },
    login_drawer: {
        logo: logo_white,
        title: ['Welcome to', 'kuvik!'],
        description: 'The community of  artists, galleries, collectors and art lovers.',
        form: {
            password: {
                placeholder: 'Password'
            },
            email: {
                placeholder: 'Email Address'
            }
        },
        forgot_password: 'Forgot Password?',
        button: 'Log in',
        no_account: 'No account?',
        sign_up: 'Sign up',
        social_media: {
            title: 'Social media',
            media: [
                { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                // { id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
            ]
        }
    },
    forgot_password_drawer: {
        logo: logo_white,
        title: ['Forgot your', 'password?'],
        subtitle: 'We will send a recovery email if there is a kuvik account linked to it.',
        form: {
            email: {
                placeholder: 'Email Address'
            }
        },
        button: 'Recover',
        go_login: 'Go to log in',
        no_account: 'No account?',
        sign_up: 'Sign up',
        social_media: {
            title: 'Social media',
            media: [
                { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                // { id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
            ]
        }
    },
    new_password_drawer: {
        logo: logo_white,
        title: ['Please set a', 'new password'],
        description: 'Enter your new password',
        form: {
            password: {
                placeholder: 'New password'
            },
            confirm_password: {
                placeholder: 'Confirm new password',
                helperText: 'Should be the same password'
            },
        },
        button: 'Ready',
        social_media: {
            title: 'Social media',
            media: [
                { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                // { id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
            ]
        }
    },
    signup_drawer: {
        logo: logo_white,
        title: 'Sign up',
        i_am: 'I am:',
        categories: [
            { id: 6, label: 'Art Lover', description: 'Art lover is someone that loves and enjoys art in any of its presentations but is not involved in its creation' },
            { id: 3, label: 'Artist', description: 'Artist is someone involved in the fine and visual arts and is constantly creating art proposals' },
            { id: 4, label: 'Gallery', description: 'Gallery is a place that shows and sells art ' },
            { id: 5, label: 'Collector', description: 'Collector is someone interested in acquiring a work of art' }
        ],
        form: {
            title: 'Personal Info',
            form: {
                optional: {
                    first_name: {
                        label: 'First Name',
                        placeholder: 'First Name'
                    },
                    last_name: {
                        label: 'Last Name',
                        placeholder: 'Last Name'
                    },
                },
                fixed: {
                    country_id: {
                        //label: 'Country',
                        placeholder: 'Country'
                    },
                    email: {
                        label: 'Email Address',
                        placeholder: 'Email Address'
                    },
                    password: {
                        label: 'Password',
                        placeholder: 'Password'
                    },
                    confirm_password: {
                        label: 'Confirm Password',
                        placeholder: 'Confirm Password'
                    },
                    // country_code: {
                    //     //label: 'Country code',
                    //     placeholder: 'Country code'
                    // },
                    mobile: {
                        label: 'Mobile number',
                        placeholder: 'Mobile number'
                    },
                    url: {
                        label: 'Username',
                        placeholder: 'Username'
                    }
                }
            },
            reference_code: {
                title: 'Do you have an invitation code?',
                form: {
                    label: 'REFERENCE CODE',
                    placeholder: 'REFERENCE CODE'
                },
            },
            button: 'Continue'
        },
        artist: [
            {
                title: 'Personal Info',
                form: {
                    first_name: {
                        label: 'First Name',
                        placeholder: 'First Name'
                    },
                    last_name: {
                        label: 'Last Name',
                        placeholder: 'Last Name'
                    },
                    discipline_id: {
                        //label: 'Discipline',
                        placeholder: 'Discipline'
                    },
                    country_id: {
                        //label: 'Country',
                        placeholder: 'Country'
                    },
                    birth_date: {
                        label: 'Date of Birth',
                        placeholder: 'Date of Birth'
                    },
                    mobile: {
                        label: 'Mobile number',
                        placeholder: 'Mobile number'
                    },
                },
                /*reference_code: {
                    title: 'Do you have an invitation code?',
                    form: {
                        label: 'REFERENCE CODE',
                        placeholder: 'REFERENCE CODE'
                    },
                },*/
                button: 'Continue'
            },
            {
                title: 'ArtWork',
                subtitle: 'Add samples of your artwork',
                max: '(max. 10 images)',
                button: 'Continue'
            },
            {
                title: 'ArtWork',
                words: 'words',
                form: {
                    motivational_tweet: {
                        label: 'Motivational Tweet',
                        placeholder: 'Motivational Tweet'
                    },
                    biography: {
                        label: 'Biografía',
                        placeholder: 'Biografía'
                    },
                    statement: {
                        label: 'Statement',
                        placeholder: 'Statement'
                    }
                },
                button: 'Continue'
            },
            {
                title: 'Documentation',
                words: 'words',
                upload_icon: uploadIcon,
                files: {
                    cv: { label: 'CV' },
                    portfolio: { label: 'Portfolio' },
                    other: { label: 'Other' }
                },
                subform: {
                    optional_documents: {
                        label: 'Optional documents',
                        helperText: 'You can include collective exhibitions, individuals, residences and awards received.',
                    },
                    sponsorship: {
                        label: 'Sponsorship',
                        helperText: [
                            'KUVIK offers limited number of economic grants ranging from 20% to fully sponsored scholarships to collaborate with talented artists regardless of their socio-economic status.',
                            'If you have the credentials to be part of kuvik and need a grant to fund your participation, please click on the “Sponsorship” tab.'
                        ]
                    },
                    choosing_reason: {
                        label: 'Why choosing you?',
                    }
                },
                form: {
                    choosing_reason: {
                        placeholder: 'Please state why you believe you are elegble to receive an scolarship to be part of KUVIK',
                    }
                },
                terms_privacity: 'I accept VK Galerie terms of service and privacy policy.',
                button: 'Continue'
            },
            {
                title: 'Create Account',
                words: 'words',
                form: {
                    professional_name: {
                        label: 'Professional name',
                        placeholder: 'Professional name'
                    },
                    email: {
                        label: 'Email Address',
                        placeholder: 'Email Address'
                    },
                    password: {
                        label: 'Password',
                        placeholder: 'Password'
                    },
                    confirm_password: {
                        label: 'Confirm Password',
                        placeholder: 'Confirm password'
                    }
                },
                button: 'Sign up'
            },
        ],
        finish: {
            title: 'Welcome to our community!',
            image: emailSent,
            description: [
                'Please confirm your account',
                'We’ll send an email to'
            ],
            email: 'psteven@gmail.com',
            button: 'Finish'
        },
    },
    confirmation_drawer: {
        logo: logo_white,
        title: ['Welcome', 'ARTIST!'],
        description: 'You have some pending information in your application process.',
        subdescription:'This step is only required if you want to become a member',
        button: 'Assessment',
        not_now: 'Not now'
    },
    artist_register_drawer: {
        logo: logo_white,
        steps: [
            {
                title: 'Personal Info',
                form: {
                    optional: {
                        first_name: {
                            label: 'First Name',
                            placeholder: 'First Name'
                        },
                        last_name: {
                            label: 'Last Name',
                            placeholder: 'Last Name'
                        },
                    },
                    fixed: {
                        country_id: {
                            //label: 'Country',
                            placeholder: 'Country'
                        },
                        birth_date: {
                            label: 'Date of Birth',
                            placeholder: 'Date of Birth'
                        },
                        /*email: {
                            label: 'Email Address',
                            placeholder: 'Email Address'
                        },*/
                        country_code: {
                            //label: 'Country code',
                            placeholder: 'Country code'
                        },
                        mobile: {
                            label: 'Mobile number',
                            placeholder: 'Mobile number'
                        },
                        url: {
                            label: 'Username',
                            placeholder: 'Username'
                        }
                    }
                },
                /* reference_code: {
                     title: 'Do you have an invitation code?',
                     form: {
                         label: 'REFERENCE CODE',
                         placeholder: 'REFERENCE CODE'
                     },
                 },*/
                button: 'Continue'
            },
            {
                title: 'ArtWork',
                subtitle: 'Add samples of your artwork',
                max: '(max. 12 images)',
                button: 'Continue',
                noImage: noImage
            },
            {
                title: 'About you',
                words: 'words',
                characters: 'characters',
                form: {
                    motivational_tweet: {
                        label: 'Motivational Tweet',
                        placeholder: 'Motivational Tweet',
                        helperText: 'Write at least 1 character and less than 140 characters please'
                    },
                    biography: {
                        label: 'Biography',
                        placeholder: 'Biography',
                        words: true,
                        helperText: 'Write at least 1 word and less than 250 words please'
                    },
                    statement: {
                        label: 'Statement',
                        placeholder: 'Statement',
                        words: true,
                        helperText: 'Write at least 1 word and less than 200 words please'
                    }
                },
                button: 'Continue'
            },
            {
                title: 'Documentation',
                words: 'words',
                upload_icon: uploadIcon,
                terms: 'I accept VK Galerie terms of service and privacy policy.',
                files: {
                    cv: { label: 'CV' },
                    portfolio: { label: 'Portfolio' },
                    other: { label: 'Other' }
                },
                subform: {
                    optional_documents: {
                        label: 'Optional documents',
                        helperText: 'You can include collective exhibitions, individuals, residences and awards received.',
                    },
                    sponsorship: {
                        label: 'Sponsorship',
                        helperText: [
                            'KUVIK offers limited number of economic grants ranging from 20% to fully sponsored scholarships to collaborate with talented artists regardless of their socio-economic status.',
                            'If you have the credentials to be part of kuvik and need a grant to fund your participation, please click on the “Sponsorship” tab.'
                        ]
                    },
                    choosing_reason: {
                        label: 'Why choosing you?',
                    }
                },
                form: {
                    choosing_reason: {
                        placeholder: 'Please state why you believe you are elegble to receive an scolarship to be part of KUVIK',
                    }
                },
                terms_privacity: 'I accept VK Galerie terms of service and privacy policy.',
                button: 'Continue'
            },
            {
                social_media: 'Social Media',
                form: {
                    professional_name: {
                        label: 'Professional name',
                        placeholder: 'Professional name'
                    },
                    discipline: {
                        //label: 'Discipline',
                        placeholder: 'Discipline'
                    },
                    facebook: {
                        label: 'Facebook',
                        placeholder: 'Facebook',
                        icon: FB,
                    },
                    twitter: {
                        label: 'Twitter',
                        placeholder: 'Twitter',
                        icon: TW,
                    },
                    instagram: {
                        label: 'Instagram',
                        placeholder: 'Instagram',
                        icon: IG,
                    },
                    tiktok: {
                        label: 'Tiktok',
                        placeholder: 'Tiktok',
                        icon: TT,
                    },
                    pinterest: {
                        label: 'Pinterest',
                        placeholder: 'Pinterest',
                        icon: PT,
                    },
                    youtube: {
                        label: 'Youtube',
                        placeholder: 'Youtube',
                        icon: YT,
                    },
                    vimeo: {
                        label: 'Vimeo',
                        placeholder: 'Vimeo',
                        icon: VM,
                    },
                    opensea: {
                        label: 'Opensea',
                        placeholder: 'Opensea',
                        icon: OP,
                    },
                },
                button: 'Sign up'
            },
        ],
        finish: {
            title: 'Welcome to our community!',
            image: emailSent,
            description: [
                'Please confirm your account',
                'We’ll send an email to'
            ],
            //email: 'psteven@gmail.com',
            button: 'Finish'
        },
        modals: {
            documents: {
                title: 'Upload document',
                label: 'Select the document',
                button: 'Save'
            }
        }
    },
    contact_drawer: {
        logo: logo,
        title: 'Contact us',
        image: contactImage,
        steps: [
            {
                subtitle: 'We want to help you',
                menu: [
                    { id: 'general', value: 1, label: 'General' },
                    { id: 'sales', value: 2, label: 'Sales' },
                    { id: 'evennt', value: 3, label: 'Event' },
                    { id: 'submission', value: 4, label: 'Submission' },
                ],
                form: {
                    general: {
                        name: {
                            placeholder: 'Name',
                            helperText: 'Introduce your name'
                        },
                        email: {
                            placeholder: 'Email Address',
                            helperText: 'Introduce your email'
                        },
                        phone: {
                            placeholder: 'Phone',
                            helperText: 'Introduce your phone'
                        },
                        message: {
                            placeholder: 'Message',
                            helperText: 'Introduce a message'
                        }
                    },
                    sales: {
                        name: {
                            placeholder: 'Name',
                            helperText: 'Introduce your name'
                        },
                        email: {
                            placeholder: 'Email Address',
                            helperText: 'Introduce your email'
                        },
                        phone: {
                            placeholder: 'Phone',
                            helperText: 'Introduce your phone'
                        },
                        message: {
                            placeholder: 'Message',
                            helperText: 'Introduce a message'
                        }
                    },
                    evennt: {
                        name: {
                            placeholder: 'Name',
                            helperText: 'Introduce your name'
                        },
                        email: {
                            placeholder: 'Email Address',
                            helperText: 'Introduce your email'
                        },
                        phone: {
                            placeholder: 'Phone',
                            helperText: 'Introduce your phone'
                        },
                        message: {
                            placeholder: 'Message',
                            helperText: 'Introduce a message'
                        }
                    },
                    submission: {
                        name: {
                            placeholder: 'Name',
                            helperText: 'Introduce your name'
                        },
                        email: {
                            placeholder: 'Email Address',
                            helperText: 'Introduce your email'
                        },
                        phone: {
                            placeholder: 'Phone',
                            helperText: 'Introduce your phone'
                        },
                        message: {
                            placeholder: 'Message',
                            helperText: 'Introduce a message'
                        }
                    }
                },
                button: 'Send message',
                account: 'Do you have an account?',
                log_in: 'Log in',
                medias: [
                    { id: 1, icon: FB_icon, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                    { id: 2, icon: IG_icon, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                    // { id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
                ],
                small_text: '* In order to respond to your enquiry, we will process the personal data you have supplied in accordance with our <strong><u>privacy policy</u></strong>. You can unsubscribe or change your preferences at any time by clicking the link in any emails.'
            },
            {
                title: ['Your message was', 'successfully recieved!'],
                subtitle: 'We will contact you shortly.',
                button: 'Got it',
                account: 'Do you have an account?',
                log_in: 'Log in',
            }
        ]
    },
    purchase_enquiry_drawer: {
        logo: logo_gold,
        title: 'We will contact you!',
        form_title: 'Please, leave us your contact information',
        form: {
            name: { label: 'Name*', placeholder: 'Name', helperText: helper_text },
            email: { label: 'Email address*', placeholder: 'Email address', helperText: 'Invalid email' },
            country_code: { label: 'Code', placeholder: '(+52)', helperText: 'You must select a code' },
            mobile: { label: 'Phone', placeholder: 'Phone', helperText: 'Invalid phone' },
            message: { label: 'Message', placeholder: 'Message', helperText: helper_text },
        },
        button: 'Send request',
        completed: {
            title1: 'Thank',
            title2: 'you!',
            img: waves,
            message: 'A consultant will reach you shortly.',
            button: 'Got it'
        }
    },
    topbar: {
        logo: logo_gold,
        logo_alt: logo,
        transparent: ['/', '/confirmation', '/about', '/artists/', '/community',],
        titles: [
            {
                url: '/artists',
                title: 'Artists'
            },
            {
                url: '/exhibitions',
                title: 'Exhibitions',
                //transparent: true
            },
            {
                url: '/magazines',
                title: 'Magazines'
            },
            {
                url: '/masterclass',
                title: 'Masterclass'
            },
            {
                url: '/marketplace',
                title: 'Marketplace'
            },
            {
                url: null,
                title: 'About',
                subtitles: [
                    {
                        url: '/community',
                        title: 'Community',
                    },
                    {
                        url: '/about',
                        title: 'Us',
                    },
                ]
            },
        ],
        contact: {
            url: null,
            title: 'Contact',
        },
        login_button: {
            url: '/login',
            label: 'Log in'
        },
        profile_menu: {
            pro: {
                url: '/kuvik-pro',
                label: 'Kuvik PRO'
            },
            logout: {
                url: '/logout',
                label: 'Log out'
            }
        },
        search: search_icon,
    },
    footer: {
        welcome: {
            success_message: [
                'You are already suscribed to our newsletter!',
                'Thank you for join us.'
            ],
            stepper: {
                ctrl_button: vertical_arrow,
                steps: [
                    {
                        title: ['Welcome to', 'kuvik'],
                        subtitle: 'Sign up for updates in exhibitions, artists and events.',
                        button: 'Next',
                        titleS: 'Looking for:',
                        form: {
                            email: {
                                placeholder: 'Enter your email',
                                helperText: 'Enter a valid email'
                            },
                            tags: {
                                helperText: '* Select at least 1 option'
                            },
                            collecting_art: { label: 'Collecting Art' },
                            exhibitions: { label: 'Exhibitions' },
                            art_news: { label: 'Art News' },
                            knowledge: { label: 'Knowledge' },
                        },
                        checkbox: ['collecting_art', 'exhibitions', 'art_news', 'knowledge']
                    },
                    {
                        title: ['Welcome to', 'kuvik'],
                        subtitle: 'Sign up for updates in exhibitions, artists and events.',
                        button: 'Next',
                        form: {
                            first_name: {
                                placeholder: 'Enter your name',
                                helperText: 'Enter a valid name'
                            },
                            last_name: {
                                placeholder: 'Enter your last name',
                                helperText: 'Enter a valid last name'
                            }
                        }
                    },
                    {
                        white: true,
                        title: ['Thank you for joining', 'our community!'],
                        subtitle: 'You have been added to our newsletter, check out your mailbox.',
                        logo: goldLogo,
                        imagesBG: [footerBG1, footerBG2, footerBG3]
                    }
                ]
            }
        },
        explore: {
            title: 'Explore',
            links: [
                { id: 1, label: 'Artist', url: '/artists' },
                { id: 2, label: 'Exhibitions', url: '/exhibitions' },
                { id: 5, label: 'Magazines', url: '/magazines' },
                { id: 3, label: 'Marketplace', url: '/marketplace' },
                { id: 4, label: 'Masterclasses', url: '/masterclass' },
            ]
        },
        about: {
            title: 'About us',
            links: [
                { id: 1, label: 'Contact', url: null },
                //{ id: 2, label: 'Privacy', url: '/privacy' },
                //{ id: 3, label: 'Terms', url: '/terms' },
                //{ id: 4, label: 'FAQ', url: '/faq' },
                { id: 5, label: 'For artist', url: '/community' },
            ]
        },
        app: {
            arrow: vertical_arrow,
            title: 'Download Kuvik catalog and get inspired by our vibrant art-tech offering',
            icon: fileIcon,
            document: kuvikDoc,
            ios: app_store_button,
            android: play_store_button
        },
        social_media: {
            title: 'Stay in touch',
            media: [
                { id: 1, icon: FB, url: 'https://www.facebook.com/Kuvik-Galerie-106153435174820', target: '_blank' },
                { id: 2, icon: IG, url: 'https://instagram.com/kuvik.art/', target: '_blank' },
                //{ id: 3, icon: TW, url: 'https://twitter.com', target: '_blank' },
                //{ id: 4, icon: LI, url: 'https://linkedin.com', target: '_blank' },
                //{ id: 5, icon: YT, url: 'https://youtube.com', target: '_blank' },
            ],
        },
        logo: logo_white,
        secure: {
            icon: mini_lock,
            title: ['Secured with', 'SSL']
        },
        brand: '© 2022 kuvik',
        language_button: {
            icon: mini_world,
            options: [
                { id: 1, value: 'en', label: 'EN' },
                // { id: 2, value: 'es', label: 'ES' }
            ]
        }
    },

}

export default general_translations