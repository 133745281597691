import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import ArtworksView from '../views/Pro/ArtworksView/ArtworksView'
import GeneralComingSoon from '../views/GeneralComingSoon'
import ArtworksDetailsView from '../views/Pro/ArtworksDetailsView/ArtworksDetailsView'
import ArtistProfileView from '../views/Pro/ArtistProfileView/ArtistProfileView'
import HomeView from '../views/Pro/HomeView/HomeView'
import ExhibitionsView from '../views/Pro/ExhibitionsView/ExhibitionsView'
import MagazinesView from '../views/Pro/MagazinesView/MagazinesView'
import MasterclassesView from '../views/Pro/MasterclassesView/MasterclassesView'

const ProRouter = () => (
    <Switch>
        <Route exact path='/pro' component={HomeView} />
        <Route path='/pro/profile' component={ArtistProfileView} />
        <Route path='/pro/applications' component={GeneralComingSoon} />
        <Route path='/pro/artist' component={GeneralComingSoon} />

        <Route exact path='/pro/artworks' component={ArtworksView} />
        <Route exact path='/pro/artworks/:url' component={ArtworksDetailsView} />

        <Route path='/pro/exhibitions' component={ExhibitionsView} />
        <Route path='/pro/magazines' component={MagazinesView} />
        <Route path='/pro/masterclasses' component={MasterclassesView} />
        {/*<Route path='/pro/confirmation' component={HomeView} />*/}
        <Redirect to='/pro' />
    </Switch>
)

export default ProRouter

