import React, { useEffect, useState } from "react"
import { Button, Divider, Grid, Icon, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import { useTheme } from "@material-ui/styles"
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon"
import { grey } from "@material-ui/core/colors"
import { isValidDocument } from "../../../../shared/utility"
import { CONFIG_SETTINGS } from "../../../../config"
import cx from 'classnames'
import moment from "moment"
import MainContainer from "../../../../components/Structure/Layouts/MainContainer"

const styles = makeStyles(theme => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        backgroundColor: '#FCFCFC',
        boxSizing: 'border-box'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    seeMoreButton: {
        display: 'block',
        margin: 'auto',
        borderRadius: 12
    },
    more: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: '0.525em'
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
}))

const Coming = ({ history, content, exhibitionsData, onShowContactDrawer, onDownloadExhibition }) => {

    const classes = styles()
    const theme = useTheme()

    const { count, exhibitions } = exhibitionsData
    const [seeMore, setSeeMore] = useState(false)

    const more = count > 2
    const _exhibitions = (!seeMore && more) ? exhibitions.slice(0, 2) : exhibitions

    const seeMoreButton = (
        <Grid item>
            <IconButton onClick={() => setSeeMore(prev => !prev)} className={classes.seeMoreButton}>
                <div className={classes.seeMoreButton}>
                    {seeMore ?
                        <SVGIcon
                            size={54}
                            src={content.moreIcon}
                            colorStroke={theme.palette.secondary.main}
                            style={{ margin: 'auto', transform: 'rotate(180deg)' }}
                        />
                        :
                        <SVGIcon
                            size={54}
                            src={content.moreIcon}
                            colorStroke={theme.palette.secondary.main}
                            style={{ margin: 'auto' }}
                        />
                    }
                    <Typography className={classes.more} align='center'>
                        {seeMore ? content.see.less : content.see.more}
                    </Typography>
                </div>
            </IconButton>
        </Grid>
    )

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container justifyContent='center' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title} align='right'>
                                    {content.title}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {count > 0 ?
                        _exhibitions.map((exhibition, key) => (
                            <Grid item xs={12}>
                                <ExhibitionCard
                                    {...exhibition}
                                    odd={key % 2 === 0}
                                    content={content}
                                    onShow={onShowContactDrawer}
                                    onDownload={onDownloadExhibition}
                                />
                            </Grid>
                        ))
                        :
                        <Grid item xs={12}>
                            <Typography className={classes.noContent} align='center'>
                                {content.noContent}
                            </Typography>
                        </Grid>
                    }
                    {more && seeMoreButton}
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Coming

const styles_card = makeStyles(theme => ({
    title: {
        fontSize: 32,
        fontWeight: 600,
    },
    imageContainer: {
        background: '#FCFCFC',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        margin: 'auto',
        [theme.breakpoints.up(450)]: {
            width: 300,
            height: 300,
        },
        [theme.breakpoints.up(700)]: {
            width: 400,
            height: 400,
        },
        [theme.breakpoints.up(900)]: {
            width: 480,
            height: 480,
        },
        [theme.breakpoints.between(960, 1250)]: {
            width: 300,
            height: 300,
        },
        [theme.breakpoints.up(1600)]: {
            width: 600,
            height: 600,
        }
    },
    image: {
        top: 0,
        left: 0,
        position: 'absolute',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        cursor: 'initial',
        [theme.breakpoints.only('xs')]: {
            position: 'inherit'
        }
    },
    imageH: {
        filter: 'brightness(0.7)',
    },
    date: {
        fontSize: 18,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    counter: {
        fontSize: 32,
        fontWeight: 300,
        '& span': {
            fontWeight: 500
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    content: {
        position: 'relative',
        height: '100%'
    },
    buttons: {
        width: '100%',
        position: 'inherit',
        bottom: 0,
        marginTop: 20
    },
    countetContainer: {
        width: '100%',
        color: 'white',
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box'
    },
    countetContainerH: {
        display: 'block'
    },
    featuredTag: {
        top: 0,
        position: 'absolute',
        right: 20
    }
}))

const ExhibitionCard = ({ content, odd, id_exhibition, title, date_text,
    start_date, medium_description, web_image, mobile_image, brochure, featured,
    exhibition_type_id, exhibition_status_id, onShow, onDownload }) => {

    const classes = styles_card()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)
    const validBrochure = isValidDocument(brochure)

    const [interval, setIntervalState] = useState(null)
    const [days, setDays] = useState(null)
    const [hours, setHours] = useState(null)
    const [minutes, setMinutes] = useState(null)


    const countDownTimer = (toDate) => {
        let dateEntered = new Date(toDate)
        let now = new Date()
        let difference = dateEntered.getTime() - now.getTime()

        if (difference <= 0) {
            //Countdown 0
            clearInterval(interval)
        } else {

            let seconds = Math.floor(difference / 1000)
            let minutes = Math.floor(seconds / 60)
            let hours = Math.floor(minutes / 60)
            let days = Math.floor(hours / 24)

            hours %= 24
            minutes %= 60

            setDays(days)
            setHours(hours)
            setMinutes(minutes)
            console.log(days)
            console.log(hours)
            console.log(minutes)
        }
    }

    useEffect(() => {
        setIntervalState(setInterval(() => {
            countDownTimer(start_date)
        }, 1000))
        return () => clearInterval(interval)
    }, [])

    const image = ismobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    const imageContent = (
        <Grid item xs={12} md={6}>
            <div
                className={classes.imageContainer}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <img src={selectedImage} className={cx({
                    [classes.image]: true,
                    [classes.imageH]: true,
                    //[classes.imageH]: hover,
                })} />
                {featured && featured > 0 ?
                    <div className={classes.featuredTag}>
                        <SVGIcon src={content.featuredIcon} color={hover ? 'white' : theme.palette.secondary.main} size={42} />
                    </div>
                    : null}
                <div className={cx({
                    [classes.countetContainer]: true,
                    [classes.countetContainerH]: true,
                    //[classes.countetContainerH]: hover
                })}>
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <Typography className={classes.counter}>
                                <span>{days ? days : 0}</span> {content.timer.days}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.counter}>
                                <span>{hours ? hours : 0}</span> {content.timer.hours}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.counter}>
                                <span>{minutes ? minutes : 0}</span> {content.timer.minutes}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Grid>
    )

    return (
        <Grid container justifyContent='center' spacing={4}>
            {(ismobile || odd) && imageContent}
            <Grid item xs={12} md={6}>
                <div className={classes.content}>
                    <Grid container alignItems='stretch' justifyContent='space-between' direction='column' style={{ height: '100%' }}>
                        <Grid item>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title}>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.date}>
                                        {date_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.description} align='justify'>
                                {medium_description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.buttons}>
                                {ismobile ?
                                    <Grid container justifyContent='center' spacing={2}>
                                        {validBrochure &&
                                            <Grid item xs={12}>
                                                <RoundedButton
                                                    color='transparent_secondary'
                                                    fullWidth
                                                    onClick={() => onDownload(brochure, `${title}_current`)}
                                                >
                                                    {content.buttons.download}
                                                </RoundedButton>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <RoundedButton fullWidth onClick={onShow}>
                                                {content.buttons.apply}
                                            </RoundedButton>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container justifyContent='center' spacing={6}>
                                        {validBrochure &&
                                            <Grid item>
                                                <RoundedButton
                                                    color='transparent_secondary'
                                                    onClick={() => onDownload(brochure)}
                                                >
                                                    {content.buttons.download}
                                                </RoundedButton>
                                            </Grid>
                                        }
                                        {exhibition_type_id === 3 && exhibition_status_id == 2 &&
                                            <Grid item>
                                                <RoundedButton onClick={onShow}>
                                                    {content.buttons.apply}
                                                </RoundedButton>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {!ismobile && !odd && imageContent}
        </Grid >
    )
}