import noImage from '../../../assets/noimage.png'
import featuredIcon from '../../../assets/icons/featured.svg'

import noMagazines from '../../../assets/icons/magazines.svg'

export const translations = {
    title: 'Magazines',
    artworks: 'Artworks',
    noMagazines: {
        title: 'No magazines to show',
        subtitle: 'Magazines will show here.',
        icon: noMagazines
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
        },
        dimensions: {
            label: 'Dimensions',
            placeholder: 'Dimensions',
        },
        technique: {
            label: 'Technique',
            placeholder: 'Technique',
        },
        price: {
            label: 'Price',
            placeholder: 'Price',
        },
        description: {
            label: 'Description',
            placeholder: 'Description',
        },
        date: {
            label: 'Date',
            //placeholder: 'Date',
        },
        sold: {
            label: 'Sold'
        },
        nft: {
            label: 'NFT'
        },
        external_url: {
            label: 'NFT Link',
            placeholder: 'NFT Link',
        },
    },
    add_button: 'Add',
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
    data: {
        title: 'Title',
        start_date: 'Date'
    },
    featured: featuredIcon
}