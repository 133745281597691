import React, { useEffect, useState } from "react"
import { Button, Card, CardActionArea, CardActions, CardMedia, Divider, Fade, Grid, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import cx from 'classnames'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./Carousel.css"
import PageContainer from "../../../../../components/Structure/Layouts/PageContainer"
import { isValidDocument, onGetCurrencyValue } from "../../../../../shared/utility"
import { CONFIG_SETTINGS } from "../../../../../config"
import RoundedButton from "../../../../../components/Actions/RoundedButton"
import GenericImage from "../../../../../components/Structure/DisplayData/GenericImage"
import MainContainer from "../../../../../components/Structure/Layouts/MainContainer"
import SVGIcon from "../../../../../components/Structure/DisplayData/SVGIcon"

const styles = makeStyles(theme => ({
    root: {
        //paddingLeft: 100,
        //paddingRight: 100,
        paddingTop: 80,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
        background: 'black',
        color: 'white',
    },
    content: {
        //padding: 100,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 32,
            paddingRight: 32,
        }
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
}))

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
    }
}

const MostViewed = ({ history, artwork, content, onBuy, onOpenArtWork, onOpenNFT }) => {

    const classes = styles()
    const theme = useTheme()
    const mobileXS = useMediaQuery(theme.breakpoints.only('xs'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const tablet = useMediaQuery(theme.breakpoints.only('md'))
    const deviceType = mobileXS ? 'mobileXS' : mobile ? 'mobile' : (tablet ? 'tablet' : 'desktop')

    const [currentSlide, setCurrentSlide] = useState(1)
    const [nextSlide, setNextSlide] = useState(false)
    const [interval, setIntervalState] = useState(null)

    useEffect(() => {
        setIntervalState(setInterval(() => setNextSlide(true), 8000))
        return () => clearInterval(interval)
    }, [])

    const CustomArrow = ({ onClick, side }) => {

        const handleClick = () => {
            clearInterval(interval)
            setIntervalState(null)
            setNextSlide(false)
            onClick()
            setIntervalState(setInterval(() => setNextSlide(true), 8000))
            let increment = side === 'right' ? 1 : -1
            setCurrentSlide(prev => {
                if (prev + increment < 0)
                    return artwork.length - 1
                else
                    return (prev + increment) % artwork.length
            })
        }
        if (nextSlide && side === 'right') handleClick()

        return (
            <button
                onClick={handleClick}
                aria-label="Go to next slide"
                className={
                    `react-multiple-carousel__arrow
                    react-multiple-carousel__arrow--${side}
                    carousel_arrow 
                    carousel_arrow-${side}`
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container alignItems='center' spacing={3}>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} align='right'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>
                <div className={classes.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        ssr
                        infinite
                        keyBoardControl
                        //centerMode={!mobile}
                        customTransition="transform 300ms ease-in-out"
                        itemClass="carouselItem"
                        deviceType={deviceType}
                        removeArrowOnDeviceType={["mobileXS"]}
                        /* afterChange={(previousSlide, { currentSlide, onMove }) => {
                             //console.log("Cambio")
                             //console.log("previous", previousSlide)
                             //console.log("current", currentSlide)
                             setCurrentSlide(currentSlide)
                         }}*/
                        customLeftArrow={<CustomArrow side='left' />}
                        customRightArrow={<CustomArrow side='right' />}
                    >
                        {artwork.map((item, key) => {
                            let idx = currentSlide - 2 === key
                            
                            return (
                                <CarouselItem
                                    key={key.toString()}
                                    // current={idx}
                                    current={currentSlide === key}
                                    {...item}
                                    content={content}
                                    onBuy={() => onBuy(item)}
                                    onClick={() => onOpenArtWork(item)}
                                />
                            )
                        })}
                    </Carousel>
                </div>
            </MainContainer>
        </div>
    )
}

export default MostViewed

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        //minHeight: '100vh',
        color: 'white',
        padding: '64px 0px',
        transition: 'padding 0s linear 100ms',
        [theme.breakpoints.down('md')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 32,
            boxSizing: 'border-box',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    current: {
        //padding: 0,
        transition: 'padding 0s linear'
    },
    currentFadeIn: {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0s linear, opacity 500ms linear',
        minHeight: 150,
    },
    currentFadeOut: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 500ms, opacity 500ms linear',
        minHeight: 150,
    },
    content: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        }
    },
    image: {
        width: '100%',
        height: 500,
        objectFit: 'contain',
        //filter: 'brightness(50%)',
        //borderRadius: 5
    },
    title: {
        fontSize: 32,
        fontWeight: 600,
        [theme.breakpoints.only('sm')]: {
            fontSize: 28,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 24,
        }
    },
    description: {
        fontSize: 20,
        fontWeight: 600,
        paddingBottom: 32,
        [theme.breakpoints.only('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        }
    },
    current_card: {
        width: '100%'
    },
    generic_card: {

    },
    buyButton: {
        textTransform: 'uppercase'
        //background:theme.palette.primary.main
    }
}))

const CarouselItem = ({ current, content, title, technique, dimensions, price, sold,
    nft, web_image, mobile_image, noImage, onBuy, onClick, onOpenNFT }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [buttonHover, setButtonHover] = useState(false)

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : noImage
    const width = current ? '100%' : '85%'

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div style={{ paddingTop: '63.75%', margin: 'auto', position: 'relative' }}>
                        <div style={{
                            boxSizing: 'border-box',
                            position: 'absolute',
                            width: '100%',
                            background: 'transparent',
                            top: '50%', left: 0,
                            transform: 'translateY(-50%)',
                            borderRadius: 12,

                        }}
                            onClick={onClick}
                        >
                            <GenericImage src={_image} style={{ background: 'grey', transition: 'all 0.2s linear', width: width }} ratio={current ? '4/3' : '16/9'} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ minHeight: 150, margin: 'auto' }}>
                        <Fade in={current} timeout={800}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom className={classes.title}>
                                        {title}
                                    </Typography>
                                    <Typography className={classes.technique}>
                                        {technique}
                                    </Typography>
                                    <Typography className={classes.dimensions}>
                                        {dimensions}
                                    </Typography>
                                </Grid>
                                {Boolean(Number(nft)) &&
                                    <Grid item>
                                        <IconButton
                                            className={cx({
                                                [classes.nft_button]: true,
                                                [classes.nft_button_grey]: sold > 0
                                            })}
                                            onClick={onOpenNFT}
                                        >
                                            <SVGIcon src={content.nft} />
                                        </IconButton>
                                    </Grid>
                                }
                                <Grid item>
                                    {sold ?
                                        <RoundedButton color='transparent_secondary'>
                                            <Typography className={classes.soldButton}>
                                                {content.sold}
                                            </Typography>
                                        </RoundedButton>
                                        :
                                        <RoundedButton
                                            color='transparent_white_square'
                                            onMouseEnter={() => setButtonHover(true)}
                                            onMouseLeave={() => setButtonHover(false)}
                                            style={{ minWidth: 150 }}
                                            onClick={onBuy}
                                        >
                                            <Typography className={classes.buyButton}>
                                                {buttonHover ? content.buy : onGetCurrencyValue(price ? price : '', 0, '€ ')}
                                            </Typography>
                                        </RoundedButton>
                                    }
                                </Grid>
                            </Grid>
                        </Fade>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

/**
 
<Card
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}
        >
            <CardActionArea
                className={classes.cardActionArea}
            >
                <CardMedia
                    className={classes.media}
                    image={selectedImage}
                    title={"Newest Artist"}
                />
            </CardActionArea>
            <CardActions classes={{ root: classes.cardActions }}>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12} sm>
                        <Typography gutterBottom className={classes.title}>
                            {title}
                        </Typography>
                        <Typography className={classes.technique}>
                            {technique}
                        </Typography>
                        <Typography className={classes.dimensions}>
                            {dimensions}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {sold ?
                            <RoundedButton color='transparent_secondary'>
                                <Typography className={classes.soldButton}>
                                    {content.sold}
                                </Typography>
                            </RoundedButton>
                            :
                            <RoundedButton
                                color='transparent_white_square'
                                onMouseEnter={() => setButtonHover(true)}
                                onMouseLeave={() => setButtonHover(false)}
                                style={{ minWidth: 150 }}
                                onClick={onBuy}
                            >
                                <Typography className={classes.buyButton}>
                                    {buttonHover ? content.buy : `$${price}`}
                                </Typography>
                            </RoundedButton>
                        }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>



 */