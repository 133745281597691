import React from "react"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import LineInputForm from "../../components/Forms/LineInputForm";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RoundedButton from "../../components/Actions/RoundedButton";
import SVGIcon from "../../components/Structure/DisplayData/SVGIcon";
import general_translations from '../../texts/eng/website/general-translations'
import { actionTypes } from "../../store/actions";
import { connect } from "react-redux";
import useContactDrawer from "./useContactDrawer";
import LoadingContainer from "../../components/Structure/Layouts/LoadingContainer";
import cx from 'classnames'
import { grey } from "@material-ui/core/colors";
import ReactHtmlParser from 'react-html-parser';
import LineSelectFormLight from "../../components/Forms/LineSelectFormLight";
import LineSelectForm from "../../components/Forms/LineSelectForm";

const styles = makeStyles(theme => ({
    root: {
        color: 'black'
    },
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: 100,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    appBar: {
        position: 'relative',
        background: 'transparent',
        padding: 16,
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderBottom: `1px solid ${grey[300]}`,
    },
    banner_container: {
        width: '100%',
        height: '100%',
        maxHeight: 1000,
        [theme.breakpoints.only('xs')]: {
            height: 600
        }
    },
    banner: {
        objectFit: 'cover',
        objectPosition: 'center',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            objectFit: 'cover',
        }
    },
    title: {
        fontSize: 30,
        fontWeight: 600,
        color: grey[700]
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 500
    },
    link: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginLeft: 16,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    iconPassword: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    footer: {
        marginBottom: 'auto',
        borderTop: `1px solid ${grey[300]}`,
        padding: 24
    },
    footerTitle: {
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: '0.25em'
    },
    smallText: {
        fontSize: 14,
        fontWeight: 400,
    },
    tabContainer: {
        width: '100%',
        boxSizing: 'border-box'
    },
    labelTab: {
        width: '100%',
        boxSizing: 'border-box',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
        padding: '8px 0px',
        cursor: 'pointer',
        color: '#858585',
    },
    labelTabSelected: {
        fontWeight: 600,
        color: theme.palette.secondary.main,
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,

    },
    left_container: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: 40,
        position: 'relative',
        //paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 32
        }
        //margin:
    },
    titleSub: {
        fontSize: 32,
        fontWeight: 600,
        '& span': {
            color: theme.palette.secondary.main
        }
    },
    subtitleSub: {
        fontSize: 24,
        fontWeight: 400
    },
    bottomContent: {
        position: 'absolute',
        bottom: 100,
        [theme.breakpoints.only('xs')]: {
            position: 'initial',
            paddingTop: 100
        }
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ContactDrawer = ({ open, language, onUpdateDrawerStatus, onUpdateDrawerLogIn }) => {

    const classes = styles()
    const content = general_translations.contact_drawer

    const { loading, sending, error, form, actions, step, stepTab } =
        useContactDrawer({ language, open, content, onUpdateDrawerStatus, onUpdateDrawerLogIn })

    const selected = content.steps[0].menu.find(e => e.value === stepTab).id

    let textContent = null
    let stepContent = content.steps[step]

    switch (step) {
        case 0:
            textContent = (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant='body2' className={classes.subtitle}>
                            {stepContent.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.tabContainer}>
                            <Grid container spacing={3}>
                                {stepContent.menu.map((tab, key) => (
                                    <Grid item xs={12} md key={`contact-drawer-item-${key.toString()}`}>
                                        <div onClick={() => actions.onUpdateStepTab(tab.value)}
                                            className={cx({
                                                [classes.labelTab]: true,
                                                [classes.labelTabSelected]: stepTab === tab.value
                                            })}>
                                            {tab.label}
                                        </div>

                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' aligItems='stretch' spacing={3}>
                            <Grid item xs={12} md={12}>
                                <LineInputForm data={form.name} onChange={actions.onChange} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LineInputForm data={form.email} onChange={actions.onChange} />
                            </Grid>
                            <Grid item xs={5} md={3}>
                                <LineSelectForm data={form.country_code} onChange={actions.onChange} />
                            </Grid>
                            <Grid item xs={7} md={9}>
                                <LineInputForm data={form.phone} onChange={actions.onChange} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LineInputForm data={form.message} onChange={actions.onChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <RoundedButton fullWidth onClick={actions.onSubmit} color='primary'>
                            {stepContent.button}
                        </RoundedButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' >
                            {stepContent.account} <span className={classes.link} onClick={actions.onOpenLogIn}>{stepContent.log_in}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {stepContent.medias.map(media => (
                                <Grid item key={`login-media.${media.id}`}>
                                    <div onClick={() => window.open(media.url, media.target)} style={{ borderRadius: 8, }}>
                                        <img src={media.icon} width={42} />
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.smallText} align='justify'>
                            {ReactHtmlParser(stepContent.small_text)}
                        </Typography>
                    </Grid>
                </Grid>
            )
            break;
        case 1:
            textContent = (
                <React.Fragment>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='body2' className={classes.titleSub}>
                                {stepContent.title[0]} <span>{stepContent.title[1]}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' className={classes.subtitleSub}>
                                {stepContent.subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.bottomContent}>
                        <Grid container spacing={4}>
                            <Grid item>
                                <RoundedButton fullWidth onClick={actions.onClose} color='primary'>
                                    {stepContent.button}
                                </RoundedButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' >
                                    {stepContent.account} <span className={classes.link} onClick={actions.onOpenLogIn}>{content.log_in}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            )
            break;
    }

    return (
        <LoadingContainer loading={loading} sending={sending} error={error} onCloseError={actions.onCloseError}>
            <div className={classes.root}>
                <Dialog fullScreen classes={{ paperFullScreen: classes.paper }} open={open} onClose={actions.onClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Grid container justifyContent='space-between' alignItems="center">
                                <Grid item>
                                    <img src={content.logo} width={48} style={{ marginLeft: 10 }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton edge="end" onClick={actions.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <Grid container justifyContent='center' alignItems='stretch'>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.left_container}>
                                    {textContent}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.banner_container}>
                                    <img src={content.image} className={classes.banner} />
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    {/*<div className={classes.footer}></div>*/}
                </Dialog>
            </div>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        open: state.contact_drawer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateDrawerStatus: (contact_drawer) => dispatch({ type: actionTypes.DRAWER_CONTACT, contact_drawer }),
        onUpdateDrawerLogIn: (login_drawer) => dispatch({ type: actionTypes.DRAWER_UPDATE_LOGIN, login_drawer })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDrawer)