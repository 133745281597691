import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Grid, IconButton, Paper, Typography, useMediaQuery, useTheme, CircularProgress } from "@material-ui/core"
import RoundedButton from "../../../components/Actions/RoundedButton"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames'

const styles = makeStyles(theme => ({
    title: {
        fontSize: 22,
        fontWeight: 500,
    },
}))

const Step2 = ({ gallery, content, actions }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))
    const isSM = useMediaQuery(theme.breakpoints.between(0, 765))

    return (
        <Grid container justifyContent='center' spacing={8}>
            <Grid item xs={12}>
                <Grid container justifyContent='center' spacing={5}>
                    <Grid item xs={12} sm>
                        <Typography variant='body2' className={classes.title}>{content.subtitle}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>{content.max}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={ismobile ? 'center' : 'flex-start'} spacing={2}>
                            {gallery.map(artwork => isSM ?
                                <Grid item xs sm={4}>
                                    <ImageCard title={''} artwork={artwork} onDelete={actions.onDeleteImage} noImage={content.noImage} />
                                </Grid>
                                :
                                <Grid item xs={12} sm={3}>
                                    <ImageCard title={''} artwork={artwork} onDelete={actions.onDeleteImage} noImage={content.noImage} />
                                </Grid>
                            )}
                            {gallery.length < 12 &&
                                <Grid item xs sm={3}>
                                    <ImageCard add updateGallery={actions.onUpdateGallery} noImage={content.noImage} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <RoundedButton disabled={gallery.length < 1} onClick={actions.onNextStep}>
                    {content.button}
                </RoundedButton>
            </Grid>
        </Grid>
    )
}

const imageCardStyles = makeStyles(theme => ({
    root: {
        width: 170,
        height: 170,
        borderRadius: 10,
        position: 'relative',
        backgroundColor:'transparent',
        [theme.breakpoints.down('md')]: {
            width: 150,
            height: 150,
        },
        [theme.breakpoints.only('xs')]: {
            width: 250,
            height: 250,
        },
    },
    overlay: {
        top: 0,
        left: 0,
        position: 'absolute',
        width: 170,
        height: 170,
        cursor: 'pointer',
        background: 'rgba(0,0,0,0.6)',
        visibility: 'hidden',
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
            width: 150,
            height: 150,
        },
        [theme.breakpoints.only('xs')]: {
            width: 250,
            height: 250,
        },
    },
    overlayShow: {
        visibility: 'inherit'
    },
    add: {
        width: 170,
        height: 170,
        background: 'rgba(123, 123, 123, 0.23)',
        border: '1px solid #7B7B7B',
        borderRadius: 4,
        [theme.breakpoints.down('md')]: {
            width: 150,
            height: 150,
        },
        [theme.breakpoints.only('xs')]: {
            width: 250,
            height: 250,
        }
    },
    addButton: {
        color: 'white',
        margin: 'auto',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    addIcon: {
        width: 50,
        height: 50
    },
    deleteIcon: {
        color: 'white',
        height: 'fit-content',
        transform: 'translateX(-100%)',
        mixBlendMode: 'difference',
        right: 0,
        padding: 10,
    },
    deleteIconH: {
        color: 'white',
        mixBlendMode: 'unset',
        background: 'transparent'
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: 400
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 10
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: 0,
        zIndex: 3,
        width: '100%',
        transform: 'translateY(-50%)'
        //background:'red'
    },
}))

const ImageCard = ({ title, artwork, noImage, add, updateGallery, onDelete }) => {

    const classes = imageCardStyles()
    const [hover, setHover] = useState(false)
    const [loading, setLoading] = useState(false)

    const selectedImage = artwork && artwork.image ? artwork.image : noImage

    const uploadMultipleFiles = async (event) => {
        console.log('hi', event.target)
        const files = event.target.files
        const _gallery = []
        for (let i = 0; i < files.length; i++) {
            _gallery.push({ id: null, image: files[i] })
        }
        await updateGallery(_gallery)
        setLoading(false)
    }

    const handleFocusBack = () => {
        console.log('focus-back');
        window.removeEventListener('focus', handleFocusBack)
        setLoading(false)
    }

    const clickedFileInput = () => {
        setLoading(true)
        window.addEventListener('focus', handleFocusBack)
    }

    const addButton = (
        <Paper className={classes.add}>
            <input
                type="file"
                id="icon-button-file"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                onChange={uploadMultipleFiles}
                onClick={clickedFileInput}
            />
            <label htmlFor="icon-button-file">
                <IconButton className={classes.addButton} component="span">
                    {loading ?
                        <div className={classes.loading}>
                            <CircularProgress size={32} color='secondary' style={{ color: 'white' }} />
                        </div>
                        :
                        <AddCircleIcon className={classes.addIcon} />
                    }
                </IconButton>
            </label>
        </Paper>
    )

    const content = (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => onDelete(artwork)}
            style={{ position: 'relative', display: 'inline-flex' }}
        >
            <Paper className={classes.root}>
                <img src={selectedImage} className={classes.image} />
            </Paper>
            <CloseIcon className={cx({ [classes.deleteIcon]: true })} />
            <div className={cx({
                [classes.overlay]: true,
                [classes.overlayShow]: hover
            })} />
        </div>
    )

    return add ? addButton : content
}
export default Step2