import React from 'react'
import { connect } from 'react-redux'
import { actionTypes } from '../../store/actions'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import SimpleModal from '../../components/Modals/SimpleModal'
import translations from '../../texts/eng/pro/general-translations'
import useChangePasswordModal from './useChangePasswordModal'
import FilledInputForm from '../../components/Forms/FilledInputForm'
import RoundedButton from '../../components/Actions/RoundedButton'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'

const styles = makeStyles(theme => ({
    title: {
        fontWeight: 700
    }
}))

const ChangePasswordModal = ({ language, open, user, onUpdateUserData, onUpdateModalStatus }) => {

    const classes = styles()
    const content = translations.change_password_modal
    const { system, view_data, actions } = useChangePasswordModal({
        language, content, onUpdateUserData, onUpdateModalStatus
    })

    return (
        <LoadingContainer loading={system.loading} sending={system.sending} error={system.error} onCloseError={actions.onCloseError}>
            <div>
                <SimpleModal open={open} sharp maxWidth={'xs'} onClose={actions.onClose} >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h6' className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FilledInputForm data={view_data.form.previous_password} onChange={actions.onChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FilledInputForm data={view_data.form.new_password} onChange={actions.onChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <RoundedButton size='small' onClick={actions.onSubmit}>
                                {content.button}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </SimpleModal>
            </div>
        </LoadingContainer>
    )
}


const mapStateToProps = state => {
    return {
        user: state.user,
        language: state.language,
        open: state.change_password_modal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateModalStatus: (change_password_modal) => dispatch({ type: actionTypes.MODAL_CHANGE_PASSWORD, change_password_modal }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)