import React from "react"
import { Box, Grid, makeStyles, SvgIcon, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import RoundedButton from "../../../../components/Actions/RoundedButton"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SVGIcon from "../../../../components/Structure/DisplayData/SVGIcon";
import PageContainer from "../../../../components/Structure/Layouts/PageContainer";
import MainContainer from "../../../../components/Structure/Layouts/MainContainer";


const styles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        marginBottom: 80,
        //paddingLeft: 100,
        //paddingRight: 100,
        //[theme.breakpoints.only('xs')]: {
        //    padding: 0
        //}
    },
    title: {
        //fontSize: 48,
        fontWeight: 600
    },
    text: {
        fontSize: 30,
        fontWeight: 300
    },
    text_small: {
        //fontSize: 24,
        fontWeight: 300
    },
    bold: {
        fontSize: 30,
        fontWeight: 500
    },
    title_cards: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    subtitle: {
        //fontSize: 36,
        fontWeight: 500,
        letterSpacing: '0.31em',
    },
    cards: {
        marginTop: 32,
        width: '100%',
        //padding: '64px 64px 0px 64px',
        boxSizing: 'border-box'
    }
}))

const AboutUs = ({ history, content, onOpenContactDrawer }) => {

    const classes = styles()

    return (
        <div className={classes.root}>
            <MainContainer>
                <Grid container spacing={6} justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Typography variant='h4'>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h6' align='justify' className={classes.text_small}>
                            {content.description}
                        </Typography>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
                <div className={classes.cards}>
                    <Grid container spacing={6}>
                        {content.cards.map((card, key) => (
                            <Grid item xs={12} md={6} lg={4} key={`iconc-${key}`}>
                                <IconCard {...card} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div style={{ marginTop: 32 }}>
                    <Grid container alignItems='center' spacing={5}>
                        <Grid item>
                            <Typography variant='h6' className={classes.subtitle}>
                                {content.bottom_text}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                size='large'
                                endIcon={<ArrowForwardIosIcon style={{ color: 'white' }} />}
                                onClick={onOpenContactDrawer}
                            >
                                {content.request_button}
                            </RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </MainContainer>
        </div>
    )
}

const styles_image_card = makeStyles(theme => ({
    image: {
        width: '100%',
        height: 400,
        //objectFit: 'cover',
        //borderRadius: 7,
        objectFit: 'cover',
    },
    imageHover: {
        opacity: 0.5
    },
    title: {
        //fontSize: 18,
        fontWeight: 500,
    },
    description: {
        //fontSize: 18,
        fontWeight: 300
    },
    box: {
        width: 100,
        height: 100,
        padding: 30,
        margin: 'auto',
        backgroundColor: 'rgba(189, 139, 64, 0.05)',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 5,
        textAlign: 'center'
    },
    icon: {

    }
}))

const IconCard = ({ title, description, icon, stroke }) => {

    const classes = styles_image_card()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Grid
            container
            classes={classes.card}
            justifyContent={ismobile ? 'center' : 'space-between'}
            alignItems='stretch'
            spacing={2}
        >
            {/*<Grid item xs={12} sm md={6} lg>*/}
            <Grid item xs={12}>
                <Box className={classes.box}>
                    <SVGIcon
                        src={icon}
                        size={100}
                        color={theme.palette.secondary.main}
                        colorStroke={stroke ? theme.palette.secondary.main : ''}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='stretch' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2' className={classes.title} align='center'> {/*align={ismobile ? 'center' : 'left'}>*/}
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' align='center' className={classes.description}>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AboutUs